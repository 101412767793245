<template>
    <OrqNotificationFixed
        :inverse="inverse"
        class="w-100"
        :kind="kind"
        notification-type="fixed"
    >
        <div
            class="body-sm"
            :class="{
                'login-error-message--inverse': inverse,
            }"
        >
            <span v-dompurify-html="message" />
        </div>
    </OrqNotificationFixed>
</template>

<script setup>
import { OrqNotificationFixed } from '@digital/orquestra';

import { LOGIN_ERROR_STATUS } from '@/consts';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const props = defineProps({
    inverse: {
        type: Boolean,
        default: false,
    },
    code: {
        type: String,
        default: '',
        validator: (value) => {
            Object.keys(LOGIN_ERROR_STATUS).includes(value);
        },
    },
});
const kind = computed(() => {
    return t(`loginStatus.${props.code}.kind`);
});

const message = computed(() => {
    return t(`loginStatus.${props.code}.message`);
});
</script>

<style lang="scss" scoped>
.login-error-message--inverse:deep(a) {
    color: var(--on-color-emphasis-high-inverse, white) !important;
}
</style>
