<template>
    <OrqDrawerBody>
        <template #body>
            <div class="order-completed">
                <div
                    class="order-completed__header text--emphasis-base-high-inverse"
                    :class="{ 'bg--primary-extended-90': !inverse }"
                >
                    <div class="order-completed__container">
                        <OrqColumn :gap="0">
                            <h1 class="body-baseline">
                                {{
                                    t(
                                        'transactions.error.notRequestedOperation'
                                    )
                                }}
                            </h1>
                            <div>
                                <h2 class="heading-2xl">
                                    {{ t('transactions.error.title') }}
                                </h2>
                                <div v-if="item?.info" class="heading-lg">
                                    {{
                                        t('transactions.error.headline', {
                                            instrument: item?.info.name,
                                        })
                                    }}
                                </div>
                            </div>
                        </OrqColumn>
                    </div>
                </div>

                <div
                    class="order-completed__body"
                    :class="`text--emphasis-base-high${
                        inverse ? '-inverse' : ''
                    }`"
                >
                    <div class="order-completed__container">
                        <OrqColumn :gap="48">
                            <OrqColumn>
                                <p
                                    class="body-baseline text--emphasis-base-medium"
                                >
                                    {{ t('transactions.error.tryAgainLater') }}
                                </p>

                                <OrqLink
                                    size="large"
                                    icon="default"
                                    :label="t('transactions.error.contactChat')"
                                    @click="
                                        rootEvents.emit(EVENTS.INTERCOM_OPEN);
                                        onClose();
                                    "
                                />
                            </OrqColumn>
                        </OrqColumn>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <TransactionalFooterCompleted
                :show-progress="false"
                button-label="Cerrar"
                :inverse="inverse"
                @accept="onClose()"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import { OrqLink, OrqColumn, OrqDrawerBody } from '@digital/orquestra';
import TransactionalFooterCompleted from '@/components/transactional-funds/TransactionalFooterCompleted.vue';
import { EVENTS } from '@/consts';
import { storeToRefs } from 'pinia';

import { useOrderStore } from '@/_store/order.store.js';
const orderStore = useOrderStore();
const { orderStatus } = storeToRefs(orderStore);

import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const emit = defineEmits(['close']);
const onClose = () => {
    orderStatus.value.error = null;
    rootEvents.emit(EVENTS.TRANSACCIONAL_CLOSE);
    emit('close');
};

defineProps({
    item: {
        type: Object,
        default: () => {},
    },
    showClose: {
        type: Boolean,
        default: true,
    },
});
</script>

<style lang="scss" scoped>
.order-completed {
    margin-left: -48px;
    margin-right: -48px;

    &__container {
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 48px;
    }

    &__header {
        padding: 32px 0;
    }
    &__body {
        padding: 24px 0;
    }
    &__actions {
        display: flex;
        gap: 1rem;
    }
}

.heading-2xl {
    line-height: 100%;
    margin: 16px 0;
}
</style>
