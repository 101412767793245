import api from './api.js';

export default {
    getGiroEnabled() {
        const path = 'apv/giroEnabled';
        return api.get(path, { data: null });
    },
    getAPVInstitutions() {
        const path = 'apv/entitys';
        return api.get(path, { data: null });
    },
    getPendingAPV() {
        const path = 'apv/pending';
        return api.get(path, { data: null });
    },
    getDocument(id) {
        const path = `apv/doc/${id}`;
        return api.get(path, {
            data: null,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
            },
        });
    },
    createAPV({
        id,
        subscription,
        regimen,
        account,
        invAmount,
        instruments,
        sourceAccount,
        mode,
        transfer,
    }) {
        // Exampple payload:
        // {
        //     "suscription": 1,
        //     "regimen": "A",
        //     "account": "60900",
        //     "invAmount": 3500000,
        //     "ffmm": [
        //         {
        //             "amount": 100,
        //             "securityId": 27891,
        //             "securityExchange": "B-APV",
        //             "symbol": "BTG Pactual Acciones Chilenas B-APV",
        //             "currency": "CLP",
        //             "text": "60900"
        //         }
        //     ]
        // }
        const path = 'apv';
        return api.post(path, {
            id,
            suscription: subscription,
            regimen,
            account,
            invAmount,
            sourceAccount,
            ffmm: instruments,
            mode,
            transfer,
        });
    },
    signAPV({
        id,
        subscription,
        regimen,
        account,
        invAmount,
        instruments,
        sourceAccount,
        transfer,
        mode,
        token,
        timer,
    }) {
        // Example payload:
        // {
        //     "suscription": 1,
        //     "regimen": "A",
        //     "account": "60900",
        //     "invAmount": 3500000,
        //     "ffmm": [
        //         {
        //             "amount": 100,
        //             "securityId": 27891,
        //             "securityExchange": "B-APV",
        //             "symbol": "BTG Pactual Acciones Chilenas B-APV",
        //             "currency": "CLP",
        //             "text": "60900"
        //         }
        //     ]
        // }
        const path = 'apv/sign';
        return api.post(path, {
            id,
            suscription: subscription,
            regimen,
            account,
            invAmount,
            sourceAccount,
            ffmm: instruments,
            mode,
            transfer,
            secondGenerateToken: timer,
            tokenTrx: token,
        });
    },

    outOfProfile(id) {
        return api.get(`apv/profile/${id}`);
    },
};
