import api from './api.js';

export default {
    getPaymentMethod() {
        return api.get('investments/paymentforms', { data: null });
    },
    cancelTransaction(id) {
        return api.delete(`investments/${id}`, { data: null });
    },
    getTransferAccount() {
        return api.get('investments/transfer/account', { data: null });
    },
    updateTransferAccount(id) {
        return api.post(`investments/transfer/account/${id}`, { data: null });
    },
    updatePhone(phone) {
        return api.put(`investments/updatePhone/${phone}`, { data: null });
    },
};
