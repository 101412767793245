<template>
    <OrqDrawerBody>
        <template #header>
            <TransactionalHeader
                :inverse="inverse"
                :title="title"
                :subtitle="subtitle"
            />
        </template>

        <template #body>
            <OrqColumn :gap="48">
                <BodyInvestAmount
                    v-model.number="inputAmount"
                    :currency="currency"
                    :error-msg="loadingCreateAPV ? false : errorMsg"
                    :inverse="inverse"
                    hide-button
                    @update:model-value="debouncedTouch()"
                    @blur="v$.$touch()"
                />
                <BodyInfo :data="investInfo" :inverse="inverse" />
            </OrqColumn>
        </template>

        <template #footer>
            <div class="footer_content">
                <OrqDivider />
                <div class="footer_content__body">
                    <TransactionalFooter
                        :next-enabled="isValid"
                        :inverse="inverse"
                        :back="false"
                        :loading="loadingCreateAPV"
                        @next="nextStep()"
                    />
                </div>
            </div>
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import { computed, ref } from 'vue';
import TransactionalHeader from '../TransactionalHeader.vue';
import TransactionalFooter from './TransactionalFooter.vue';
import BodyInvestAmount from '../body-components/BodyInvestAmount.vue';
import BodyInfo from '../body-components/BodyInfo.vue';
import { OrqDrawerBody, OrqColumn, OrqDivider } from '@digital/orquestra';
import { TYPES_DATA } from '@/consts';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useVuelidate } from '@vuelidate/core';
import { debounce } from 'lodash';
import { removeFormat, formatCurrency } from '@/directives/currencyFormat';

import { useApvStore } from '@/_store/apv.store.js';
const apvStore = useApvStore();
const { amounts, loadingCreateAPV } = storeToRefs(apvStore);
const { nextStep: storeNextStep } = apvStore;

const idBtg = computed(() => {
    return parseInt(window.atob(props.item.info.idBtg), 10);
});

const nextStep = async () => {
    apvStore.insertToItems(props.item);
    if (idBtg.value in amounts.value) {
        amounts.value[idBtg.value] += removeFormat(inputAmount.value);
    } else {
        amounts.value[idBtg.value] = removeFormat(inputAmount.value);
    }
    await apvStore.createAPV();
    storeNextStep();
};

const { t } = useI18n();

const inputAmount = ref(null);

const currency = computed(() => {
    if (props.item?.config?.multiproduct || props.item?.config?.multiCurrency) {
        return 'CLP';
    }

    return !props.item?.config?.multiCurrency &&
        props?.item?.config?.currency === 'USD'
        ? 'USD'
        : 'CLP';
});

const title = computed(() => {
    return props.item?.info?.name;
});

const subtitle = computed(() => {
    return TYPES_DATA[props.item?.info?.type?.id]?.nameType ?? '';
});

const props = defineProps({
    item: {
        type: Object,
        default: () => {},
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

import { parseRemuneration } from '@/_helpers/instrument.helper';
const maxRemuneration = computed(() => {
    const remunerationStr = props.item?.config?.remuneration ?? '';
    return parseRemuneration(remunerationStr);
});

const investInfo = computed(() => {
    const data = [
        {
            title: t('transactions.minInvestment'),
            value: {
                amount: props.item?.config?.invMinAmount,
                format: currency.value,
            },
        },
        {
            title: t('transactions.maxRemuneration'),
            value: {
                amount: maxRemuneration.value,
                format: 'PERCENT',
            },
        },
    ];
    if (props.item?.config?.retiroAnticipado) {
        data.push({
            title: t('transactions.earlyRescueCommission'),
            value: {
                label: props.item?.config?.comisionRescate || '-',
            },
        });
    }
    return data;
});

const totalAmount = computed(() => {
    return Object.entries(amounts.value)
        .map((entry) => entry[1])
        .reduce((acc, cur) => acc + cur, 0);
});

const minAmountByPercentage = computed(() => {
    if ((amounts.value[idBtg.value] ?? 0) > 0) {
        return 0;
    }
    return Math.ceil(totalAmount.value / 99);
});

const maxAmountByPercentage = computed(() => {
    const minAmount = Math.min(
        ...Object.entries(amounts.value)
            .filter((entry) => entry[0] != idBtg.value)
            .filter((entry) => entry[1] != 0)
            .map((entry) => entry[1])
    );
    return minAmount * 100 - totalAmount.value;
});

import { minValue, maxValue } from '@/_helpers/rules.helper.js';
const rules = computed(() => {
    return {
        inputAmount: {
            moreThanMin: minValue(props.item?.config?.invMinAmount),
            moreThanMinPercentage: minValue(
                minAmountByPercentage.value,
                `El monto debe ser al menos el 1% del aporte total. Ingresa un mínimo de ${formatCurrency(
                    minAmountByPercentage.value,
                    'CLP'
                )} en este fondo o elimina otros fondos de tu lista.`
            ),
            lessThanMaxPercentage: maxValue(
                maxAmountByPercentage.value,
                maxAmountByPercentage.value == 0
                    ? `El monto no puede hacer que un fondo represente menos del 1% del aporte total. Elimina otros fondos de tu lista.`
                    : `El monto no puede hacer que un fondo represente menos del 1% del aporte total. Ingresa un máximo de ${formatCurrency(
                          maxAmountByPercentage.value,
                          'CLP'
                      )} en este fondo o elimina otros fondos de tu lista.`
            ),
        },
    };
});

const v$ = useVuelidate(rules, { inputAmount });

const debouncedTouch = debounce(() => {
    v$.value.$touch();
}, 500);

const isValid = computed(() => {
    return !v$.value.$invalid;
});

const errorMsg = computed(() => v$.value.$errors[0]?.$message ?? null);
</script>

<style lang="scss" scoped>
.footer_content {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1008;

    &__body {
        padding: 24px 48px 24px 48px;
    }
}
</style>
