<template>
    <TransactionalFooter
        :model-value="stepIndex"
        :steps="totalSteps"
        :next-enabled="nextEnabled"
        :inverse="inverse"
        :loading="nextLoading"
        @next="nextStep(sign)"
        @back="prevStep()"
        @cancel="emit('cancel')"
    />
</template>

<script setup>
import TransactionalFooter from './TransactionalFooter.vue';
import { storeToRefs } from 'pinia';

import { useOrderStore } from '@/_store/order.store.js';

const orderStore = useOrderStore();
const { totalSteps, stepIndex, nextEnabled, nextLoading } =
    storeToRefs(orderStore);
const { prevStep, nextStep } = orderStore;

defineProps({
    inverse: {
        type: Boolean,
        default: false,
    },
    sign: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['cancel']);
</script>


