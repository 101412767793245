<template>
    <OrqDrawerBody>
        <template #header>
            <OrqColumn :gap="16">
                <div
                    class="heading-md"
                    :class="`text--emphasis-base-high${
                        inverse ? '-inverse' : ''
                    }`"
                >
                    Abonar
                </div>
                <div>
                    <AbonarIcon src="/images/transaccion-success.png" />
                </div>
                <div>
                    <div
                        class="heading-lg"
                        :class="`text--emphasis-base-high${
                            inverse ? '-inverse' : ''
                        }`"
                    >
                        Traspaso exitoso
                    </div>
                </div>
            </OrqColumn>
        </template>

        <template #body>
            <OrqColumn :gap="16">
                <OrqFlex style="justify-content: space-between">
                    <div
                        :class="`text--emphasis-base-medium${
                            inverse ? '-inverse' : ''
                        }`"
                    >
                        Fecha de ingreso
                    </div>
                    <div
                        :class="`text--emphasis-base-medium${
                            inverse ? '-inverse' : ''
                        }`"
                    >
                        {{ formatDate(date, 'today') }}
                    </div>
                </OrqFlex>
                <OrqFlex style="justify-content: space-between">
                    <div
                        :class="`text--emphasis-base-medium${
                            inverse ? '-inverse' : ''
                        }`"
                    >
                        Cuenta destino
                    </div>
                    <div
                        :class="`text--emphasis-base-medium${
                            inverse ? '-inverse' : ''
                        }`"
                    >
                        <OrqShimmer v-if="accountSelectedLoading" type="text" />
                        <template v-else>
                            {{ accountSelected }}
                        </template>
                    </div>
                </OrqFlex>
                <div
                    :class="`text--emphasis-base-medium${
                        inverse ? '-inverse' : ''
                    }`"
                >
                    Recibirás una copia de la transacción a tu correo
                    electrónico {{ user.email }}
                </div>
            </OrqColumn>
        </template>
        <template #footer>
            <AbonarFooter
                :show-progress="false"
                :show-cancel="false"
                next-label="Cerrar"
                :inverse="inverse"
                @next="onClose"
            >
                <OrqColumn
                    :gap="8"
                    class="body-sm"
                    :class="`text--emphasis-base-medium${
                        inverse ? '-inverse' : ''
                    } ${inverse ? 'inverse' : ''}`"
                >
                    <div>
                        ¿Tu banco no está en la lista?
                        <a href="#" @click.prevent="openIntercom"
                            >Contáctanos por el chat</a
                        >
                    </div>
                    <div>
                        <a
                            href="https://ayuda.btgpactual.cl/es/articles/6766159-cuando-vere-reflejada-mi-transferencia"
                            target="_blank"
                            @click.stop
                            >¿En cuánto tiempo veré reflejados los abonos en mi
                            cuenta?
                        </a>
                    </div>
                </OrqColumn>
            </AbonarFooter>
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import {
    OrqShimmer,
    OrqDrawerBody,
    OrqColumn,
    OrqFlex,
} from '@digital/orquestra';

import AbonarFooter from './shared/AbonarFooter.vue';
import AbonarIcon from './shared/AbonarIcon.vue';

import { formatDate } from '@/utils/text.js';
import { computed } from 'vue';

// Data
import { useUserStore } from '@/_store/user.store.js';
import { storeToRefs } from 'pinia';
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

import { useAbonarStore } from '@/_store/abonar.store';
const abonarStore = useAbonarStore();
const { accountSelected, accountSelectedLoading } = storeToRefs(abonarStore);
abonarStore.getAccountSelected();

const date = computed(() => new Date());

// Close event
import { EVENTS } from '@/consts';
import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();
const onClose = () => {
    rootEvents.emit(EVENTS.ABONAR_CLOSE);
};

defineProps({
    inverse: {
        type: Boolean,
        default: false,
    },
});
</script>

<style lang="scss" scoped>
.inverse {
    a {
        color: var(--action-secondary-enabled-inverse);
    }
}
</style>
