import api from './api.js';

export default {
    /**
     * Asks whether this share is open for buying or selling.
     * @param  {String}  idEncoded - Encoded id of the share.
     * @param  {Integer}  operation - Type of operation, 1 means buying, 2 means selling.
     * @return {Object}  Object representation of the status of the instrument.
     */
    isOpen({ idEncoded, operation }) {
        return api.get(`shares/${idEncoded}/open/${operation}`);
    },
    buy({ account, idEncoded, amount, quantity, price, timer, token }) {
        return api.post('shares/purchases', {
            account,
            instrument: idEncoded,
            amount,
            quantity,
            price,
            secondGenerateToken: timer,
            tokenTrx: token,
        });
    },
    sell({ account, idEncoded, amount, quantity, price, timer, token }) {
        return api.post('shares/sales', {
            account,
            instrument: idEncoded,
            amount,
            quantity,
            price,
            secondGenerateToken: timer,
            tokenTrx: token,
        });
    },
    top() {
        return api.get('shares/getTop');
    },
    flop() {
        return api.get('shares/getFlop');
    },
    popular() {
        return api.get('shares/getTopFlop');
    },
    getAnexo({ risk }) {
        const path = `user/anexo/ACC/${risk}`;
        return api.get(path, { data: null });
    },
    getBalance({ idEncoded, account }) {
        const path = `/shares/balance/${account}/${idEncoded}`;
        return api.get(path, { data: null });
    },
    signAnexo() {
        const path = 'user/contracts/anexoA/ACC';
        return api.put(path, { data: null });
    },
    outOfProfile({ idEncoded }) {
        const path = `user/profileShare/${idEncoded}`;
        return api.get(path, { data: null });
    },
    getCommission({ amount, operation, account, signal }) {
        const path = `shares/commission/${amount}/${operation}/${account}`;
        return api.get(path, { data: null, signal });
    },
};
