<template>
    <div>
        <OrqShimmer v-if="loading" type="input" />
        <div v-else>
            <OrqColumn v-if="!canLogin">
                <slot name="notification">
                    <div class="heading-lg">Plataforma en mantención</div>

                    <OrqNotificationFixed
                        :inverse="inverse"
                        class="w-100"
                        kind="warning"
                        notification-type="fixed"
                    >
                        <div
                            class="body-sm"
                            :class="{
                                'login-error-message--inverse': inverse,
                            }"
                        >
                            <span v-dompurify-html="status.message" />
                        </div>
                    </OrqNotificationFixed>
                </slot>
                <slot name="action" />
            </OrqColumn>
            <template v-else>
                <slot />
            </template>
        </div>
    </div>
</template>

<script setup>
import {
    OrqShimmer,
    OrqNotificationFixed,
    OrqColumn,
} from '@digital/orquestra';

import { storeToRefs } from 'pinia';

defineProps({
    inverse: {
        type: Boolean,
        default: false,
    },
});

import { useStatusStore } from '@/_store/status.store.js';
import { watch } from 'vue';
const statusStore = useStatusStore();
const { status, loading, canLogin } = storeToRefs(statusStore);

statusStore.fetchServerStatus();

const emits = defineEmits(['complete']);
watch(loading, () => {
    emits('complete', loading.value);
});
</script>
