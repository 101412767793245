/**
 * This directive must be used on a input parent.
 *
 * Example:
 *
 * import { focus as vFocus } from '@/directives/focus';
 *
 * <div v-focus>
 *  <input />
 * </div>
 */
export const focus = {
    // called right before the element is inserted into the DOM.
    beforeMount() {},
    // called when the bound element's parent component
    // and all its children are mounted.
    mounted(el) {
        const elementInput = el.querySelector('input');
        elementInput.focus();
    },
};
