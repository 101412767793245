import api from './api.js';

export default {
    getDownloadContract({ codigoSADCO = '58' }) {
        const path = `user/contracts/download/${codigoSADCO}`;
        return api.get(path, {
            data: null,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
            },
        });
    },
};
