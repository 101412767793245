import { onMounted, ref } from 'vue';

/**
 * This mixing helps to use mediaqueries into JS.
 *
 * How to use:
 * :class="{ 'my-super-class-name': responsive.sm }
 */

export const useResponsive = () => {
    const breakpoints = {
        xs: '0',
        sm: '576px',
        md: '768px',
        lg: '1024px',
        xl: '1280px',
    };

    const _responsive = Object.keys(breakpoints).reduce((a, b) => {
        a[b] = false;
        return a;
    }, {});

    const responsive = ref(_responsive);

    const responsiveLoaded = ref(false);

    const mediaQuery = () => {
        Object.keys(breakpoints).forEach((key) => {
            const breakpoint = breakpoints[key];

            let mediaQuery = null;
            if (!import.meta.env.VITEST) {
                mediaQuery = window.matchMedia(
                    `screen and (min-width: ${breakpoint})`
                );
            }
            const handleMQ = (e) => {
                if (e.matches) {
                    responsive.value[key] = true;
                } else {
                    responsive.value[key] = false;
                }
            };
            if (mediaQuery) {
                mediaQuery.addEventListener('change', handleMQ);
                handleMQ(mediaQuery);
            }
        });

        responsiveLoaded.value = true;
    };

    onMounted(() => {
        mediaQuery();
    });

    return {
        responsive,
        responsiveLoaded,
    };
};
