import { useAccountsStore } from '@/_store/accounts.store.js';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { ACCOUNT_TYPE } from '@/consts';

export const useAPV = () => {
    const accountsStore = useAccountsStore();
    const { isCurrentAccountAPV, isCurrentAccountAdminAPV, accounts } =
        storeToRefs(accountsStore);

    const hasAPVAccount = computed(() => {
        return accounts.value.some(
            (account) => account.tipoCuenta === ACCOUNT_TYPE.APV_FONDOS_MUTUOS
        );
    });

    const APVAccountID = computed(() => {
        return (
            accounts.value.find(
                (account) =>
                    account.tipoCuenta === ACCOUNT_TYPE.APV_FONDOS_MUTUOS
            )?.idCuentaGrupo ?? ''
        );
    });

    return {
        isCurrentAccountAPV,
        isCurrentAccountAdminAPV,
        hasAPVAccount,
        APVAccountID,
    };
};
