<template>
    <OrqDrawerBody>
        <template #body>
            <div class="drawer_content">
                <div class="drawer_content__header bg--primary-extended-90">
                    <OrqColumn class="drawer_content__container" :gap="16">
                        <span
                            class="text--emphasis-base-high-inverse body-baseline"
                        >
                            Operación solicitada
                        </span>
                        <div style="display: flex; flex-direction: column">
                            <span
                                class="heading-2xl text--emphasis-base-high-inverse"
                            >
                                Solicitaste el traspaso APV
                            </span>
                            <span
                                class="body-sm text--emphasis-base-high-inverse"
                            >
                                Desde {{ selectedInstitution.name }}
                            </span>
                        </div>
                    </OrqColumn>
                </div>
                <div class="drawer_content__body">
                    <OrqColumn :gap="24">
                        <OrqColumn :gap="16" class="text--emphasis-base-medium">
                            <span>
                                Enviamos una copia firmada de la Selección de
                                Alternativas de APV tu correo electrónico
                                {{ user.email }}.
                            </span>
                            <span>
                                Te notificaremos cuando los aportes se ingresen.
                            </span>
                        </OrqColumn>
                        <OrqColumn :gap="16">
                            <div
                                style="
                                    display: flex;
                                    justify-content: space-between;
                                "
                            >
                                <span
                                    class="body-baseline text--emphasis-base-high"
                                >
                                    Tipo de traspaso
                                </span>
                                <span
                                    class="body-baseline text--emphasis-base-high"
                                    style="text-align: right"
                                >
                                    Monto
                                </span>
                            </div>
                            <div
                                style="
                                    display: flex;
                                    justify-content: space-between;
                                "
                            >
                                <span
                                    class="body-sm text--emphasis-base-medium"
                                >
                                    {{
                                        savingTypes.find(
                                            (st) => st.value == savingType
                                        ).label
                                    }}
                                </span>
                                <span
                                    class="body-sm text--emphasis-base-medium"
                                    style="text-align: right"
                                >
                                    <template v-if="selectedAmountType == 1">
                                        Total
                                    </template>
                                    <template v-else>
                                        <OrqAmount
                                            :amount="transferAmount"
                                            format="CLP"
                                        />
                                    </template>
                                </span>
                            </div>
                        </OrqColumn>
                        <OrqDivider />
                        <OrqColumn gap="16">
                            <div
                                style="
                                    display: flex;
                                    justify-content: space-between;
                                "
                            >
                                <span
                                    class="body-baseline text--emphasis-base-high"
                                >
                                    Fondos de destino
                                </span>
                                <span
                                    class="body-baseline text--emphasis-base-high"
                                    style="text-align: right"
                                >
                                    Porcentaje aporte
                                </span>
                            </div>
                            <OrqColumn gap="8">
                                <OrqGrid
                                    v-for="(id, index) in Object.keys(
                                        destinationPercentages
                                    )"
                                    :key="index"
                                    :cols="5"
                                    :gap="8"
                                >
                                    <span
                                        class="orq-col-3 body-sm text--emphasis-base-medium"
                                    >
                                        {{
                                            destinationInstruments[id].info.name
                                        }}
                                    </span>
                                    <div class="orq-col-1"></div>
                                    <span
                                        class="orq-col-1 text--on-color-emphasis-medium body-sm"
                                        style="text-align: right"
                                    >
                                        {{ destinationPercentages[id] }}
                                    </span>
                                </OrqGrid>
                            </OrqColumn>
                        </OrqColumn>
                        <OrqDivider />
                    </OrqColumn>
                </div>
            </div>
        </template>

        <template #footer>
            <TransactionalFooter
                :next-enabled="nextEnabled"
                :inverse="inverse"
                next-label="Cerrar"
                :exit="false"
                :back="false"
                @next="onClose()"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import {
    OrqColumn,
    OrqDrawerBody,
    OrqAmount,
    OrqDivider,
    OrqGrid,
} from '@digital/orquestra';
import TransactionalFooter from '../APV/TransactionalFooter.vue';

import { storeToRefs } from 'pinia';

import { useApvStore } from '@/_store/apv.store.js';
const apvStore = useApvStore();
const {
    savingTypes,
    transferAmount,
    selectedAmountType,
    savingType,
    destinationPercentages,
    destinationInstruments,
    selectedInstitution,
} = storeToRefs(apvStore);

defineProps({
    title: {
        type: String,
        default: '',
    },
    subtitle: {
        type: String,
        default: '',
    },
});

import { useUserStore } from '@/_store/user.store.js';
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

import { EVENTS } from '@/consts';
import { useRootEvents } from '@/composables/useRootEvents';
import { useRouter } from 'vue-router';

const router = useRouter();
const rootEvents = useRootEvents();
const onClose = () => {
    apvStore.$reset();
    rootEvents.emit(EVENTS.APV_TRANSFER_CLOSE);
    router.push({ name: 'portfolio' });
};
</script>

<style lang="scss" scoped>
.drawer_content {
    margin-left: -48px;
    margin-right: -48px;

    &__container {
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 48px;
    }
    &__header {
        padding: 40px 0;
    }
    &__body {
        padding: 32px 48px;
    }
}

:deep(.title--title-md) {
    font-size: 20px;
}
</style>
