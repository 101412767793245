<template>
    <OrqDrawerBody>
        <template #header>
            <TransactionalHeader
                title="Producto no disponible"
                :inverse="inverse"
            />
        </template>

        <template #body>
            <OrqNotificationFixed
                kind="warning"
                type="fixed"
                label="No tienes posiciones disponibles para vender o rescatar de este instrumento."
                notification-type="fixed"
                :label-size="14"
                :inverse="inverse"
            />
        </template>

        <template #footer>
            <OrqFlex class="controls">
                <OrqButton
                    size="compact"
                    :label="t('transactions.close')"
                    :inverse="inverse"
                    @click="onClose"
                />
            </OrqFlex>
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import TransactionalHeader from '../TransactionalHeader.vue';
import {
    OrqButton,
    OrqDrawerBody,
    OrqFlex,
    OrqNotificationFixed,
} from '@digital/orquestra';
import { useI18n } from 'vue-i18n';
import { EVENTS } from '@/consts';

const { t } = useI18n();

import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();

const onClose = () => {
    rootEvents.emit(EVENTS.TRANSACCIONAL_CLOSE);
};

defineProps({
    label: {
        type: String,
        default: '',
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});
</script>

<style lang="scss" scoped>
.bg--warning-surface {
    background-color: var(--warning-surface);
}
.controls {
    justify-content: end;
}
</style>
