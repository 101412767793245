<template>
    <OrqDrawerBody>
        <template #header>
            <div class="header__container">
                <OrqColumn :gap="8">
                    <span
                        class="heading-md header__title text--emphasis-base-medium"
                    >
                        {{ title }}
                    </span>
                </OrqColumn>
            </div>
        </template>

        <template #body>
            <OrqColumn class="column__container" :gap="16">
                <OrqColumn gap="24">
                    <OrqColumn gap="16">
                        <div
                            style="
                                display: flex;
                                justify-content: space-between;
                            "
                        >
                            <span
                                class="body-baseline text--emphasis-base-high"
                            >
                                Tipo de traspaso
                            </span>
                            <span
                                class="body-baseline text--emphasis-base-high"
                                style="text-align: right"
                            >
                                Monto
                            </span>
                        </div>
                        <div
                            style="
                                display: flex;
                                justify-content: space-between;
                            "
                        >
                            <span class="body-sm text--emphasis-base-medium">
                                {{
                                    savingTypes.find(
                                        (st) => st.value == savingType
                                    ).label
                                }}
                            </span>
                            <span
                                class="body-sm text--emphasis-base-medium"
                                style="text-align: right"
                            >
                                <template v-if="selectedAmountType == 1">
                                    Total
                                </template>
                                <template v-else>
                                    <OrqAmount
                                        :amount="transferAmount"
                                        format="CLP"
                                    />
                                </template>
                            </span>
                        </div>
                    </OrqColumn>
                </OrqColumn>
                <OrqDivider />
                <OrqColumn gap="16">
                    <div style="display: flex; justify-content: space-between">
                        <span class="body-baseline text--emphasis-base-high">
                            Fondos de destino
                        </span>
                        <span
                            class="body-baseline text--emphasis-base-high"
                            style="text-align: right"
                        >
                            Porcentaje aporte
                        </span>
                    </div>
                    <OrqColumn gap="8">
                        <OrqGrid
                            v-for="(id, index) in Object.keys(
                                destinationPercentages
                            )"
                            :key="index"
                            :cols="5"
                            :gap="8"
                        >
                            <span
                                class="orq-col-3 body-sm text--emphasis-base-medium"
                            >
                                {{ destinationInstruments[id].info.name }}
                            </span>
                            <div class="orq-col-1"></div>
                            <span
                                class="orq-col-1 text--on-color-emphasis-medium body-sm"
                                style="text-align: right"
                            >
                                {{ destinationPercentages[id] }}
                            </span>
                        </OrqGrid>
                    </OrqColumn>
                </OrqColumn>
                <OrqDivider />
                <OrqColumn gap="16">
                    <span class="body-baseline text--emphasis-base-high">
                        Institución de origen
                    </span>
                    <span class="body-sm text--emphasis-base-medium">
                        {{ selectedInstitution.name }}
                    </span>
                </OrqColumn>
                <OrqDivider style="margin-bottom: 8px" />
                <DocButton
                    label="Selección de Alternativas de APV"
                    :loading="loadingDocument"
                    @click="downloadAPVDocument"
                />
            </OrqColumn>
        </template>

        <template #footer>
            <div class="footer_content">
                <OrqDivider />
                <div class="footer_content__body">
                    <OrqColumn style="margin-bottom: 32px" gap="0">
                        <span class="body-sm text--emphasis-base-low">
                            Al presionar Siguiente, aceptas haber leído y estar
                            de acuerdo con los términos establecidos.
                        </span>
                    </OrqColumn>
                    <TransactionalFooter
                        :back="false"
                        @next="showTokenModal = true"
                    />
                </div>
            </div>
        </template>
    </OrqDrawerBody>

    <Teleport to="#modals">
        <TokenModal
            :show="showTokenModal"
            :loading="tokenLoading || loadingSignTransferAPV"
            @submit="signAPV"
            @cancel="showTokenModal = false"
        />
    </Teleport>
</template>

<script setup>
import {
    OrqColumn,
    OrqDrawerBody,
    OrqDivider,
    OrqGrid,
    OrqAmount,
} from '@digital/orquestra';
import DocButton from '@/components/Shared/DocButton.vue';
import TransactionalFooter from '../APV/TransactionalFooter.vue';
import TokenModal from '@/components/Token/TokenModal.vue';

import { storeToRefs } from 'pinia';

import { useApvStore } from '@/_store/apv.store.js';
const apvStore = useApvStore();
const {
    selectedAmountType,
    selectedInstitution,
    destinationInstruments,
    destinationPercentages,
    apvID,
    savingType,
    savingTypes,
    transferAmount,
    loadingSignTransferAPV,
} = storeToRefs(apvStore);

import { ref } from 'vue';

const loadingDocument = ref(false);
import apvService from '@/_services/apv.service';
const downloadAPVDocument = async () => {
    try {
        loadingDocument.value = true;
        const { data } = await apvService.getDocument(apvID.value);
        const blob = new Blob([data], {
            type: 'application/pdf',
        });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
    } finally {
        loadingDocument.value = false;
    }
};

defineProps({
    title: {
        type: String,
        default: '',
    },
});

import { useTokenStore } from '@/_store/token.store.js';
const tokenStore = useTokenStore();
const {
    token,
    timer: tokenTimer,
    loading: tokenLoading,
} = storeToRefs(tokenStore);

const showTokenModal = ref(false);

const emit = defineEmits(['next']);

const signAPV = async () => {
    await apvStore.signTransferAPV({
        token: token.value,
        timer: tokenTimer.value,
    });
    emit('next');
};
</script>

<style lang="scss" scoped>
.column {
    &__container {
        padding-top: 32px;
        padding-bottom: 40px;
    }
}

.text-button:hover {
    cursor: pointer;
    text-decoration: underline;
}

.header {
    // &__container {}
    &__title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.footer_content {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1008;

    &__body {
        padding: 24px 48px 24px 48px;
    }
}
</style>
