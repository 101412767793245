<template>
    <OrqColumn class="column__container" :gap="48">
        <OrqColumn :gap="8">
            <span
                class="heading-md"
                :class="{
                    'text--emphasis-base-high': !inverse,
                    'text--emphasis-base-high-inverse': inverse,
                }"
            >
                {{
                    outOfProfile
                        ? t('transactions.outOfProfileTitle')
                        : t('transactions.anexoTitle')
                }}
            </span>
            <span
                v-dompurify-html="bodyMsg"
                class="body-sm"
                :class="{
                    'text--emphasis-base-medium': !inverse,
                    'text--emphasis-base-medium-inverse': inverse,
                }"
                @click.prevent="onClick"
            />
        </OrqColumn>
        <DocButton
            v-if="anexo"
            :label="t('transactions.anexo')"
            :inverse="inverse"
            :loading="loading"
            @click="downloadAnexo"
        />
        <OrqCheckbox
            :checked="isChecked"
            :label="checkboxMsg"
            checkbox-label
            :inverse="inverse"
            @input="(val) => $emit('update:isChecked', val.checked)"
        />
        <InfoCard
            v-if="outOfProfile"
            :inverse="inverse"
            :text="t('transactions.outOfProfileInfo')"
        />
    </OrqColumn>
</template>

<script setup>
import { OrqColumn, OrqCheckbox } from '@digital/orquestra';
import DocButton from '@/components/Shared/DocButton.vue';
import InfoCard from '@/components/transactional-funds/Shared/InfoCard.vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import documentsService from '@/_services/documents.service';
import { CODIGO_SADCO } from '@/consts';
import { useToast } from '@/composables/useToast';

const toast = useToast();
const { t } = useI18n();
const { getDownloadContract } = documentsService;

const props = defineProps({
    isChecked: {
        type: Boolean,
        default: false,
    },
    risk: {
        type: String,
        default: '',
    },
    anexo: {
        type: Boolean,
        default: false,
    },
    outOfProfile: {
        type: Boolean,
        default: false,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
    multiple: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:isChecked', 'details']);

const riskStr = computed(() =>
    props.risk === '' ? props.risk : t(`consts.risk.${props.risk}.label`)
);

const onClick = (e) => {
    if (e.target.id === 'details-prompt') emit('details');
};

const checkboxMsg = computed(() => {
    const msgs = [
        t('transactions.agree'),
        ...(props.outOfProfile
            ? [t('transactions.outOfProfileAgreement')]
            : []),
        ...(props.outOfProfile && props.anexo ? [t('transactions.and')] : []),
        ...(props.anexo ? [t('transactions.anexoAgreement')] : []),
    ];
    return msgs.join(' ');
});

const bodyMsg = computed(() => {
    if (props.multiple && props.outOfProfile) {
        return t(
            props.anexo
                ? 'transactions.anexoOutOfProfileDescMult'
                : 'transactions.outOfProfileDescMult',
            { profile: riskStr.value }
        );
    } else if (props.outOfProfile) {
        return t(
            props.anexo
                ? 'transactions.anexoOutOfProfileDesc'
                : 'transactions.outOfProfileDesc',
            { profile: riskStr.value }
        );
    }
    return t('transactions.anexoDesc');
});

const loading = ref(false);
const downloadAnexo = async () => {
    try {
        loading.value = true;
        const { data } = await getDownloadContract({
            codigoSADCO: CODIGO_SADCO.ANEXO_A,
        });
        const blob = new Blob([data], {
            type: 'application/pdf',
        });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
        loading.value = false;
    } catch (error) {
        loading.value = false;
        toast.toasty({
            kind: 'error',
            title: t('documents.anexoA.error.title'),
            message: t('documents.anexoA.error.message'),
            position: 'center',
        });
    }
};
</script>

<style lang="scss" scoped>
.column {
    &__container {
        padding-top: 32px;
    }
}
.info {
    &__container {
        display: flex;
        flex-direction: row;
        gap: 8px;
        border-radius: 4px;
        padding: 24px;
    }
}
</style>
