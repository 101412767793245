// /**
//  * How to use:
//  * <UIInput v-model="mode" v-input-format />
//  */

const removeFormat = (value) => {
    let _value = String(value);
    _value = _value.replace(/[^\d]/g, '');
    return _value;
};

const format = (value) => {
    const unformatted = removeFormat(value).trim().toLowerCase();
    return `${unformatted}`;
};

export const numberFormat = {
    // called right before the element is inserted into the DOM.
    beforeMount(el) {
        const elementInput = el.querySelector('input');

        elementInput.addEventListener('keydown', (evt) => {
            let charCode = evt.which ? evt.which : evt.keyCode;

            const numbers = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
            const numbersPad = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105];

            const operationalKeys = [
                8, // backspace: charCode
                9, // tab: charCode
                13, // enter: charCode
                16, // shift: charCode
                17, // control: charCode
                37, // keyArrows: charCode
                38, // keyArrows: charCode
                39, // keyArrows: charCode
                40, // keyArrows: charCode
                91, // cmd L: charCode
                93, // cmd R: charCode
            ];
            const allowed = [...numbers, ...numbersPad, ...operationalKeys];
            if (
                (evt.ctrlKey || evt.metaKey) &&
                (charCode === 86 || charCode === 67 || charCode === 65)
            ) {
                // ctrl + v o cmd + v
            } else if (!allowed.includes(charCode)) {
                evt.preventDefault();
            }

            setTimeout(() => {
                const elementInput = el.querySelector('input');

                elementInput.value = format(elementInput.value);
                let event = new Event('input', { bubbles: true });
                elementInput.dispatchEvent(event);
            }, 100);
        });
    },
    // called when the bound element's parent component
    // and all its children are mounted.
    mounted(el) {
        const elementInput = el.querySelector('input');

        elementInput.value = format(elementInput.value);
        let event = new Event('input', { bubbles: true });
        elementInput.dispatchEvent(event);
    },
};
