<template>
    <img
        class="image-container"
        :src="src"
        :width="size"
        :height="size"
        onerror="this.src='/images/no-image.svg'"
    />
</template>

<script setup>
console.warn('Deprecated', 'Please use InstrumentIcon instead');
defineProps({
    src: {
        type: String,
        default: '',
    },
    size: {
        type: Number,
        default: 40,
    },
});
</script>

<style lang="scss" scoped>
.image-container {
    border-radius: 50%;
}
</style>
