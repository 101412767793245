<template>
    <MarketClosed v-bind="data[operation]" />
</template>

<script setup>
import MarketClosed from '@/components/transactional-funds/Shared/MarketClosed.vue';
import { OPERATION } from '@/consts.js';

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { storeToRefs } from 'pinia';
import { useInstrumentStore } from '@/_store/instrument.store.js';
const instrumentStore = useInstrumentStore();
const { isMarketOpen } = storeToRefs(instrumentStore);

const { t } = useI18n();

defineProps({
    operation: {
        type: String,
        default: OPERATION.BUY,
    },
});

const data = computed(() => ({
    [OPERATION.BUY]: {
        label: t('transactions.usdCurrentlyClosed', {
            startTime: isMarketOpen.value.openHour,
            endTime: isMarketOpen.value.closeHour,
        }),
    },
    [OPERATION.SELL]: {
        label: t('transactions.usdCurrentlyClosed', {
            startTime: isMarketOpen.value.openHour,
            endTime: isMarketOpen.value.closeHour,
        }),
    },
}));
</script>


