import api from './api.js';

export default {
    getInvestmentFunds() {
        return api.get('investmentFunds');
    },
    /**
     * Asks whether this a fund is open for buying or selling.
     * @param  {String}  idEncoded - Encoded id of the fund.
     * @param  {Integer}  operation - Type of operation, 1 means buying, 2 means selling.
     * @param  {Integer}  market - Type of market for the operation, 1 means normal (bolsa), 2 means nav.
     * @return {Object}  Object representation of the status of the instrument.
     */
    isOpen({ idEncoded, operation, market = 1 }) {
        return api.get(
            `investmentFunds/${idEncoded}/open/${operation}/${market}`
        );
    },
    buy({ account, idBtg, amount, quantity, price, market, timer, token }) {
        return api.post('investmentFunds/purchases', {
            account,
            instrument: idBtg,
            amount,
            quantity,
            price,
            opType: market,
            secondGenerateToken: timer,
            tokenTrx: token,
        });
    },
    sell({ account, idBtg, amount, quantity, price, market, timer, token }) {
        return api.post('investmentFunds/sales', {
            account,
            instrument: idBtg,
            amount,
            quantity,
            price,
            opType: market,
            secondGenerateToken: timer,
            tokenTrx: token,
        });
    },
    outOfProfile({ idBtg }) {
        const path = `user/profileFinv/${idBtg}`;
        return api.get(path, { data: null });
    },
    getCommission({
        amount,
        operation,
        account,
        market: opType,
        idBtg,
        signal,
    }) {
        const path = `investmentFunds/commission/${amount}/${operation}/${account}/${opType}/${idBtg}`;
        return api.get(path, { data: null, signal });
    },
    getBalance({ idBtg, account }) {
        const path = `/investmentFunds/balance/${account}/${idBtg}`;
        return api.get(path, { data: null });
    },
    getAnexo({ risk }) {
        const path = `user/anexo/FFII/${risk}`;
        return api.get(path, { data: null });
    },
    signAnexo() {
        const path = 'user/contracts/anexoA/FFII';
        return api.put(path, { data: null });
    },
};
