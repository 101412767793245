import fintocService from '@/_services/fintoc.service.js';
import { getFintoc } from '@fintoc/fintoc-js';
import { onMounted, onUnmounted, ref } from 'vue';
import { useRootEvents } from './useRootEvents';

export const useFintoc = () => {
    const Fintoc = ref();
    const widget = ref();
    const loading = ref(false);
    const error = ref(false);

    const { emit } = useRootEvents();

    onMounted(async () => {
        Fintoc.value = await getFintoc();
    });

    const create = async ({ amount }) => {
        try {
            loading.value = true;
            const response = await fintocService.getFintocOptionsAPV({
                amount,
            });
            const {
                country,
                holderType,
                product,
                publicKey,
                webhookUrl,
                widgetToken,
                username,
            } = response.data;
            widget.value = Fintoc.value.create({
                holderType,
                product,
                publicKey,
                webhookUrl,
                widgetToken,
                country,
                username,
                onSuccess: () => emit('FINTOC_SUCCESS'),
                onExit: () => emit('FINTOC_EXIT'),
            });
            widget.value.open();
        } catch {
            error.value = true;
        } finally {
            loading.value = false;
        }
    };

    onUnmounted(() => {});

    return {
        create,
        widget,
        loading,
        error,
    };
};
