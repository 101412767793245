<template>
    <OrqDrawerBody>
        <template #header>
            <OrqColumn :gap="16">
                <div
                    class="heading-md"
                    :class="`text--emphasis-base-high${
                        inverse ? '-inverse' : ''
                    }`"
                >
                    Abonar
                </div>
                <div
                    class="heading-lg"
                    :class="`text--emphasis-base-high${
                        inverse ? '-inverse' : ''
                    }`"
                >
                    Seleccionar método
                </div>
            </OrqColumn>
        </template>
        <template #body>
            <OrqColumn :gap="16">
                <OrqNotificationFixed
                    notification-type="fixed"
                    label="La cuenta de origen tiene que tener tu mismo RUT."
                    kind="info"
                    :label-size="12"
                    :inverse="inverse"
                />
                <OrqRadioButtonGroup
                    v-model="methodSelected"
                    :items="accounts"
                    :inverse="inverse"
                />
            </OrqColumn>
        </template>

        <template #footer>
            <AbonarFooter :inverse="inverse" @next="abonarStore.nextStep()" />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import {
    OrqDrawerBody,
    OrqRadioButtonGroup,
    OrqColumn,
    OrqNotificationFixed,
} from '@digital/orquestra';

import AbonarFooter from './shared/AbonarFooter.vue';
import { useAbonarStore } from '@/_store/abonar.store';
import { storeToRefs } from 'pinia';
const abonarStore = useAbonarStore();
const { methodSelected } = storeToRefs(abonarStore);

const accounts = [
    {
        label: 'Por transferencia electrónica',
        description:
            'Transfiere directamente desde tu cuenta corriente o vista.',
    },
    {
        label: 'Por botón de pago',
        description:
            'Transfiere altos montos desde tu cuenta bancaria, sin salir del portal de BTG Pactual.',
    },
];

defineProps({
    inverse: {
        type: Boolean,
        default: false,
    },
});
</script>


