<template>
    <OrqDrawerBody>
        <template #header>
            <OrqColumn :gap="16">
                <div class="heading-md">{{ t('withdraw.title') }}</div>
                <div class="heading-lg">
                    {{ t('withdraw.selectAccount') }}
                </div>
            </OrqColumn>
        </template>

        <template #body>
            <template v-if="accounts.length === 0">
                <OrqEmptyState
                    :title="t('withdraw.noAccounts.title')"
                    :description="t('withdraw.noAccounts.description')"
                >
                    <div>
                        <OrqButton
                            :label="t('withdraw.noAccounts.action')"
                            @click="goToAccounts"
                        />
                    </div>
                </OrqEmptyState>
            </template>
            <template v-else>
                <OrqColumn :gap="16">
                    <OrqNotificationFixed
                        v-if="hasCuentaVista"
                        notification-type="fixed"
                        kind="warning"
                    >
                        <div
                            v-dompurify-html="t('withdraw.debitAccount')"
                            class="caption-xs"
                        />
                    </OrqNotificationFixed>
                    <OrqNotificationFixed
                        v-else
                        notification-type="fixed"
                        kind="info"
                        :label="t('withdraw.notification')"
                    />
                    <OrqRadioButtonGroup
                        v-model="accountSelectedIndex"
                        :items="accounts"
                    />
                </OrqColumn>
            </template>
        </template>

        <template #footer>
            <template v-if="accounts.length !== 0">
                <RetirarFooter
                    :next-enabled="isValid"
                    @next="saveAndContinue"
                />
            </template>
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import {
    OrqDrawerBody,
    OrqRadioButtonGroup,
    OrqColumn,
    OrqButton,
    OrqNotificationFixed,
    OrqEmptyState,
} from '@digital/orquestra';
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { EVENTS, BANK_ACCOUNTS_TYPES } from '@/consts';
import { useRootEvents } from '@/composables/useRootEvents';
import { useI18n } from 'vue-i18n';
import RetirarFooter from './shared/RetirarFooter.vue';
import { useRetirarStore } from '@/_store/retirar.store';
import { useRouter } from 'vue-router';

const { t } = useI18n();
const retirarStore = useRetirarStore();
const { bankAccountsForGiro } = storeToRefs(retirarStore);

const accountSelectedIndex = ref(null);
const accountSelected = computed(() => {
    if (accountSelectedIndex.value === null) {
        return {};
    }
    return accounts.value[accountSelectedIndex.value];
});

const accounts = computed(() => {
    return bankAccountsForGiro.value.map((i) => {
        return {
            label: i.name,
            description: i.typeDesc,
            ...i,
        };
    });
});

const isValid = computed(() => accountSelectedIndex.value !== null);

const saveAndContinue = async () => {
    retirarStore.bankSelected = accountSelected.value;
    retirarStore.currency = accountSelected.value.currency;
    retirarStore.nextStep();
};

const rootEvents = useRootEvents();
const router = useRouter();

const goToAccounts = () => {
    rootEvents.emit(EVENTS.RETIRAR_CLOSE);
    router.push({ name: 'profile-bankaccounts' });
};

const hasCuentaVista = computed(() => {
    return accounts.value.some(
        (e) => e.type === BANK_ACCOUNTS_TYPES.CUENTA_VISTA
    );
});
</script>
