import { defineStore } from 'pinia';
import dapService from '@/_services/dap.service';
import { errorHandler } from '@/_services/errorHandler';
import { ref } from 'vue';

export const useDapStore = defineStore('dap', () => {
    const rates = ref({
        CLP: [],
        UF: [],
    });
    const getRates = async () => {
        try {
            const resCLP = await dapService.getInterestRateTableByCurrency({
                currency: 'CLP',
            });
            rates.value['CLP'] = resCLP.data;

            const resUF = await dapService.getInterestRateTableByCurrency({
                currency: 'UF',
            });
            rates.value['UF'] = resUF.data;
        } catch (error) {
            errorHandler(error);
        }
    };

    const days = ref({
        CLP: [],
        UF: [],
    });
    const getDays = async () => {
        try {
            const resCLP = await dapService.getDaysByCurrency();
            days.value['CLP'] = resCLP.data;

            const resUF = await dapService.getDaysByCurrency('UF');
            days.value['UF'] = resUF.data;
        } catch (error) {
            errorHandler(error);
        }
    };

    const ufDate = ref('');
    const getUFDate = async () => {
        try {
            const res = await dapService.getUFRate();
            ufDate.value = res.data.fecha;
        } catch (error) {
            errorHandler(error);
        }
    };

    return {
        rates,
        getRates,
        days,
        getDays,
        ufDate,
        getUFDate,
    };
});
