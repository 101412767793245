import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import balanceService from '../_services/balance.service';
import { useAccountsStore } from './accounts.store';

/**
 * Important: We are using 'Setup Store' definition.
 * @see https://pinia.vuejs.org/core-concepts/#setup-stores
 *
 * ref()s become state properties
 * computed()s become getters
 * function()s become actions
 *
 */
export const useBalanceStore = defineStore('balance', () => {
    const loading = ref(false);

    const balance = ref({
        CLP: 0,
        USD: 0,
    });

    const balanceBank = ref(0);

    const noBalance = computed(() => {
        return balance.value.CLP === 0 && balance.value.USD === 0;
    });

    const accountsStore = useAccountsStore();
    const getBalance = async () => {
        loading.value = true;

        const CLPBalance = balanceService
            .getUserBalanceByAccount({
                account: accountsStore.selected,
                currency: 'CLP',
            })
            .then((r) => {
                balance.value.CLP = r.data;
            })
            .catch((e) => console.log(e));

        const USDBalance = balanceService
            .getUserBalanceByAccount({
                account: accountsStore.selected,
                currency: 'USD',
            })
            .then((r) => (balance.value.USD = r.data))
            .catch((e) => console.log(e));

        return Promise.all([CLPBalance, USDBalance]).then(() => {
            loading.value = false;
        });
    };

    /**
     * Deprecated:
     * Please use InstrumentStore fetchBalance instead.
     */
    const balanceById = ref({});
    const getBalanceByInstrument = async ({ idBtg }) => {
        balanceService
            .getBalanceByInstrument(idBtg, accountsStore.selected, 'CLP')
            .then((response) => {
                balanceById.value[idBtg] = response;
            }).catch;
    };
    
    const getBalanceBank = async () => {
        return balanceService
            .getUserBalanceAccountBank({
                account: accountsStore.selectedBank
            })
            .then((r) => {
                balanceBank.value = r.data;
            })
            .catch((e) => console.log(e));
    };

    return { loading, noBalance, getBalance, balance, getBalanceByInstrument, balanceBank, getBalanceBank };
});
