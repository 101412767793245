import api from './api.js';

export default {
    // Get user banks accounts
    getAccounts() {
        return api.get('user/bankAccounts', { data: null });
    },
    getTypes() {
        return api.get('user/bankAccounts/types', { data: null });
    },
    // Get banks list
    getBanks() {
        return api.get('user/bankAccounts/banks', { data: null });
    },
    getCurrency() {
        return api.get('user/bankAccounts/currency', { data: null });
    },
    getBankAccountsForGiro({ currency }) {
        const path = `user/bankAccounts/giro?currency=${currency}`;
        return api.get(path, { data: null });
    },
    getDataForTransfer() {
        const path = `investments/transferData`;
        return api.get(path, { data: null });
    },
    /** Returns min CLP/USD amount to withdraw  */
    fetchMinWithdrawalAmount({ currency }) {
        const path = `girar/minAmount?currency=${currency}`;
        return api.get(path, { data: null });
    },
    // returns cut hour for transfers
    getCutHour({ currency, type }) {
        const path = `girar/cutHour?currency=${currency}&type=${type}`;
        return api.get(path, { data: null });
    },
    /** Realiza el giro a una cuenta deseada */
    sendWithdrawal({
        account,
        amount,
        bank,
        bankName,
        secondGenerateToken,
        currency,
        tokenTrx,
    }) {
        const path = 'girar/';
        return api.post(path, {
            account,
            amount,
            bank,
            bankName,
            secondGenerateToken,
            currency,
            tokenTrx,
        });
    },
    addAccount({
        bank,
        type,
        currency,
        account,
        tokenTrx,
        secondGenerateToken = 0,
    }) {
        return api.post('user/bankAccounts', {
            bank,
            type,
            currency,
            account,
            tokenTrx,
            secondGenerateToken,
        });
    },
    deleteAccount(id) {
        return api.delete(`user/bankAccounts/${id}`, { data: null });
    },
};
