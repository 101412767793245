import api from './api.js';

export default {
    getPreferred() {
        const path = `investments/transfer/account`;
        return api.get(path, { data: null });
    },
    setPreferred({ account }) {
        const path = `investments/transfer/account/${account}`;
        return api.post(path, { data: null });
    },
};
