export const useRootEvents = () => {
    const events = [];

    const addListener = (event, callback) => {
        // Avoid duplicating event.
        const eventExists = events.find((e) => e.e === event);
        if (eventExists) {
            return;
        }

        const listener = {
            e: event,
            c: (evt) => {
                callback(evt.detail);
            },
        };
        events.push(listener);
        window.addEventListener(listener.e, listener.c);
    };

    const removeListeners = () => {
        events.forEach((event) => {
            window.removeEventListener(event.e, event.c);
        });
    };

    const emit = (event, args) => {
        window.dispatchEvent(
            new CustomEvent(event, {
                detail: {
                    ...args,
                },
            })
        );
    };

    return { addListener, emit, removeListeners };
};
