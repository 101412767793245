<template>
    <OrqDrawerBody compact>
        <template #body>
            <div class="drawer_content">
                <div class="drawer_content__header bg--primary-extended-80">
                    <OrqColumn class="drawer_content__container" :gap="16">
                        <span
                            class="text--emphasis-base-high-inverse heading-xl"
                        >
                            {{ title }}
                        </span>
                        <span
                            class="text--emphasis-base-medium-inverse body-baseline"
                        >
                            {{ subtitle }}
                        </span>
                    </OrqColumn>
                </div>
                <div class="drawer_content__body">
                    <OrqColumn gap="16">
                        <OrqFlex
                            v-if="items.length > 0"
                            style="justify-content: space-between"
                        >
                            <span class="heading-md text--emphasis-base-high">
                                Fondos a aportar
                            </span>
                            <OrqLink
                                class="underlined"
                                label="Editar"
                                type="primary"
                                @click="editing = !editing"
                            />
                        </OrqFlex>
                        <OrqColumn v-else gap="16">
                            <img
                                src="https://static.btgpactual.cl/apps/apv/apv-empty.svg"
                                height="48"
                                width="48"
                            />
                            <span
                                class="title--title-sm text--emphasis-base-high"
                            >
                                No tienes fondos en tu lista
                            </span>
                            <span
                                class="body-baseline text--emphasis-base-medium"
                                style="width: 90%"
                            >
                                En una cuenta APV Fondos Mutuos, primero debes
                                elegir en qué fondos invertir y luego abonar el
                                saldo.
                            </span>
                        </OrqColumn>
                        <OrqColumn gap="24" style="overflow-y: auto">
                            <div
                                v-for="(i, index) in items"
                                :key="index"
                                style="flex: 1 0 0"
                            >
                                <div
                                    style="
                                        align-items: center;
                                        display: flex;
                                        gap: 16px;
                                    "
                                >
                                    <div
                                        style="width: 40px; height: 40px"
                                        class="movement-icon bg--primary-extended-70"
                                    >
                                        <OrqIconContainer
                                            size="24"
                                            name="money-out"
                                        />
                                    </div>
                                    <div style="display: grid; flex: 1 0 0">
                                        <div
                                            style="
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                            "
                                        >
                                            <span
                                                class="text--emphasis-base-high"
                                                style="
                                                    text-overflow: ellipsis;
                                                    overflow: hidden;
                                                    white-space: nowrap;
                                                "
                                            >
                                                {{ i.symbol }}
                                            </span>
                                            <div
                                                class="body-sm text--emphasis-base-medium"
                                                style="display: flex"
                                            >
                                                <span>Aporte:&nbsp;</span>
                                                <OrqAmount
                                                    :inverse="inverse"
                                                    format="PERCENTSIMPLE"
                                                    :amount="
                                                        percentages[
                                                            i.securityId
                                                        ]
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            v-if="editing"
                                            class="delete-button"
                                        >
                                            <OrqIconContainer
                                                size="24"
                                                name="edit-trash"
                                                @click="
                                                    openDeleteModal(
                                                        i.securityId
                                                    )
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <OrqDivider
                                    style="margin-top: 16px"
                                    :inverse="inverse"
                                />
                            </div>
                        </OrqColumn>
                    </OrqColumn>
                    <OrqFlex style="margin-top: 24px">
                        <OrqButton
                            size="compact"
                            icon-name="add-plus"
                            icon-orientation="right"
                            appearance="line"
                            label="Agregar fondo"
                            @click="onAddmore"
                        />
                        <OrqLink
                            v-if="items.length > 0"
                            class="underlined"
                            label="Eliminar todo"
                            @click="confirmDeleteAll = true"
                        />
                    </OrqFlex>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="footer_content">
                <OrqDivider />
                <div class="footer_content__body">
                    <OrqColumn style="margin-bottom: 32px" gap="0">
                        <span class="body-sm text--emphasis-base-low">
                            Total aportes
                        </span>
                        <OrqAmount
                            class="body-sm text--emphasis-base-medium"
                            :format="'CLP'"
                            :amount="totalAmount"
                        />
                        <div v-if="items.length >= 2" class="info-container">
                            <div style="padding-top: 4px">
                                <OrqIconContainer
                                    name="info-on"
                                    :size="16"
                                    color="status-informative"
                                />
                            </div>
                            <span class="caption-xs text--emphasis-base-medium">
                                El aporte a cada fondo se calcula en porcentaje
                                y puede tener variaciones por redondeos.
                            </span>
                        </div>
                    </OrqColumn>
                    <TransactionalFooter
                        :next-enabled="items.length > 0"
                        :inverse="inverse"
                        :back="false"
                        @next="nextStep()"
                        @cancel="emit('cancel')"
                    />
                </div>
            </div>
        </template>
    </OrqDrawerBody>

    <Teleport v-if="confirmDelete" to="#modals">
        <OrqModal
            :visible="confirmDelete"
            style="z-index: 1010"
            title="Eliminar fondo"
            :show-actions="false"
            :show-one-action="false"
            @on-close="cancelDelete"
        >
            <template #wrapper>
                ¿Quieres eliminar este fondo de la lista?
                <OrqDivider style="margin: 24px 0 24px 0" />
                <div style="display: flex; gap: 16px; justify-content: end">
                    <OrqButton
                        label="Cerrar"
                        appearance="line"
                        @click="cancelDelete"
                    />
                    <OrqButton
                        label="Eliminar"
                        :loading="loadingCreateAPV"
                        @click="submitDelete"
                    />
                </div>
            </template>
        </OrqModal>
        <OrqLockscreen v-if="confirmDelete" z-index="1009" />
    </Teleport>

    <Teleport v-if="confirmDeleteAll" to="#modals">
        <OrqModal
            :visible="confirmDeleteAll"
            style="z-index: 1010"
            title="Eliminar todo"
            :show-actions="false"
            :show-one-action="false"
            primary-button-text="Eliminar"
            secondary-button-text="Cerrar"
            @on-close="cancelDeleteAll"
            @on-primary-click="submitDeleteAll"
            @on-secondary-click="cancelDeleteAll"
        >
            <template #wrapper>
                ¿Quieres eliminar todos los fondos de la lista?
                <OrqDivider style="margin: 24px 0 24px 0" />
                <div style="display: flex; gap: 16px; justify-content: end">
                    <OrqButton
                        label="Cerrar"
                        appearance="line"
                        @click="cancelDeleteAll"
                    />
                    <OrqButton
                        label="Eliminar"
                        :loading="loadingCreateAPV"
                        @click="submitDeleteAll"
                    />
                </div>
            </template>
        </OrqModal>
        <OrqLockscreen v-if="confirmDeleteAll" z-index="1009" />
    </Teleport>
</template>

<script setup>
import {
    OrqColumn,
    OrqDrawerBody,
    OrqAmount,
    OrqFlex,
    OrqButton,
    OrqLink,
    OrqModal,
    OrqIconContainer,
    OrqDivider,
    OrqLockscreen,
} from '@digital/orquestra';
import TransactionalFooter from './TransactionalFooter.vue';

import { storeToRefs } from 'pinia';

import { useApvStore } from '@/_store/apv.store.js';

const apvStore = useApvStore();
const { percentages, totalAmount, loadingCreateAPV } = storeToRefs(apvStore);
const { nextStep } = apvStore;

const emit = defineEmits(['cancel']);

import { ref } from 'vue';
const editing = ref(false);
const confirmDelete = ref(false);
const confirmDeleteAll = ref(false);
const idToDelete = ref(null);

const openDeleteModal = (id) => {
    idToDelete.value = id;
    confirmDelete.value = true;
};

const cancelDelete = () => {
    idToDelete.value = null;
    confirmDelete.value = false;
};

const submitDelete = async () => {
    await apvStore.deleteItemFromAPV(idToDelete.value);
    idToDelete.value = null;
    confirmDelete.value = false;
};

const cancelDeleteAll = () => {
    confirmDeleteAll.value = false;
};

const submitDeleteAll = async () => {
    await apvStore.deleteAllAPV();
    confirmDeleteAll.value = false;
};

defineProps({
    title: {
        type: String,
        default: '',
    },
    subtitle: {
        type: String,
        default: '',
    },
    inverse: {
        type: Boolean,
        default: false,
    },
    items: {
        type: Array,
        default: () => [],
    },
});

import { EVENTS } from '@/consts';
import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();

import { useRouter } from 'vue-router';
const router = useRouter();

const onAddmore = () => {
    if (router.currentRoute.value.name !== 'fondos-mutuos-apv') {
        router.push({ name: 'fondos-mutuos-apv' });
    }
    rootEvents.emit(EVENTS.APV_CLOSE);
};
</script>

<style lang="scss" scoped>
.drawer_content {
    z-index: 1008;

    &__container {
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 48px;
    }
    &__header {
        padding: 40px 0;
    }
    &__body {
        padding: 32px 48px;
    }

    display: flex;
    flex-direction: column;
}

.footer_content {
    &__body {
        padding: 24px 48px 24px 48px;
    }
}

:deep(.orq-link__span) {
    text-decoration: underline;
}

.movement-icon {
    align-content: center;
    text-align: center;
    border-radius: 50%;
}

:deep(.movement-icon > div) {
    color: var(--on-color-emphasis-high-inverse);
}

.delete-button {
    cursor: pointer;
}

.delete-button:hover {
    color: var(--on-color-emphasis-medium);
}

.info-container {
    display: flex;
    gap: 8px;
    margin-top: 16px;
    align-items: start;
}
</style>
