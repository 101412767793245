<template>
    <OrqDrawer :is-open="isDrawerOpen">
        <APVTraspaso v-if="isDrawerOpen" />
    </OrqDrawer>
</template>

<script setup>
import { OrqDrawer } from '@digital/orquestra';
import APVTraspaso from '@/components/transactional-funds/APVTraspaso.vue';
import { onUnmounted, ref } from 'vue';
import { useRootEvents } from '@/composables/useRootEvents';
const { addListener, removeListeners } = useRootEvents();
import { EVENTS } from '@/consts';

const isDrawerOpen = ref(false);

addListener(EVENTS.APV_TRANSFER_OPEN, () => {
    isDrawerOpen.value = true;
});

addListener(EVENTS.APV_TRANSFER_CLOSE, () => {
    isDrawerOpen.value = false;
});

onUnmounted(() => {
    removeListeners();
});
</script>
