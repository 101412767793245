<template>
    <div class="risk-type">
        <OrqIconContainer :name="icon" :color="color" />
        <span class="caption-lg" :class="[textColor, textStyle]">
            {{ label }}
        </span>
    </div>
</template>

<script setup>
import { OrqIconContainer } from '@digital/orquestra';

import { RISK } from '@/consts.js';
import { computed } from 'vue';
const props = defineProps({
    risk: {
        type: String,
        default: '',
        required: true,
        validator: (value) => {
            const isValid = Object.keys(RISK).includes(value);
            if (!isValid) {
                console.warn(
                    'Risk must be one of these:',
                    Object.keys(RISK),
                    'Not:',
                    value
                );
            }
            return Object.keys(RISK).includes(value);
        },
    },
    color: {
        type: String,
        default: 'emphasis-base-low',
    },
    textColor: {
        type: String,
        default: 'text-emphasis-base-high',
    },
    textStyle: {
        type: String,
        default: '',
    },
});

const label = computed(() => {
    if (!Object.hasOwn(RISK, props.risk)) {
        // This shouldn't be happening.
        return '-';
    }
    return RISK[props.risk].label;
});
const icon = computed(() => {
    if (!Object.hasOwn(RISK, props.risk)) {
        return 'pix';
    }
    return RISK[props.risk].icon;
});
</script>

<style lang="scss" scoped>
.risk-type {
    display: flex;
    gap: 8px;
    align-items: center;
}
</style>
