import { defineStore, storeToRefs } from 'pinia';
import { computed, handleError, ref } from 'vue';
import { useTokenStore } from '@/_store/token.store.js';

import { useAccountsStore } from '@/_store/accounts.store.js';
import { useBalanceStore } from '@/_store/balance.store';
import { useInstrumentStore } from '@/_store/instrument.store';

import { RESPONSES_STATUS, TYPES, OPERATION } from '@/consts';

import { usePortfolioStore } from '@/_store/portfolio.store.js';

export const useOrderStore = defineStore('order', () => {
    const order = ref({});
    const orderResponse = ref({});
    const stepIndex = ref(0);
    const nextEnabled = ref(false);

    const instrumentStore = useInstrumentStore();
    const { serviceByType, currentItem } = instrumentStore;
    const { isOpen, isAnexoPending } = storeToRefs(instrumentStore);
    const portfolioStore = usePortfolioStore();

    const balanceStore = useBalanceStore();

    const steps = computed(() => {
        const hasRisk =
            order.value?.operation !== OPERATION.SELL &&
            (isAnexoPending.value || instrumentStore.isOutOfProfile);

        const item = currentItem(order.value.idEncoded);
        const type = item.info?.type?.id;
        const isDAP = type === TYPES.DEPOSITO_A_PLAZO;

        return [
            'init',
            ...(hasRisk ? ['risk'] : []),
            ...(isDAP ? ['simulation'] : []),
            'confirm',
            'completed',
        ];
    });

    const step = computed(() => {
        if (!isOpen.value) {
            return 'closed';
        }
        const isError =
            !orderStatus.value.loading &&
            !!orderStatus.value.error &&
            ![RESPONSES_STATUS.SUCCESS, RESPONSES_STATUS.TOKEN_ERROR].includes(
                orderStatus.value.error
            );
        if (isError) {
            return 'error';
        }
        return steps.value[stepIndex.value];
    });

    const totalSteps = computed(() => {
        return steps.value.length;
    });

    const goToStep = (stepName) => {
        const index = steps.value.findIndex(
            (currentStep) => currentStep === stepName
        );
        stepIndex.value = index;
    };

    const nextLoading = ref(false);
    const nextStep = async (sign = false) => {
        // Sign Anexo A if indicated
        if (sign) {
            try {
                nextLoading.value = true;
                await instrumentStore.setAnexoSign({
                    idEncoded: order.value.idEncoded,
                });
            } catch {
                orderStatus.value.error = RESPONSES_STATUS.SERVER_ERROR;
                orderStatus.value.desc = 'Error while signing Anexo A';
                return;
            } finally {
                nextLoading.value = false;
            }
        }
        stepIndex.value++;
        if (stepIndex.value > totalSteps.value - 1) {
            stepIndex.value = totalSteps.value - 1;
        }
    };

    const prevStep = () => {
        stepIndex.value--;
        if (stepIndex.value < 0) {
            stepIndex.value = 0;
        }
    };

    // Starts a new Order. Do not use for updating data because it will erase all existing data.
    const initOrder = (values) => {
        orderResponse.value = {}; // Resets order response if we have one.
        Object.assign(order.value, values);
    };

    const { token, timer, error: tokenError } = storeToRefs(useTokenStore());

    const accountsStore = useAccountsStore();
    const { currentAccount } = storeToRefs(accountsStore);

    const responseMap = {
        201: RESPONSES_STATUS.SUCCESS,
        400: RESPONSES_STATUS.TOKEN_ERROR,
        403: RESPONSES_STATUS.SERVER_ERROR,
        500: RESPONSES_STATUS.SERVER_ERROR,
    };

    const orderStatus = ref({
        loading: false,
        error: null,
        desc: null,
    });

    const onOperationFinished = async () => {
        orderStatus.value.loading = false;

        // We fetch the new user balance.
        await balanceStore.getBalance();

        // We fetch the new instrument balance.
        await instrumentStore.fetchBalance({
            idEncoded: order.value?.idEncoded,
        });

        // Get new portfolio values
        await portfolioStore.getPortfolio();
    };

    const buy = async () => {
        const item = currentItem(order.value.idEncoded);

        let type;
        if (item.config.multiproduct) {
            // Multiproduct is an special case and must operate as a Fondo Mutuo
            type = TYPES.FONDO_MUTUO;
        } else if (
            item.info.type.id === TYPES.FONDO_MUTUO &&
            item.config.bolsa
        ) {
            // Another special case: check https://btgpactual.atlassian.net/browse/NDO-1000
            type = TYPES.FONDO_DE_INVERSION;
        } else {
            type = item.info.type.id;
        }

        try {
            orderStatus.value.loading = true;
            await initOrder({
                account: Number(currentAccount.value.idCuentaGrupo),
                token: token.value,
                timer: timer.value,
                idBtg: item.info.idBtg,
            });

            // We call the service
            const response = await serviceByType[type].buy(order.value);

            // If we are OK we send the user to the last step
            if ([201, 200].includes(response.status)) {
                orderResponse.value = response.data;
                stepIndex.value = totalSteps.value - 1;
            }
        } catch (error) {
            const { response } = error;
            orderStatus.value.error =
                responseMap[response.status] || RESPONSES_STATUS.ERROR;

            if (response?.status === 400) {
                tokenError.value = RESPONSES_STATUS.TOKEN_ERROR;
            }
        } finally {
            onOperationFinished();
        }
    };

    const sell = async () => {
        const item = currentItem(order.value.idEncoded);

        let type;
        if (item.config.multiproduct) {
            // Multiproduct is an special case and must operate as a Fondo Mutuo
            type = TYPES.FONDO_MUTUO;
        } else if (
            item.info.type.id === TYPES.FONDO_MUTUO &&
            item.config.bolsa
        ) {
            // Another special case: check https://btgpactual.atlassian.net/browse/NDO-1000
            type = TYPES.FONDO_DE_INVERSION;
        } else {
            type = item.info.type.id;
        }

        try {
            orderStatus.value.loading = true;
            await initOrder({
                account: Number(currentAccount.value.idCuentaGrupo),
                token: token.value,
                timer: timer.value,
                idBtg: item.info.idBtg,
            });

            // We call the service
            const response = await serviceByType[type].sell(order.value);

            // If we are OK we send the user to the last step
            if ([201, 200].includes(response.status)) {
                orderResponse.value = response.data;
                stepIndex.value = totalSteps.value - 1;
            }
        } catch (error) {
            const { response } = error;
            orderStatus.value.error =
                responseMap[response.status] || RESPONSES_STATUS.ERROR;

            if (response?.status === 400) {
                tokenError.value = RESPONSES_STATUS.TOKEN_ERROR;
            }
        } finally {
            onOperationFinished();
        }
    };

    const commissionLoading = ref(false);
    const commissionController = ref(new AbortController());

    const cancelCommission = () => {
        // commissionLoading.value = false; // [degt]: We removed this bc it was displaying the button when loading the request
        commissionController.value.abort();
    };

    const getCommission = async () => {
        const item = currentItem(order.value.idEncoded);
        let type;
        if (item.info.type.id === TYPES.FONDO_MUTUO && item.config.bolsa) {
            // Another special case: check https://btgpactual.atlassian.net/browse/NDO-1000
            type = TYPES.FONDO_DE_INVERSION;
        } else {
            type = item.info.type.id;
        }
        const getCommission = serviceByType[type].getCommission;

        commissionController.value.abort();
        commissionController.value = new AbortController();

        if (item.config.comisionEnabled === false) {
            return;
        }

        if (typeof getCommission !== 'function') {
            return;
        }

        commissionLoading.value = true;
        try {
            const res = await getCommission({
                ...order.value,
                idBtg: item.info.idBtg,
                account: Number(currentAccount.value.idCuentaGrupo),
                signal: commissionController.value.signal,
            });
            if (res.data) {
                order.value.commission = res.data.commision || 0; // Please note that the answer had a typo
                order.value.iva = res.data.ivaAmount || 0;
            }
        } catch (error) {
            handleError(error);
        }
        commissionLoading.value = false;
    };

    const getCommissionByAmount = async ({ amount }) => {
        // Reset and init order
        order.value.commission = 0;
        order.value.iva = 0;
        order.value.amount = amount;
        order.value.quantity = 0;

        // Get the first commission so we can have an initial value
        await getCommission();
        // With the first commission we calculate the real commission
        order.value.quantity = Math.floor(
            (amount - order.value.commission - order.value.iva) /
                order.value.price
        );

        // Prevents negative quantity
        if (order.value.quantity < 0) {
            order.value.quantity = 0;
        }

        // Preventing reset the  quantinty
        if (order.value.quantity !== 0) {
            order.value.amount =
                Math.floor(order.value.quantity * order.value.price) +
                order.value.commission +
                order.value.iva;
            await getCommission();

            // Update value to final amount + commission
            order.value.amount =
                Math.floor(order.value.quantity * order.value.price) +
                order.value.commission +
                order.value.iva;
        }
    };

    const getCommissionByQuantity = async ({
        quantity,
        operation = OPERATION.BUY,
    }) => {
        // Resets order
        order.value.commission = 0;
        order.value.iva = 0;
        order.value.amount = Math.floor(quantity * order.value.price);
        order.value.quantity = quantity;

        // Get the first commission so we can have an initial value
        await getCommission();

        // Update value to final amount

        if (operation === OPERATION.BUY) {
            // Buying we Add commission + iva to the amount to be bought.
            order.value.amount =
                Math.floor(order.value.quantity * order.value.price) +
                order.value.commission +
                order.value.iva;
        } else {
            // Selling we Substract commission + iva to the amount to be sold.
            order.value.amount =
                Math.floor(order.value.quantity * order.value.price) -
                order.value.commission -
                order.value.iva;
        }
    };

    const amountRescueAnticipatedLoading = ref(false);
    const getAmountRescueAnticipated = async () => {
        const item = currentItem(order.value.idEncoded);
        const type = item.info.type.id;

        if (
            typeof serviceByType[type].getAmountRescueAnticipated !== 'function'
        ) {
            // Prevents this call if we don't have a service for this
            console.warn('No service for rescue', type);
            return;
        }
        amountRescueAnticipatedLoading.value = true;
        try {
            const res = await serviceByType[type].getAmountRescueAnticipated({
                id: item.info.idBtg,
                account: Number(currentAccount.value.idCuentaGrupo),
                amount: order.value.amount,
            });
            order.value.amountRescueAnticipated = res.data;
        } catch (error) {
            handleError(error);
        }
        amountRescueAnticipatedLoading.value = false;
    };

    const $reset = () => {
        order.value = {};
        stepIndex.value = 0;
        orderStatus.value = {
            loading: false,
            error: null,
            desc: null,
        };
    };

    return {
        stepIndex,
        step,
        totalSteps,
        goToStep,
        nextEnabled,
        nextLoading,
        prevStep,
        nextStep,
        order,
        orderStatus,
        orderResponse,
        initOrder,
        buy,
        sell,
        getCommission,
        getCommissionByAmount,
        getCommissionByQuantity,
        cancelCommission,
        commissionLoading,
        amountRescueAnticipatedLoading,
        getAmountRescueAnticipated,
        $reset,
    };
});
