<template>
    <OrqDrawerBody>
        <template #body>
            <div class="drawer_content">
                <div class="drawer_content__header bg--primary-extended-90">
                    <OrqColumn class="drawer_content__container" :gap="16">
                        <span
                            class="text--emphasis-base-high-inverse body-baseline"
                        >
                            Operación solicitada
                        </span>
                        <OrqColumn
                            class="text--emphasis-base-high-inverse"
                            gap="0"
                        >
                            <div
                                class="heading-2xl"
                                style="display: flex; align-items: center"
                            >
                                <span> Invertiste&nbsp; </span>
                                <OrqAmount
                                    class="text--secondary-base-inverse"
                                    :format="'CLP'"
                                    :amount="totalAmount"
                                />
                            </div>
                            <span class="heading-md">
                                en Abono APV - {{ selectedRegimeText }}
                            </span>
                        </OrqColumn>
                    </OrqColumn>
                </div>
                <div class="drawer_content__body">
                    <OrqColumn :gap="24">
                        <OrqColumn :gap="16" class="text--emphasis-base-medium">
                            <span>
                                Enviamos una copia de la solicitud al correo
                                electrónico {{ user.email }}
                            </span>
                            <span>
                                Te notificaremos cuando los aportes se ingresen.
                            </span>
                        </OrqColumn>
                        <OrqColumn :gap="24">
                            <OrqGrid :cols="5" style="margin-bottom: 8px">
                                <span
                                    class="orq-col-3 heading-md text--emphasis-base-high"
                                >
                                    Abono APV - {{ selectedRegimeText }}
                                </span>
                                <span
                                    class="orq-col-2 heading-md text--emphasis-base-high"
                                    style="text-align: right"
                                >
                                    Porcentaje
                                </span>
                            </OrqGrid>
                            <OrqGrid
                                v-for="(i, index) in items"
                                :key="index"
                                :cols="5"
                                :gap="8"
                            >
                                <span
                                    class="orq-col-4 body-baseline text--emphasis-base-medium"
                                >
                                    {{ i.symbol }}
                                </span>
                                <span
                                    class="orq-col-1 text--on-color-emphasis-medium body-sm"
                                    style="text-align: right"
                                >
                                    <OrqAmount
                                        format="PERCENTSIMPLE"
                                        :amount="percentages[i.securityId]"
                                    />
                                </span>
                            </OrqGrid>
                        </OrqColumn>
                        <OrqDivider :inverse="inverse" />
                        <OrqColumn gap="24">
                            <span class="heading-md text--emphasis-base-high">
                                Origen del abono
                            </span>
                            <span
                                class="body-baseline text--emphasis-base-medium"
                            >
                                <template
                                    v-if="
                                        selectedDepositMethod ==
                                        APV_DEPOSIT_METHODS.FROM_BTG
                                    "
                                >
                                    Cuenta BTG Pactual {{ selectedAccount }}
                                </template>
                                <template v-else> Fintoc </template>
                            </span>
                        </OrqColumn>
                        <OrqDivider :inverse="inverse" />
                    </OrqColumn>
                </div>
            </div>
        </template>

        <template #footer>
            <TransactionalFooter
                :next-enabled="nextEnabled"
                :inverse="inverse"
                next-label="Cerrar"
                :exit="false"
                :back="false"
                @next="onClose()"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import {
    OrqColumn,
    OrqDrawerBody,
    OrqAmount,
    OrqDivider,
    OrqGrid,
} from '@digital/orquestra';
import TransactionalFooter from './TransactionalFooter.vue';

import { storeToRefs } from 'pinia';

import { useApvStore } from '@/_store/apv.store.js';
const apvStore = useApvStore();
const {
    totalAmount,
    selectedRegimeText,
    selectedAccount,
    items,
    selectedDepositMethod,
    percentages,
} = storeToRefs(apvStore);

defineProps({
    title: {
        type: String,
        default: '',
    },
    subtitle: {
        type: String,
        default: '',
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

import { useUserStore } from '@/_store/user.store.js';
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

import { EVENTS, APV_DEPOSIT_METHODS } from '@/consts';
import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();
const onClose = () => {
    apvStore.$reset();
    rootEvents.emit(EVENTS.APV_CLOSE);
};
</script>

<style lang="scss" scoped>
.drawer_content {
    margin-left: -48px;
    margin-right: -48px;

    &__container {
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 48px;
    }
    &__header {
        padding: 40px 0;
    }
    &__body {
        padding: 32px 48px;
    }
}

:deep(.title--title-md) {
    font-size: 20px;
}
</style>
