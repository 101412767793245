import api from './api.js';

export default {
    getItem({ idEncoded }) {
        if (!idEncoded) {
            console.warn('No encoded ID was provided.');
            return;
        }
        const path = `instruments/${idEncoded}`;
        return api.get(path, {
            params: {
                withoutToken: true,
            },
        });
    },
    getRelated(idEncoded) {
        return api.get(`instruments/${idEncoded}/relations`);
    },
    getItems(params, cancellationSignal) {
        return api.get('instruments/search', {
            params: params,
            signal: cancellationSignal,
        });
    },
    getChart({ idEncoded, period, dateStart, dateEnd }, signal) {
        // Preventing calls without params
        if (
            Object.values({ idEncoded, period, dateStart, dateEnd }).filter(
                Boolean
            ).length <= 1
        ) {
            console.warn('No params provided');
            return {};
        }
        const path = `instruments/${idEncoded}/historicalData`;
        return api.get(path, {
            params: {
                period,
                dateStart,
                dateEnd,
                withoutToken: true,
            },
            signal,
        });
    },
    getSectors() {
        return api.get('index?sectorial=true');
    },
    getTypes() {
        return api.get('instruments/types');
    },
    getCategories() {
        return api.get('instruments/tags');
    },
    getMarkets() {
        return api.get('instruments/markets', {
            params: {
                withoutToken: true,
            },
        });
    },
    getClasses() {
        return api.get('instruments/class', {
            params: {
                withoutToken: true,
            },
        });
    },
    getManagers(type) {
        return api.get('instruments/managers', { params: { type } });
    },
    getStrategies(type) {
        return api.get('instruments/strategies', { params: { type } });
    },
    fetchComposition({ idEncoded }) {
        if (!idEncoded) {
            return;
        }

        const path = `instruments/${idEncoded}/composition`;
        return api.get(path, {});
    },
    getResearch(idEncoded) {
        return api.get(`instruments/${idEncoded}/research`, {
            data: null,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
            },
        });
    },
    getCustodians({ cuenta, claseActivo, origen, idInstrumento, portfolio }) {
        return api.get(
            `user/safekeeping/${cuenta}/${claseActivo}/${origen}/${idInstrumento}/${portfolio}`
        );
    },
};
