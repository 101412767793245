import api from './api.js';

export default {
    /**
     * There are some examples. Please delete or modify them as you need
     *  @see https://axios-http.com/docs/intro
     */
    get({ account }) {
        const path = `user/executive/${account}`;
        return api.get(path, { data: null });
    },
};
