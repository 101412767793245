// /**
//  * How to use:
//  * <UIInput v-model="mode" v-rut-format />
//  */

const removeRutFormat = (rut) => {
    var strRut = new String(rut);
    strRut = strRut.replace(/[^\d-kK.]/g, '');
    return strRut.replace(/(\.|-)/g, '');
};

const formatRut = (value) => {
    const unformatted = removeRutFormat(value).trim().toLowerCase();
    if (unformatted.length < 2) return unformatted;
    const length = unformatted.length - 1;
    const rut = unformatted
        .substring(0, length)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    return `${rut}-${unformatted.charAt(length)}`;
};

export const rutFormat = {
    // called right before the element is inserted into the DOM.
    beforeMount(el) {
        el.addEventListener('keyup', (evt) => {
            var charCode = evt.which ? evt.which : evt.keyCode;

            const numbers = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];

            const operationalKeys = [
                8, // backspace: charCode
                9, // tab: charCode
                13, // enter: charCode
                16, // shift: charCode
                17, // control: charCode
                37, // keyArrows: charCode
                38, // keyArrows: charCode
                39, // keyArrows: charCode
                40, // keyArrows: charCode
                75, // k
                91, // cmd L: charCode
                93, // cmd R: charCode
            ];
            const allowed = [...numbers, ...operationalKeys];
            if (
                (evt.ctrlKey || evt.metaKey) &&
                (charCode === 86 || charCode === 67 || charCode === 65)
            ) {
                // ctrl + v o cmd + v
            } else if (!allowed.includes(charCode)) {
                evt.preventDefault();
            }

            setTimeout(() => {
                const elementInput = el.querySelector('input');

                elementInput.value = formatRut(elementInput.value);
                var event = new Event('input', { bubbles: true });
                elementInput.dispatchEvent(event);
            }, 100);
        });
    },
    // called when the bound element's parent component
    // and all its children are mounted.
    mounted(el) {
        const elementInput = el.querySelector('input');

        elementInput.value = formatRut(elementInput.value);
        var event = new Event('input', { bubbles: true });
        elementInput.dispatchEvent(event);
    },
};
