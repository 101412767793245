<template>
    <OrqDrawerBody>
        <template #body>
            <OrqColumn class="column__container" :gap="40">
                <OrqColumn :gap="16">
                    <span
                        class="heading-md"
                        :class="`text--emphasis-base-high${
                            inverse ? '-inverse' : ''
                        }`"
                    >
                        {{ title }}
                    </span>
                    <OrqColumn :gap="8">
                        <span
                            class="total-container heading-lg"
                            :class="{
                                'text--emphasis-base-medium': !inverse,
                                'text--secondary-base-inverse': inverse,
                            }"
                        >
                            <OrqAmount :amount="amount" :format="format" />
                            <span
                                v-if="measure"
                                :class="`text--emphasis-base-medium${
                                    inverse ? '-inverse' : ''
                                }`"
                                >{{ measure }}</span
                            >
                        </span>
                        <span
                            class="heading-lg"
                            :class="`text--emphasis-base-high${
                                inverse ? '-inverse' : ''
                            }`"
                        >
                            {{ headline }}
                        </span>
                    </OrqColumn>
                </OrqColumn>

                <BodyInfo
                    v-for="(i, index) in info"
                    :key="index"
                    :data="i"
                    :inverse="inverse"
                />

                <OrqColumn v-if="downloads.length > 0" :gap="16">
                    <DocButton
                        v-for="(doc, index) in downloads"
                        :key="index"
                        :label="doc?.name"
                        :link="doc?.link"
                        :inverse="inverse"
                    />
                </OrqColumn>

                <OrqColumn v-if="messages.length > 0" :gap="16">
                    <OrqNotificationFixed
                        v-for="(msg, index) in messages"
                        :key="`msg-${index}`"
                        class="caption-xs"
                        kind="info"
                        :label="msg"
                        size="compact"
                        notification-type="fixed"
                    />
                </OrqColumn>
            </OrqColumn>

            <Teleport to="#modals">
                <TokenModal
                    :show="showToken"
                    :loading="orderStatus.loading"
                    :timer="order.showTimer"
                    :inverse="inverse"
                    @submit="triggerOperation()"
                    @cancel="showToken = false"
                />
            </Teleport>
        </template>

        <template #footer>
            <TimerContainer
                v-if="order.showTimer"
                :id-encoded="idEncoded"
                :inverse="inverse"
                @time-up="showToken = false"
            />
            <TransactionalFooter
                :model-value="stepIndex"
                :steps="totalSteps"
                :next-enabled="nextEnabled"
                :inverse="inverse"
                :back="back"
                @next="showToken = true"
                @back="prevStep()"
                @cancel="emit('cancel')"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import {
    OrqColumn,
    OrqAmount,
    OrqDrawerBody,
    OrqNotificationFixed,
} from '@digital/orquestra';
import TransactionalFooter from '../TransactionalFooter.vue';
import BodyInfo from '../body-components/BodyInfo.vue';
import DocButton from '@/components/Shared/DocButton.vue';
import TimerContainer from '@/components/Timer/TimerContainer.vue';
import TokenModal from '@/components/Token/TokenModal.vue';

import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { OPERATION } from '@/consts.js';

import { useOrderStore } from '@/_store/order.store.js';

const orderStore = useOrderStore();
const { order, totalSteps, stepIndex, orderStatus } = storeToRefs(orderStore);
const { buy, sell, prevStep } = orderStore;

const showToken = ref(false);

const emit = defineEmits(['cancel']);

defineProps({
    title: {
        type: String,
        default: '',
    },
    headline: {
        type: String,
        default: '',
    },
    amount: {
        type: Number,
        default: 0,
    },
    format: {
        type: String,
        default: 'CLP',
    },
    measure: {
        type: String,
        default: '',
    },
    info: {
        type: Array,
        default: () => [],
    },
    downloads: {
        type: Array,
        default: () => [],
    },
    messages: {
        type: Array,
        default: () => [],
    },
    idEncoded: {
        type: String,
        default: '',
    },
    inverse: {
        type: Boolean,
        default: false,
    },
    back: {
        type: Boolean,
        default: true,
    },
});

const triggerOperation = () => {
    if (order.value?.operation === OPERATION.BUY) {
        buy();
    } else {
        sell();
    }
};
</script>

<style lang="scss" scoped>
.column {
    &__container {
        padding-top: 32px;
    }
}

.total-container {
    display: flex;
    flex-direction: row;
    gap: 4px;
}
</style>
