<template>
    <OrqDrawerBody>
        <template #header>
            <OrqColumn :gap="16">
                <div
                    class="heading-md"
                    :class="`text--emphasis-base-high${
                        inverse ? '-inverse' : ''
                    }`"
                >
                    Abonar
                </div>
                <div
                    class="heading-lg"
                    :class="`text--emphasis-base-high${
                        inverse ? '-inverse' : ''
                    }`"
                >
                    Por botón de pago
                </div>
            </OrqColumn>
        </template>

        <template #body>
            <OrqColumn :gap="16">
                <OrqSelect
                    v-model="bankSelected"
                    label="Selecciona tu banco"
                    :options="banksList"
                    :inverse="inverse"
                />

                <div v-currency-format="'CLP'">
                    <OrqInput
                        v-model="amount"
                        placeholder="$0"
                        label="Monto a transferir"
                        :max-length="12"
                        :description="amountDescription"
                        :inverse="inverse"
                    />
                </div>
            </OrqColumn>
        </template>
        <template #footer>
            <AbonarFooter
                :next-enabled="isValid"
                next-label="Transferir"
                :inverse="inverse"
                @next="initTransaction"
            >
                <OrqColumn :gap="8" class="body-sm">
                    <div
                        :class="`text--emphasis-base-high${
                            inverse ? '-inverse' : ''
                        }`"
                    >
                        ¿Tu banco no está en la lista?
                        <a
                            :style="{
                                color: `var(--action-secondary-enabled${
                                    inverse ? '-inverse' : ''
                                })`,
                            }"
                            href="#"
                            @click.prevent="openIntercom"
                        >
                            Contáctanos por el chat
                        </a>
                    </div>
                    <div>
                        <a
                            href="https://ayuda.btgpactual.cl/es/articles/6766159-cuando-vere-reflejada-mi-transferencia"
                            target="_blank"
                            :style="{
                                color: `var(--action-secondary-enabled${
                                    inverse ? '-inverse' : ''
                                })`,
                            }"
                            @click.stop
                        >
                            ¿En cuánto tiempo veré reflejados los abonos en mi
                            cuenta?
                        </a>
                    </div>
                </OrqColumn>
            </AbonarFooter>
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();

import { BANKS, EVENTS } from '@/consts';

import {
    OrqDrawerBody,
    OrqInput,
    OrqSelect,
    OrqColumn,
} from '@digital/orquestra';
import { computed, ref } from 'vue';
import {
    removeFormat,
    formatCurrency,
    currencyFormat as vCurrencyFormat,
} from '@/directives/currencyFormat';
import AbonarFooter from './shared/AbonarFooter.vue';
import bdpService from '@/_services/bdp.service';

import { useAbonarStore } from '@/_store/abonar.store';
import { storeToRefs } from 'pinia';
const abonarStore = useAbonarStore();
const { accountSelected } = storeToRefs(abonarStore);

import { useAccountsStore } from '@/_store/accounts.store.js';
const accountStore = useAccountsStore();

const amount = ref();
const bankSelected = ref();

const bdpList = ref([]);
bdpService
    .getBankList({
        account: accountSelected.value || accountStore.selected,
    })
    .then((r) => {
        bdpList.value = r.data;
    });

const banksList = computed(() => {
    return bdpList.value.map((i) => {
        return {
            title: i.desc,
            id: i.id,
            selected: false,
            disabled: false,
        };
    });
});

// Sets min amount for testing localStorage.setItem('DEBUG_MIN_AMOUNT', true)
const debug = !!localStorage.getItem('DEBUG_MIN_AMOUNT');

const MIN_AMOUNT = debug ? 1000 : 5000000;
const maxAmount = computed(() => {
    const bank = Object.values(BANKS).find((i) => i.id === bankSelected.value);
    if (bank) {
        return bank.max;
    }

    return 100000000;
});
const isValid = computed(() => {
    if (removeFormat(amount.value) < MIN_AMOUNT) {
        return false;
    }
    if (removeFormat(amount.value) > maxAmount.value) {
        return false;
    }
    if (!bankSelected.value) {
        return false;
    }
    return true;
});

const amountDescription = computed(() => {
    if (!bankSelected.value) {
        return '';
    }

    if (removeFormat(amount.value) <= 0) {
        return '';
    }

    if (removeFormat(amount.value) < MIN_AMOUNT) {
        return `El monto mínimo por cada transacción es de ${formatCurrency(
            MIN_AMOUNT,
            'CLP'
        )}`;
    }

    return `El monto máximo por cada transacción es de ${formatCurrency(
        maxAmount.value,
        'CLP'
    )}. Puedes realizar más de un abono en un mismo día.`;
});

import { useUserStore } from '@/_store/user.store.js';
const { user } = storeToRefs(useUserStore());

const initTransaction = () => {
    window.open(
        router.resolve({
            name: 'bdp',
            query: {
                account: accountSelected.value || accountStore.selected,
                amount: removeFormat(amount.value),
                bank: bankSelected.value,
                rut: user.value.extraData?.rut,
            },
        }).href,
        '',
        'width=800,height=600'
    );
};

import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();
const openIntercom = () => {
    rootEvents.emit(EVENTS.INTERCOM_OPEN);
};

defineProps({
    inverse: {
        type: Boolean,
        default: false,
    },
});
</script>


