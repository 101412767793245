import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import accountsService from '@/_services/accounts.service';
import { useAccountsStore } from '@/_store/accounts.store.js';
import { storeToRefs } from 'pinia';

export const useAbonarStore = defineStore('abonar', () => {
    const accountStore = useAccountsStore();
    const { accounts } = storeToRefs(accountStore);
    const currentStep = ref(0);
    const shouldSkipFirstStep = computed(() => {
        return accounts.value.length === 1;
    });
    const maxSteps = computed(() => {
        if (shouldSkipFirstStep.value) {
            return 2;
        }
        return 3;
    });
    const methodSelected = ref(0); // 0: transferencia 1: Boton de pago

    const nextStep = () => {
        if (currentStep.value < maxSteps.value - 1) {
            currentStep.value++;
        }
    };

    const prevStep = () => {
        currentStep.value--;
        if (currentStep.value < 0) {
            currentStep.value = 0;
        }
    };

    const accountSelected = ref(null);
    const accountSelectedLoading = ref(false);
    const getAccountSelected = () => {
        accountSelectedLoading.value = true;
        accountsService.getPreferred().then((r) => {
            accountSelected.value = r.data;
            accountSelectedLoading.value = false;
        });
    };

    const setAccountSelected = () => {
        return accountsService.setPreferred({ account: accountSelected.value });
    };

    const $reset = () => {
        currentStep.value = 0;
    };

    return {
        currentStep,
        maxSteps,
        nextStep,
        accountSelected,
        getAccountSelected,
        setAccountSelected,
        accountSelectedLoading,
        methodSelected,
        $reset,
        shouldSkipFirstStep,
        prevStep,
    };
});
