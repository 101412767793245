import { defineStore } from 'pinia';

import internationalFundsService from '@/_services/internationalFunds.service';
import { ref } from 'vue';

export const useInternationalFundsStore = defineStore(
    'internationalFunds',
    () => {
        const USDRate = ref({});

        const fetchUSDRate = async () => {
            const res = await internationalFundsService.getUSDRate();
            if (res.data) {
                USDRate.value = res.data;
            }
            return res.data;
        };

        return {
            USDRate,
            fetchUSDRate,
        };
    }
);
