import api from './api.js';

export default {
    getUSDRate() {
        return api.get('internationalFunds/rate', { data: null });
    },
    /**
     * Asks whether this a fund is open for buying or selling.
     * @param  {String}  idEncoded- Encoded id of the fund.
     * @param  {Integer}  operation - Type of operation, 1 means buying, 2 means selling.
     * @return {Object}  Object representation of the status of the instrument.
     */
    isOpen({ idEncoded, operation, currency = 'CLP' }) {
        return api.get(`/internationalFunds/${idEncoded}/open/${operation}/${currency}`);
    },
    buy({
        account,
        currency = 'CLP',
        idBtg,
        amount,
        prtfolioFFM,
        rate,
        timer,
        token,
    }) {
        return api.post('internationalFunds/purchases', {
            countID: account,
            currency,
            rate: rate.id,
            idFondo: idBtg,
            monto: Math.floor(amount),
            prtfolioFFM,
            secondGenerateToken: timer,
            tokenTrx: token,
        });
    },
    sell({
        account,
        idBtg,
        amount,
        price,
        prtfolioFFM,
        timer,
        token,
        percent,
        type,
    }) {
        return api.post('internationalFunds/sales', {
            countID: account,
            idFondo: idBtg,
            bank: ' ', // This field isn't being used, but needs to be sent or the service fails
            detail: 6, // This field means "Caja corredora BTG Pactual" from investment/paymentForms service as it's the one that's always used
            percent,
            type,
            amount,
            price,
            prtfolioFFM,
            secondGenerateToken: timer,
            tokenTrx: token,
        });
    },
    getBalance({ idBtg, account }) {
        const path = `/internationalFunds/balance/${account}/${idBtg}`;
        return api.get(path, { data: null });
    },
    outOfProfile({ idBtg }) {
        const path = `user/profileAF/${idBtg}`;
        return api.get(path, { data: null });
    },
};
