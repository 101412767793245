<template>
    <div class="info__container">
        <div
            v-for="(row, index) in filteredData"
            :key="index"
            class="info__row"
            :class="{
                [`text--emphasis-base-${opacity}`]: !inverse,
                [`text--emphasis-base-${opacity}-inverse`]: inverse,
            }"
        >
            <div class="info__row--rescue">
                <span class="body-sm">{{ row?.title }}</span>
                <OrqTooltip
                    v-if="row?.tooltip"
                    :label="row?.tooltip"
                    :inverse="inverse"
                >
                    <OrqIconContainer name="info" :size="13" />
                </OrqTooltip>
            </div>
            <div class="info__row--rescue">
                <span v-if="'label' in row?.value" class="body-sm">
                    {{ row?.value?.label }}
                </span>
                <OrqAmount
                    v-if="'amount' in row?.value"
                    class="body-sm"
                    :amount="row?.value?.amount"
                    :format="row?.value?.format"
                    v-bind="row?.value?.props"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { OrqIconContainer, OrqAmount, OrqTooltip } from '@digital/orquestra';
import { computed } from 'vue';

const props = defineProps({
    data: {
        type: Array,
        default: () => [],
    },
    inverse: {
        type: Boolean,
        default: false,
    },
    opacity: {
        type: String,
        default: 'medium',
    },
});

const filteredData = computed(() => {
    return props.data.filter(
        (i) => i.value?.label || i.value?.amount || i?.showZero
    );
});
</script>

<style lang="scss" scoped>
.info {
    &__container {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &--rescue {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
        }
    }
}
</style>
