<template>
    <OrqColumn :gap="8">
        <OrqSelectInline
            class="select__container"
            :items="selectableOptions"
            :label="label"
            :inverse="inverse"
            @on-click="(val) => (selected = val.id)"
        />
        <slot></slot>
    </OrqColumn>
</template>

<script setup>
import { OrqColumn, OrqSelectInline } from '@digital/orquestra';

import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
    options: {
        type: Array,
        default: () => [],
    },
    label: {
        type: String,
        default: '',
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:modelValue', 'blur', 'selected']);

const selectableOptions = computed(() => {
    return props.options.map((option, index) => {
        return {
            id: index,
            title: t(`transactions.${option}`),
            selected: index === 0,
        };
    });
});

const selected = ref(0);

onMounted(() => {
    emit('selected', selected.value);
});

watch(selected, () => {
    emit('update:modelValue', 0);
    emit('selected', selected.value);
});
</script>

<style lang="scss" scoped>
.select {
    &__container {
        margin-left: -8px;
    }
}

:slotted(.price-label) {
    display: flex;
    align-items: center;
    gap: 4px;
}
</style>
