<template>
    <OrqDrawerBody>
        <template #body>
            <BodyOutOfProfile
                v-model:is-checked="isValid"
                multiple
                :risk="user?.extraData?.perfilInversion ?? ''"
                out-of-profile
                @details="$emit('details')"
            />
        </template>
        <template #footer>
            <TransactionalFooter
                :loading="loading || loadingSign"
                :next-enabled="isValid"
                @back="goBack()"
                @next="apvStore.nextStep()"
                @cancel="$emit('cancel')"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';

import { OrqDrawerBody } from '@digital/orquestra';

import BodyOutOfProfile from '@/components/transactional-funds/body-components/BodyOutOfProfile.vue';
import TransactionalFooter from './TransactionalFooter.vue';

import { useApvStore } from '@/_store/apv.store.js';
const apvStore = useApvStore();
const { selectedDepositMethod } = storeToRefs(apvStore);

import { useUserStore } from '@/_store/user.store';
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
});

defineEmits(['next', 'cancel', 'back', 'details']);

const isValid = ref(false);

import { APV_DEPOSIT_METHODS } from '@/consts';
const goBack = () => {
    if (selectedDepositMethod.value == APV_DEPOSIT_METHODS.FROM_BTG) {
        apvStore.goToStep('selectAccount');
    } else {
        apvStore.goToStep('selectDepositMethod');
    }
};
</script>

<style lang="scss" scoped>
.detail {
    &__container {
        max-height: 442px;
        overflow-y: auto;
        padding-right: 8px;
    }
}

.instrument-list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 16px 0px 16px 0px;
    }
}

.instrument {
    gap: 8px;

    &__type {
        line-height: 24px;
    }

    &__title {
        color: var(--emphasis-base-medium);
        line-height: 21px;
    }
}
</style>
