import { defineStore } from 'pinia';
import userService from '@/_services/user.service.js';
import { errorHandler } from '@/_services/errorHandler';
import { ref, computed } from 'vue';
import { cloneDeep } from 'lodash';
import { TIPO_PERSONA } from '@/consts.js';

// import { isCached } from '@/_helpers/cache';

/**
 * Important: We are using 'Setup Store' definition.
 * @see https://pinia.vuejs.org/core-concepts/#setup-stores
 *
 * ref()s become state properties
 * computed()s become getters
 * function()s become actions
 *
 */
export const useUserStore = defineStore(
    'user',
    () => {
        const user = ref({
            rut: '',
            name: '',
            lastName: '',
            email: '',
            phone: '',
            btg: false,
            lastlogin: null,
            onboardingCode: '',
            extraData: {},
            estadoPassword: 1,
            fnacimiento: null,
            captcha: null,
            intercompHash: null,
            qualified: false,
            bank: false,
            codeb: false,
        });

        const userForm = ref({});

        const timestamp = ref(null);
        const loading = ref(false);
        const request = ref(null);
        const hiddenAmounts = ref(false);

        const $reset = () => {
            user.value = {
                rut: '',
                name: '',
                lastName: '',
                email: '',
                phone: '',
                btg: false,
                lastlogin: null,
                onboardingCode: '',
                extraData: {},
                estadoPassword: 1,
                fnacimiento: null,
                captcha: null,
                intercompHash: null,
                qualified: false,
                bank: false,
                codeb: false,
            };
            userForm.value = {};
        };

        const initials = computed(() => {
            if (!user.value.name) {
                return '';
            }
            return user.value.name
                .split('')
                .map((i) => {
                    return i.slice(0, 1).toLowerCase();
                })
                .join('')
                .slice(0, 2);
        });

        const getUser = () => {
            if (loading.value === true) {
                console.log(
                    'Already loading user. Preventing a new call and returning previous call'
                );
                return request.value;
            }

            // if (isCached({ timestamp: timestamp.value })) {
            //     console.log('User call is cached');
            //     return new Promise((resolve) => {
            //         resolve();
            //     });
            // }
            request.value = new Promise((resolve, reject) => {
                loading.value = true;
                userService
                    .get()
                    .then((r) => {
                        user.value = { ...r.data };
                        user.value.extraData = r.data.extraData
                            ? JSON.parse(r.data.extraData)
                            : {};
                        // Preventing to have those concatenated emails like "test@test.com;test2@test.com"
                        user.value.email = r.data.email.replaceAll(';', '\n');
                        const _user = user.value;
                        userForm.value = cloneDeep(_user);
                    })
                    .catch((e) => {
                        reject(e);
                    })
                    .finally(() => {
                        timestamp.value = new Date().getTime();
                        loading.value = false;
                        request.value = null;
                        resolve();
                    });
            });

            return request.value;
        };

        const updateUser = async (password) => {
            try {
                await userService.update({ password, ...userForm.value });
                await getUser();
            } catch (error) {
                errorHandler(error);
                throw error;
            }
        };

        const resetForm = () => {
            userForm.value = cloneDeep(user.value);
        };

        const toggleAmounts = () => {
            hiddenAmounts.value = !hiddenAmounts.value;
        };

        const notifications = ref([]);

        const getNotifications = async () => {
            const r = await userService.getNotifications();
            notifications.value = r.data.toSorted((a, b) => a.id - b.id);
        };

        const toggleNotification = async ({ id }) => {
            const notificationIndex = notifications.value.findIndex((n) => {
                return n.id === id;
            });
            if (notificationIndex !== -1) {
                const notification = notifications.value[notificationIndex];
                await userService.setNotification({
                    id,
                    enabled: !notification.config.push,
                });
                notifications.value[notificationIndex] = {
                    ...notification,
                    config: {
                        push: !notification.config.push,
                    },
                };
            }
        };

        /**
         * Check if current user is BTG Client or not.
         * A BTG client is someone who already complete it's OBOL
         * @returns Boolean
         */
        const isBtg = computed(() => {
            return user.value.btg;
        });

        const isCodeb = computed(() => {
            return user.value.codeb;
        });
        const isBank = computed(() => {
            return user.value.bank;
        });
        const isEnterprise = computed(() => {
            return user.value.extraData?.tipoPersona === TIPO_PERSONA.EMPRESA;
        });

        const isUserMix = computed(() => {
            return user.value.bank && user.value.codeb;
        });

        const isOnlyBank = computed(() => {
            return user.value.bank && !user.value.codeb;
        });

        return {
            getUser,
            updateUser,
            resetForm,
            user,
            userForm,
            loading,
            timestamp,
            hiddenAmounts,
            toggleAmounts,
            initials,
            getNotifications,
            toggleNotification,
            notifications,
            $reset,
            isBtg,
            isBank,
            isCodeb,
            isUserMix,
            isOnlyBank,
            isEnterprise,
        };
    },
    {
        persist: {
            storage: sessionStorage,
            paths: ['hiddenAmounts'],
        },
    }
);
