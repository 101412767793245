export const digitalTheme = {
    name: 'Light Digital Theme',
    properties: {
        // colores monitor
        '--monitor-positive': '#00E175',
        '--monitor-negative': '#F56069',

        '--amount-text-positive': '#159E5C',
        '--amount-text-positive--inverse': '#00E175',

        '--amount-text-negative': '#EB3D47',
        '--amount-text-negative--inverse': '#F56069',
        // Colors in Figma
        '--primary-base': '#195AB4',
        '--primary-base-inverse': '#195AB4',
        '--primary-extended-10': '#D2E5FF',
        '--primary-extended-20': '#B1D2FF',
        '--primary-extended-30': '#87BAFF',
        '--primary-extended-40': '#6BAAFF',
        '--primary-extended-50': '#549CFF',
        '--primary-extended-60': '#307AE0',
        '--primary-extended-70': '#195AB4',
        '--primary-extended-80': '#10408D',
        '--primary-extended-90': '#0B2859',
        '--primary-extended-100': '#05132A',
        '--secondary-base': '#10408D',
        '--secondary-base-inverse': '#87BAFF',
        '--neutral-base': '#FFFFFF',
        '--neutral-base-inverse': '#1F2023',
        '--neutral-extended-10': '#FFFFFF',
        '--neutral-extended-20': '#F5F6F9',
        '--neutral-extended-30': '#D7DBDF',
        '--neutral-extended-40': '#B8BEC4',
        '--neutral-extended-50': '#959CA2',
        '--neutral-extended-60': '#61686E',
        '--neutral-extended-70': '#464C51',
        '--neutral-extended-80': '#34383C',
        '--neutral-extended-90': '#272A2E',
        '--neutral-extended-100': '#1B1E20',
        '--action-main-enabled': '#195AB4',
        '--action-main-hover': '#3E75C0',
        '--action-main-focus': '#195AB4',
        '--action-main-pressed': '#134489',
        '--action-main-selected': '#307AE0',
        '--action-main-enabled-inverse': '#195AB4',
        '--action-main-hover-inverse': '#3E75C0',
        '--action-main-focus-inverse': '#195AB4',
        '--action-main-pressed-inverse': '#134489',
        '--action-main-selected-inverse': '#307AE0',
        '--action-secondary-enabled': '#10408D',
        '--action-secondary-hover': '#234F96',
        '--action-secondary-focus': '#10408D',
        '--action-secondary-pressed': '#0C316B',
        '--action-secondary-selected': '#307AE0',
        '--action-secondary-enabled-inverse': '#87BAFF',
        '--action-secondary-hover-inverse': '#90BFFF',
        '--action-secondary-focus-inverse': '#87BAFF',
        '--action-secondary-pressed-inverse': '#719CD6',
        '--action-secondary-selected-inverse': '#549CFF',
        '--action-neutral-enabled': '#FFFFFF',
        '--action-neutral-hover': '#D6D6D6',
        '--action-neutral-focus': '#FFFFFF',
        '--action-neutral-pressed': '#C2C2C2',
        '--action-neutral-selected': '#F0F0F0',
        '--action-neutral-disabled': '#FFFFFF',
        '--action-neutral-enabled-inverse': '#1F2023',
        '--action-neutral-hover-inverse': '#373737',
        '--action-neutral-focus-inverse': '#1F2023',
        '--action-neutral-pressed-inverse': '#292929',
        '--action-neutral-selected-inverse': '#404040',
        '--action-neutral-disabled-inverse': '#1F2023',
        '--on-color-emphasis-high': '#000000',
        '--on-color-emphasis-medium': '#000000b3',
        '--on-color-emphasis-low': '#00000080',
        '--on-color-emphasis-disabled': '#0000004D',
        '--on-color-emphasis-high-inverse': '#FFFFFF',
        '--on-color-emphasis-medium-inverse': '#FFFFFFB3',
        '--on-color-emphasis-low-inverse': '#FFFFFF80',
        '--on-color-emphasis-disabled-inverse': '#FFFFFF4D',
        '--on-color-emphasis-indicator-highlight-surface': '#D2E5FF',
        '--on-color-emphasis-indicator-highlight-text': '#0B2859',
        '--on-color-emphasis-indicator-highlight-inverse': '#0B2859',
        '--on-color-emphasis-indicator-highlight-text-inverse': '#D2E5FF',
        '--on-color-emphasis-indicator-positive-surface': '#62C395',
        '--on-color-emphasis-indicator-positive-text': '#128850',
        '--on-color-emphasis-indicator-positive-inverse': '#128850',
        '--on-color-emphasis-indicator-positive-text-inverse': '#62C395',
        '--on-color-emphasis-indicator-negative-surface': '#F2757C',
        '--on-color-emphasis-indicator-negative-text': '#CE363F',
        '--on-color-emphasis-indicator-negative-inverse': '#CE363F',
        '--on-color-emphasis-indicator-negative-text-inverse': '#F2757C',
        '--status-success-base': '#159E5C',
        '--status-success-base-surface': '#DAECE5',
        '--status-success-base-inverse': '#62C395',
        '--status-success-base-surface-inverse': '#106A40',
        '--status-warning-base': '#F05800',
        '--status-warning-base-surface': '#FFE7CC',
        '--status-warning-base-inverse': '#FF8752',
        '--status-warning-base-surface-inverse': '#884426',
        '--status-error-base': '#EB3D47',
        '--status-error-base-surface': '#FFDBDD',
        '--status-error-base-inverse': '#F76970',
        '--status-error-base-surface-inverse': '#8B2328',
        '--status-informative-base': '#1F7DFF',
        '--status-informative-base-surface': '#D2E5FF',
        '--status-informative-base-inverse': '#4795FF',
        '--status-informative-base-surface-inverse': '#113D88',
        '--outline-base': '#00000029',
        '--outline-base-inverse': '#FFFFFF29',
        '--outline-monitor-inverse': '#ffffff3f',
        '--outline-base-focus': '#A02795',
        '--outline-base-focus-inverse': '#F217DE',
        '--background-base': '#F5F5F6',
        '--background-base-inverse': '#101010',
        '--support-blue-10': '#D2E5FF',
        '--support-blue-20': '#B1D2FF',
        '--support-blue-30': '#87BAFF',
        '--support-blue-40': '#6BAAFF',
        '--support-blue-50': '#549CFF',
        '--support-blue-60': '#307AE0',
        '--support-blue-70': '#195AB4',
        '--support-blue-80': '#174E9F',
        '--support-blue-90': '#123570',
        '--support-blue-100': '#0B2859',
        '--support-aqua-10': '#D8F0F5',
        '--support-aqua-20': '#BEE6EE',
        '--support-aqua-30': '#87D0DF',
        '--support-aqua-40': '#5ABED3',
        '--support-aqua-50': '#45B6CD',
        '--support-aqua-60': '#329FB5',
        '--support-aqua-70': '#328495',
        '--support-aqua-80': '#2F7482',
        '--support-aqua-90': '#29636E',
        '--support-aqua-100': '#22515A',
        '--support-lime-10': '#EEFBE0',
        '--support-lime-20': '#DAEFC3',
        '--support-lime-30': '#BCDC98',
        '--support-lime-40': '#9BC868',
        '--support-lime-50': '#7EB63E',
        '--support-lime-60': '#6BAA23',
        '--support-lime-70': '#629C20',
        '--support-lime-80': '#53821B',
        '--support-lime-90': '#416616',
        '--support-lime-100': '#395913',
        '--support-green-10': '#E7F3EE',
        '--support-green-20': '#D0EBDF',
        '--support-green-30': '#9DD8BC',
        '--support-green-40': '#62C395',
        '--support-green-50': '#2DB071',
        '--support-green-60': '#149A5A',
        '--support-green-70': '#128850',
        '--support-green-80': '#107444',
        '--support-green-90': '#0E613A',
        '--support-green-100': '#0D5734',
        '--support-orange-10': '#FFD8C7',
        '--support-orange-20': '#FFC7AE',
        '--support-orange-30': '#FFAB86',
        '--support-orange-40': '#FF905E',
        '--support-orange-50': '#FF7E44',
        '--support-orange-60': '#EF7640',
        '--support-orange-70': '#C46135',
        '--support-orange-80': '#9F4F2B',
        '--support-orange-90': '#783C21',
        '--support-orange-100': '#5D2F1A',
        '--support-yellow-10': '#FFF5DA',
        '--support-yellow-20': '#FFEAB5',
        '--support-yellow-30': '#FFDF8C',
        '--support-yellow-40': '#FFD15B',
        '--support-yellow-50': '#FFCA43',
        '--support-yellow-60': '#E8B73D',
        '--support-yellow-70': '#C79C34',
        '--support-yellow-80': '#A37E2B',
        '--support-yellow-90': '#795C20',
        '--support-yellow-100': '#674D1B',
        '--support-red-10': '#FFE5E6',
        '--support-red-20': '#FCCACC',
        '--support-red-30': '#F79EA3',
        '--support-red-40': '#F2757C',
        '--support-red-50': '#ED515A',
        '--support-red-60': '#EB3E48',
        '--support-red-70': '#CE363F',
        '--support-red-80': '#A92C33',
        '--support-red-90': '#7A1F24',
        '--support-red-100': '#60181C',
        '--support-pink-10': '#F1E5ED',
        '--support-pink-20': '#EBD4E3',
        '--support-pink-30': '#DEABCD',
        '--support-pink-40': '#D289BA',
        '--support-pink-50': '#C768A7',
        '--support-pink-60': '#C0549C',
        '--support-pink-70': '#A94A89',
        '--support-pink-80': '#853B6C',
        '--support-pink-90': '#5E2A4D',
        '--support-pink-100': '#49213C',
        '--support-violet-10': '#E6E2F0',
        '--support-violet-20': '#D6CDEA',
        '--support-violet-30': '#BCABE0',
        '--support-violet-40': '#9B80D4',
        '--support-violet-50': '#8562CB',
        '--support-violet-60': '#754CC5',
        '--support-violet-70': '#6A46B2',
        '--support-violet-80': '#563B90',
        '--support-violet-90': '#3D2D66',
        '--support-violet-100': '#322652',
        '--support-purple-10': '#D2D7F0',
        '--support-purple-20': '#C0C6EB',
        '--support-purple-30': '#9BA3E0',
        '--support-purple-40': '#7B84D6',
        '--support-purple-50': '#5B66CD',
        '--support-purple-60': '#4854C7',
        '--support-purple-70': '#3B46AF',
        '--support-purple-80': '#232D84',
        '--support-purple-90': '#111962',
        '--support-purple-100': '#091051',
        '--support-brown-10': '#EDE7E4',
        '--support-brown-20': '#E4D9D3',
        '--support-brown-30': '#D0B8AD',
        '--support-brown-40': '#BE9989',
        '--support-brown-50': '#AA7A64',
        '--support-brown-60': '#9E664D',
        '--support-brown-70': '#8D5A43',
        '--support-brown-80': '#714733',
        '--support-brown-90': '#533122',
        '--support-brown-100': '#3A2014',
        '--support-grey-10': '#F2F2F2',
        '--support-grey-20': '#E5E5E5',
        '--support-grey-30': '#CACACA',
        '--support-grey-40': '#B2B2B2',
        '--support-grey-50': '#9A9A9A',
        '--support-grey-60': '#909090',
        '--support-grey-70': '#808080',
        '--support-grey-80': '#626262',
        '--support-grey-90': '#484848',
        '--support-grey-100': '#373737',
        '--special-colors-fixed-white': '#FFFFFF',
        '--special-fixed-black': '#000000',

        //// Not In Figma
        '--main-state-enabled': '#195ab4ff',
        '--main-state-hover': '#5081c6ff',
        '--main-state-focus': '#195ab4ff',
        '--main-state-pressed': '#134489ff',
        '--main-state-selected': '#195ab4ff',
        '--main-state-disabled': '#ebebebff',
        '--main-state-enabled-inverse': '#b1d2ffff',
        '--main-state-hover-inverse': '#c4ddffff',
        '--main-state-focus-inverse': '#b1d2ffff',
        '--main-state-pressed-inverse': '#87a0c2ff',
        '--main-state-selected-inverse': '#b1d2ffff',
        '--main-state-disabled-inverse': '#1e3966ff',
        '--secondary-state-enabled': '#0fa5adff',
        '--secondary-state-hover': '#48bbc1ff',
        '--secondary-state-focus': '#0fa5adff',
        '--secondary-state-pressed': '#0b7e84ff',
        '--secondary-state-selected': '#0fa5adff',
        '--secondary-state-disabled': '#ebebebff',
        '--secondary-state-enabled-inverse': '#8cedf2ff',
        '--secondary-state-hover-inverse': '#a8f1f5ff',
        '--secondary-state-focus-inverse': '#8cedf2ff',
        '--secondary-state-pressed-inverse': '#6bb4b8ff',
        '--secondary-state-selected-inverse': '#8cedf2ff',
        '--secondary-state-disabled-inverse': '#1e3966ff',
        '--neutral-state-enabled': '#ffffffff',
        '--neutral-state-hover': '#ebebebff',
        '--neutral-state-focus': '#ffffffff',
        '--neutral-state-pressed': '#c2c2c2ff',
        '--neutral-state-selected': '#ffffffff',
        '--neutral-state-disabled': '#ebebebff',
        '--neutral-state-enabled-inverse': '#0b2859ff',
        '--neutral-state-hover-inverse': '#1e3966ff',
        '--neutral-state-focus-inverse': '#0b2859ff',
        '--neutral-state-pressed-inverse': '#071b3cff',
        '--neutral-state-selected-inverse': '#0b2859ff',
        '--neutral-state-disabled-inverse': '#1e3966ff',
        '--emphasis-base-high': '#000000ff',
        '--emphasis-base-medium': '#000000b3',
        '--emphasis-base-low': '#00000080',
        '--emphasis-base-disabled': '#0000004d',
        '--emphasis-base-high-inverse': '#ffffffff',
        '--emphasis-base-medium-inverse': '#ffffffb3',
        '--emphasis-base-low-inverse': '#ffffff80',
        '--emphasis-base-disabled-inverse': '#ffffff4d',
        '--neutral-base-selected': '#307AE0',
        '--neutral-base-selected-hover': '#2e65ad',
        '--neutral-base-disabled': '#E2E2E3',
        '--neutral-base-hover': '#D6D6D6',
        '--neutral-base-inverse-light': '#549CFF',
        '--neutral-base-inverse-hover-light': '#72afff',
        '--outline': '#0000001a',
        '--outline-inverse': '#ffffff1a',
        '--outline-focus': '#a02795ff',
        '--outline-focus-inverse': '#f217deff',
        '--success': 'var(--status-success-base)',
        '--success-surface': 'var(--status-success-base-surface)',
        '--success-inverse': 'var(--status-success-base-inverse)',
        '--success-surface-inverse':
            'var(--status-success-base-surface-inverse)',
        '--warning': 'var(--status-warning-base)',
        '--warning-surface': 'var(--status-warning-base-surface)',
        '--warning-inverse': 'var(--status-warning-base-inverse)',
        '--warning-surface-inverse':
            'var(--status-warning-base-surface-inverse)',
        '--error': 'var(--status-error-base)',
        '--error-surface': 'var(--status-error-base-surface)',
        '--error-inverse': 'var(--status-error-base-surface-inverse)',
        '--error-surface-inverse': 'var(--status-error-base-surface-inverse)',
        '--informative': 'var(--status-informative-base-surface)',
        '--informative-surface': 'var(--status-informative-base-surface)',
        '--informative-inverse':
            'var(--status-informative-base-surface-inverse)',
        '--informative-surface-inverse':
            'var(--status-informative-base-surface-inverse)',
        '--fixed-white': '#ffffffff',
        '--fixed-black': '#000000ff',
        // Override
        '--navbar-bg-color': 'var(--primary-base)',
    },
};
