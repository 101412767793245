import { TYPES, CURRENCY_FORMAT } from '@/consts';

/**
 * Description
 * @param {Array} types
 * @param {Number} totalInvest
 * @param {Number} UFPrice
 * @returns {Array}
 */
export const normalizePortfolio = ({ types, totalInvest, UFPrice }) => {
    const _types = [...types];

    _types.map((type) => {
        return type.items.map((item) => {
            if (item.instrument.info.idCode) {
                item.idEncoded = window.btoa(item.instrument.info.idCode);
            }
            // The next code adds needed data as it isn't in the payload
            item.expiration =
                item.instrument.info.name.split(' - ').at(-1) ?? '-';
            // Note: DAP uf totalMoney comes in UF instead of CLP so we have to make the convertion
            if (
                type.id === TYPES.DEPOSITO_A_PLAZO &&
                item.instrument.config.currency === 'UF'
            ) {
                item.percent =
                    100 * ((UFPrice * item.totalMoney) / totalInvest);
            } else {
                item.percent = 100 * (item.totalMoney / totalInvest);
            }

            item.profitAmount = (item.quantity * item.profit) / 100;

            item.productName = item.instrument.info.name
                .split(' - ')
                .slice(0, 2)
                .join(' - ');

            // This patches currency if the type needs it
            if (type.id == TYPES.BILLETERA_ML)
                item.instrument.config.currency = 'CLP';
            if (type.id == TYPES.MONEDA)
                item.instrument.config.currency = 'USD';

            // This patches currency if value is strange
            const formattedCurrency = item.instrument.config.currency
                .toUpperCase()
                .replace(/\s/g, '');

            item.instrument.config.currency =
                CURRENCY_FORMAT[formattedCurrency] ?? CURRENCY_FORMAT.default;
        });
    });

    return _types;
};
