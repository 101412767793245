import api from './api.js';

export default {
    getCertificates() {
        return api.get('user/TaxCertificates', { data: null });
    },
    downloadCertificate({ id }) {
        const path = `user/TaxCertificates/${id}`;
        return api.get(path, {
            data: null,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
            },
        });
    },
    /**
     * Lists contracts.
     */
    getContracts({ pending }) {
        const path = `user/contracts/${pending ? '' : 'hist'}`;
        return api.get(path, { data: null });
    },
    /**
     * Downloads only one.
     */
    downloadContracts({ pending, id }) {
        const path = `user/contracts/${pending ? 'download' : 'downloadHist'}/${id}`;
        return api.get(path, {
            data: null,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
            },
        });
    },
    signContracts({ data }) {
        return api.post('user/contracts',
            data,
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
    },
    downloadBatchContracts({ pending, data }) {
        const path = `user/contracts/${pending ? 'download' : 'downloadHist'}`;
        
        if (pending) {
            return api.post(path,
                data,
                {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                },
            });
        } else {
             return api.get(path,
                {
                    data: null,
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                },
            });           
        }
    },
    getDocuments({ account, pending }) {
        const path = `user/documents/${account}/${pending ? '' : 'history'}`;
        return api.get(path, { data: null });
    },
    /**
     * Downloads only one.
     */
    downloadDocuments({ account, pending, id }) {
        const path = `user/documents/${pending ? account : 'history'}/download/${id}`;
        return api.get(path, {
            data: null,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
            },
        });
    },
    signDocuments({ account, ids = undefined }) {
        return api.post(`user/documents/${account}/${ids}/1`,
            { data: null }, 
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
            },
        });
    },
    downloadBatchDocuments({ account, pending, ids = undefined }) {
        const path = `user/documents/${account}/${pending ? '' : 'history/'}download/${ids ? ids : ''}`;
        return api.get(path, {
            data: null,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
            },
        });
    },
    getFicha() {
        return api.get('user/ficha', { data: null });
    },
    updateFicha(payload) {
        return api.put('user/ficha', payload);
    },
    getNCG380() {
        return api.get('user/ncg380', { data: null });
    },
    updateNCG380(payload) {
        return api.put('user/ncg380', payload);
    }
};
