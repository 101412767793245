<template>
    <OrqLockscreen v-if="show" :z-index="1010" />
    <OrqModal
        :visible="show"
        size="small"
        title="Se acabó el tiempo"
        :show-actions="false"
        :show-one-action="false"
        style="z-index: 1010"
        :inverse="inverse"
        @on-close="onClose"
    >
        <template #wrapper>
            <OrqColumn>
                <p
                    :class="{
                        'text--emphasis-base-medium': !inverse,
                        'text--emphasis-base-medium-inverse': inverse,
                    }"
                >
                    Por seguridad, tienes 90 segundos para llevar a cabo la
                    operación. Una vez cumplido el tiempo debes volver a
                    empezar, con un nuevo precio del dólar.
                </p>

                <div class="orq-modal__container__footer">
                    <div class="orq-modal__container__footer__button-div">
                        <OrqButton
                            class="orq-button"
                            appearance="primary"
                            size="compact"
                            color="light"
                            label="Reintentar"
                            @click="emit('submit')"
                        />
                    </div>
                </div>
            </OrqColumn>
        </template>
    </OrqModal>
</template>

<script setup>
import {
    OrqColumn,
    OrqModal,
    OrqLockscreen,
    OrqButton,
} from '@digital/orquestra';

const emit = defineEmits(['submit', 'cancel']);

const onClose = () => {
    emit('cancel');
};

defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});
</script>

<style lang="scss" scoped>
.token__input {
    max-width: 320px;
}

:deep(.orq-modal__container__content__slot--small) {
    max-height: 155px;
}
</style>
