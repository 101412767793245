/**
 * Important note:
 * This CurrencyFormat2 does the same as original CurrencyFormat
 * BUT this one works with decimals
 *
 * Please preffer this one over the first CurrencyFormal directive
 * Also, try to migrate everything to this one.
 *
 * Once everything is updated, please delete the previous one.
 */

import { FORMAT_PRESETS } from '@/consts.js';
import { debounce } from 'lodash';

const config = {
    decimalSeparator: ',',
    thousandsSeparator: '.',
};

export const removeFormat = (value) => {
    if (typeof value === 'number') {
        return value;
    }

    const removeExtraCommas = (inputString) => {
        // Find the index of the first comma
        let firstCommaIndex = inputString.indexOf(',');

        // Remove all commas except the first one
        let result =
            inputString.substring(0, firstCommaIndex + 1) +
            inputString.substring(firstCommaIndex + 1).replace(/,/g, '');

        return result;
    };

    let _value = new String(value);
    _value = _value.replace(/[^\d,]/g, ''); // remove all non number chars but leave commas
    _value = removeExtraCommas(_value).replace(',', '.'); // Remove extra commas and replace it for a period
    return Number(_value);
};

const formatNumber = (n, isRightSide = false) => {
    if (isRightSide) {
        return n.replace(/\D/g, '');
    }

    const _n = String(Number(n.replace(/\D/g, ''))); // Cleans 011.222 to 11222
    return _n.replace(/\B(?=(\d{3})+(?!\d))/g, config.thousandsSeparator); // Add separators
};

// Used to format the currency
export const formatCurrency = (value, format = 'CLP') => {
    let _value = removeFormat(value);

    const preset = FORMAT_PRESETS[format];
    if (!preset) {
        console.log(format);
        throw new Error(`[vue-currency-format] Preset ${format} not found`);
    }

    const options = {
        minimumFractionDigits:
            'minDecimalDigits' in preset ? preset.minDecimalDigits : 2,
        maximumFractionDigits:
            'maxDecimalDigits' in preset ? preset.maxDecimalDigits : 2,
    };

    _value = _value.toLocaleString('nl-NL', options);

    return `${preset.preffix || ''}${preset.prefixSpace ? ' ' : ''}${_value}${
        preset.suffix || ''
    }`;
};

// Used to format the Input
const formatInputCurrency = ({ input, isBlur, format }) => {
    // appends Currency format to value, validates decimal side
    // and puts cursor back in right position.
    // get input value
    let input_val = input.value;
    // Format presets

    const preset = FORMAT_PRESETS[format] || {};
    let minDecimals = preset.minDecimalDigits || 0;

    const ZerosAfterDecimals = Array(minDecimals).fill(0).join('');

    // don't validate empty input
    if (input_val === '') {
        return;
    }
    // original length
    let original_len = input_val.length;

    // initial caret position
    let caret_pos = input.selectionStart;

    // check for decimal
    if (input_val.indexOf(config.decimalSeparator) >= 0) {
        // get position of first decimal
        // this prevents multiple decimals from
        // being entered
        let decimal_pos = input_val.indexOf(config.decimalSeparator);
        // split number by decimal point
        let left_side = input_val.substring(0, decimal_pos);
        let right_side = input_val.substring(decimal_pos);
        // add commas to left side of number
        left_side = formatNumber(left_side);
        // validate right side
        right_side = formatNumber(right_side, true);
        // On blur make sure 2 numbers after decimal
        if (isBlur) {
            right_side += ZerosAfterDecimals;
        }
        // Limit decimal to only preset.minDecimalDigits digits
        right_side = right_side.substring(0, minDecimals);
        //
        let decimalSeparator = config.decimalSeparator;
        if (isBlur && right_side.length === 0) {
            decimalSeparator = '';
        }
        // join number by separator
        // Note: Suffix was removed bc it wasn't working well.
        input_val = `${preset.preffix || ''}${
            preset.prefixSpace ? ' ' : ''
        }${left_side}${decimalSeparator}${right_side}${preset.suffix || ''}`;
    } else {
        // no decimal entered
        // add commas to number
        // remove all non-digits
        input_val = formatNumber(input_val);
        input_val = `${preset.preffix || ''}${
            preset.prefixSpace ? ' ' : ''
        }${input_val}${preset.suffix || ''}`;
        const decimalSeparator =
            preset.minDecimalDigits > 0 ? config.decimalSeparator : '';
        // final formatting
        if (isBlur) {
            input_val += `${decimalSeparator}${ZerosAfterDecimals}`;
        }
    }
    // send updated string to input
    input.value = input_val;

    // put caret back in the right position
    let updated_len = input_val.length;
    caret_pos = updated_len - original_len + caret_pos;

    if (document.activeElement === input) {
        if (caret_pos === updated_len && preset.suffix) {
            input.setSelectionRange(caret_pos, caret_pos - 1);
        } else {
            input.setSelectionRange(caret_pos, caret_pos);
        }
    }

    let event = new Event('input', { bubbles: true });
    input.dispatchEvent(event);
};

const preventNotAllowedKeys = (elementInput) => {
    elementInput.addEventListener('keydown', (evt) => {
        let charCode = evt.which ? evt.which : evt.keyCode;

        const numbers = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
        const numbersPad = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105];

        const operationalKeys = [
            8, // backspace: charCode
            9, // tab: charCode
            13, // enter: charCode
            16, // shift: charCode
            17, // control: charCode
            37, // keyArrows: charCode
            38, // keyArrows: charCode
            39, // keyArrows: charCode
            40, // keyArrows: charCode
            91, // cmd L: charCode
            93, // cmd R: charCode
        ];

        const symbolKeys = [
            188, // comma: charCode
            // 190, // period: charCode
        ];
        const allowed = [
            ...numbers,
            ...numbersPad,
            ...operationalKeys,
            ...symbolKeys,
        ];
        if (
            (evt.ctrlKey || evt.metaKey) &&
            (charCode === 86 || charCode === 67 || charCode === 65)
        ) {
            // ctrl + v o cmd + v
        } else if (!allowed.includes(charCode)) {
            evt.preventDefault();
        }
    });
};

const preventDuplicatedCommas = (elementInput) => {
    // Preventing duplicated commas in the input value.
    elementInput.addEventListener('keydown', (evt) => {
        const value = evt.target.value;

        const symbolKeys = [188];

        const allowed = [...symbolKeys];
        if (!allowed.includes(evt.keyCode)) {
            return;
        }

        if (value.includes(',')) {
            evt.preventDefault();
        }
    });
};

// Directive
export const currencyFormat = {
    mounted(el, binding) {
        const elementInput = el.querySelector('input');

        const format = binding.value || undefined;
        const checkDecimal = binding.modifiers.checkDecimal || false;

        formatInputCurrency({
            input: elementInput,
            isBlur: true,
            format: format,
            checkDecimal: checkDecimal,
        });

        preventDuplicatedCommas(elementInput);
        preventNotAllowedKeys(elementInput);

        elementInput.addEventListener(
            'keyup',
            debounce((evt) => {
                if (evt.key === 'Meta') {
                    return;
                }

                formatInputCurrency({
                    input: elementInput,
                    isBlur: false,
                    format: format,
                    checkDecimal: checkDecimal,
                });
            }, 1000)
        );

        elementInput.addEventListener('blur', () => {
            formatInputCurrency({
                input: elementInput,
                isBlur: true,
                format: format,
                checkDecimal: checkDecimal,
            });
        });
    },
};
