import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { i18n } from '@/i18n';

import '../node_modules/@digital/orquestra/dist/style.css'; // Not sure this is the bes way to solve it
import App from './App.vue';
import router from './router';
import anchorBehaviour from '@/plugins/anchorBehaviour';

import VueIntercom from '@homebaseai/vue3-intercom';
import { worker } from './mocks/browser';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import VueDOMPurifyHTML from 'vue-dompurify-html';

if (process.env.NODE_ENV === 'development') {
    if (JSON.parse(localStorage.getItem('MSW')) === true) {
        worker.start();
    }
}

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

import * as Sentry from '@sentry/vue';
Sentry.init({
    app,
    dsn: 'https://1955ef7406515e219504cff7924f9175@o1385036.ingest.us.sentry.io/4507011544580096',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        'localhost',
        'app.btgpactual.cl',
        'next.ml.btgpactual.cl',
        'next.mel-dev.btgpactual.cl',
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(VueDOMPurifyHTML);

app.use(router);
app.use(pinia);
app.use(i18n);
app.use(VueIntercom);
app.use(anchorBehaviour, { router });

app.mount('#app');
