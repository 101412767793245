import { EVENTS } from '@/consts';
import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();

export const useToast = () => {
    const toasty = (payload) => {
        rootEvents.emit(EVENTS.TOAST, payload);
    };
    const close = (payload) => {
        rootEvents.emit(EVENTS.TOAST_CLOSE, payload);
    };

    return {
        toasty,
        close,
    };
};
