<template>
    <OrqDrawerBody>
        <template #header>
            <OrqColumn :gap="16">
                <div
                    class="heading-md"
                    :class="`text--emphasis-base-high${
                        inverse ? '-inverse' : ''
                    }`"
                >
                    Abonar
                </div>
                <div>
                    <AbonarIcon src="/images/transaccion-error.png" />
                </div>
                <div
                    class="heading-lg"
                    :class="`text--emphasis-base-high${
                        inverse ? '-inverse' : ''
                    }`"
                >
                    Hubo un error al procesar la operación.
                </div>
            </OrqColumn>
        </template>

        <template #body>
            <OrqColumn :gap="16">
                <div
                    :class="`text--emphasis-base-medium${
                        inverse ? '-inverse' : ''
                    }`"
                >
                    Vuelve a intentarlo más tarde. Si el problema persiste o
                    tienes dudas, contáctanos por el chat.
                </div>
                <OrqLink
                    label="Contactar al chat"
                    orientation="right"
                    icon="default"
                    type="primary"
                    :inverse="inverse"
                    @click="openIntercom"
                />
            </OrqColumn>
        </template>

        <template #footer>
            <AbonarFooter
                :show-progress="false"
                :show-cancel="false"
                next-label="Cerrar"
                :inverse="inverse"
                @next="onClose"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import { OrqDrawerBody, OrqColumn, OrqLink } from '@digital/orquestra';
import AbonarFooter from './shared/AbonarFooter.vue';
import AbonarIcon from './shared/AbonarIcon.vue';

import { useRootEvents } from '@/composables/useRootEvents';
import { EVENTS } from '@/consts';
const rootEvents = useRootEvents();
const openIntercom = () => {
    rootEvents.emit(EVENTS.INTERCOM_OPEN);
};
const onClose = () => {
    rootEvents.emit(EVENTS.ABONAR_CLOSE);
};

defineProps({
    inverse: {
        type: Boolean,
        default: false,
    },
});
</script>
