<template>
    <OrqColumn>
        <BodyInvestAmount
            v-model.number="usdValue"
            :style="`order: ${inputOrder[0]}`"
            currency="USD"
            icon="https://static.btgpactual.cl/apps/flags/US.svg"
            :label="operationLabel ? operationLabel : t('moneda.amountToBuy')"
            :disabled="inputOrder[0] === 3"
            hide-button
            :inverse="inverse"
            :error-msg="error"
            @update:model-value="calculateByInputCurrency"
        />
        <div style="order: 1">
            <OrqButton
                :label="t('transactions.useAllBalance')"
                appearance="line"
                :inverse="inverse"
                @click="useBalance()"
            />
        </div>
        <div class="separator-container" style="order: 2">
            <OrqDivider />
            <div>
                <a href="#" @click.stop.prevent="switchCurrencies">
                    <OrqIconContainer
                        name="change-user"
                        :size="40"
                        color="emphasis-base-high-inverse"
                        custom-circle-background-color="var(--action-main-enabled)"
                        circle
                    />
                </a>
            </div>
            <OrqDivider />
        </div>
        <BodyInvestAmount
            v-model.number="clpValue"
            :style="`order: ${inputOrder[1]}`"
            currency="CLP"
            icon="https://static.btgpactual.cl/apps/flags/CL.svg"
            :label="t('moneda.amountIn', { currency: 'CLP' })"
            :disabled="inputOrder[1] === 3"
            hide-button
            :error-msg="error"
            :inverse="inverse"
            @update:model-value="calculateByInputCurrency"
        />
    </OrqColumn>
</template>

<script setup>
import {
    OrqDivider,
    OrqIconContainer,
    OrqColumn,
    OrqButton,
} from '@digital/orquestra';
import BodyInvestAmount from '@/components/transactional-funds/body-components/BodyInvestAmount.vue';

import { ref } from 'vue';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

import { storeToRefs } from 'pinia';

import { useOrderStore } from '@/_store/order.store.js';
const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);

import { useBalanceStore } from '@/_store/balance.store.js';
const balanceStore = useBalanceStore();
const { balance } = storeToRefs(balanceStore);

import { formatCurrency, removeFormat } from '@/directives/currencyFormat';

const props = defineProps({
    operationLabel: {
        type: String,
        default: null,
    },
    rate: {
        type: Object,
        default: () => {},
    },
    inverse: {
        type: Boolean,
        default: false,
    },
    error: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:amount']);

order.value.currencyInputUSD = true;
const clpValue = ref(null);
const usdValue = ref(null);

// IMPORTANT! This is different from buying bc we send dollars instead of CLP
const calculateByInputCurrency = () => {
    if (order.value.currencyInputUSD) {
        const val = removeFormat(usdValue.value) * props.rate;
        clpValue.value = formatCurrency(val, 'CLP');
        order.value.amount = removeFormat(usdValue.value);
    } else {
        const val =
            Math.floor(
                (removeFormat(clpValue.value) / props.rate).toFixed(2) * 100
            ) / 100;
        usdValue.value = formatCurrency(val, 'USD');
        order.value.amount = val;
    }
    emit('update:amount');
};

// This is different from buying here we pass USD instead of CLP
const useBalance = () => {
    const valCLP = balance.value.USD * props.rate;
    clpValue.value = formatCurrency(valCLP, 'CLP');

    usdValue.value = formatCurrency(balance.value.USD, 'USD');
    order.value.amount = balance.value.USD;
    emit('update:amount');
};

// Indicates the order of the inputs, changes when pressing the switch button.
const inputOrder = ref([0, 3]);
const switchCurrencies = () => {
    order.value.currencyInputUSD = !order.value.currencyInputUSD;
    inputOrder.value.reverse();
};
</script>

<style lang="scss" scoped>
.separator {
    &-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
</style>
