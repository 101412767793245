import { defineStore } from 'pinia';
import { errorHandler } from '@/_services/errorHandler';
import { ref } from 'vue';

export const storeFactory = ({ name, service }) => {
    return defineStore(name, () => {
        const loading = ref(false);
        const items = ref([]);

        const fetch = async () => {
            loading.value = true;
            try {
                const res = await service.fetchItems();
                items.value = res.data;
            } catch (error) {
                errorHandler(error);
            }
            loading.value = false;
        };

        return {
            items,
            loading,
            fetch,
        };
    });
};
