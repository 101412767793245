<template>
    <OrqDrawerBody>
        <template #header>
            <OrqColumn :gap="16">
                <div class="heading-md">{{ t('withdraw.title') }}</div>
                <div class="heading-lg">Retiro en CLP</div>
            </OrqColumn>
        </template>

        <template #body>
            <OrqColumn>
                <OrqFlex style="justify-content: space-between">
                    <div class="body-sm text--emphasis-base-medium">
                        Saldo disponible
                    </div>
                    <div class="body-sm text--emphasis-base-medium">
                        <OrqAmount :amount="balance.CLP" format="CLP" />
                    </div>
                </OrqFlex>

                <div v-currency-format="'CLP'">
                    <OrqInput
                        v-model="amount"
                        placeholder="$0"
                        :error="investError"
                        :description="investError"
                        label="Monto a retirar"
                        :max-length="12"
                        @blur="v$.$touch()"
                    />
                </div>

                <div>
                    <OrqButton
                        appearance="line"
                        :disabled="balance.CLP == 0"
                        label="Retirar el 100%"
                        @click="takeAll"
                    />
                </div>
            </OrqColumn>
        </template>

        <template #footer>
            <RetirarFooter
                next-label="Retirar"
                :next-enabled="isValid"
                @next="saveAndContinue"
            />
        </template>
    </OrqDrawerBody>

    <Teleport to="#modals">
        <TokenModal
            :show="showToken"
            :loading="postRetiroLoading"
            @submit="triggerOperation()"
            @cancel="showToken = false"
        />
    </Teleport>
</template>

<script setup>
import { computed, ref } from 'vue';
import {
    OrqDrawerBody,
    OrqColumn,
    OrqInput,
    OrqFlex,
    OrqAmount,
    OrqButton,
} from '@digital/orquestra';
import {
    removeFormat,
    formatCurrency,
    currencyFormat as vCurrencyFormat,
} from '@/directives/currencyFormat';
import { useBalanceStore } from '@/_store/balance.store.js';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useRetirarStore } from '@/_store/retirar.store.js';
import RetirarFooter from './shared/RetirarFooter.vue';
import TokenModal from '@/components/Token/TokenModal.vue';
import { useVuelidate } from '@vuelidate/core';
import {
    // overZero,
    nonZero,
    maxValue,
} from '@/_helpers/rules.helper.js';

const { t } = useI18n();
const balanceStore = useBalanceStore();
const { balance } = storeToRefs(balanceStore);
const retirarStore = useRetirarStore();
const { postRetiroLoading } = storeToRefs(retirarStore);

const amount = ref(null);

const rules = computed(() => {
    return {
        lessThanBalance: maxValue(balance.value.CLP, `Saldo insuficiente`),
        nonZero: nonZero(t('transactions.lessThanMinWithdraw')),
    };
});

const v$ = useVuelidate(rules, amount);

const investError = computed(() => v$.value.$errors[0]?.$message ?? false);

const takeAll = () => {
    amount.value = formatCurrency(balance.value.CLP, 'CLP');
};

const isValid = computed(() => {
    if (
        removeFormat(amount.value) > balance.value.CLP ||
        removeFormat(amount.value) === 0
    ) {
        return false;
    }
    return true;
});

const saveAndContinue = () => {
    retirarStore.amount = removeFormat(amount.value);
    showToken.value = true;
};
const showToken = ref(false);
const triggerOperation = () => {
    retirarStore
        .postRetiro()
        .then(() => {
            retirarStore.nextStep();
        })
        .catch(() => {
            retirarStore.nextStep();
        });
};
</script>


