import api from './api.js';

export default {
    getUserBalanceByAccount({ account, currency }) {
        if (!account) {
            console.log('No account was set');
            return new Promise((_, reject) => {
                reject();
            });
        }
        const path = `user/balance/${account}/${currency}`;
        return api.get(path, { data: null });
    },
    getBalanceByInstrument(account, instrument, currency) {
        api.get(`user/balance/${account}/${instrument}/${currency}`, {
            data: null,
        });
    },
    getUserBalanceAccountBank({ account }) {
        if (!account) {
            return new Promise((_, reject) => {
                reject();
            });
        }
        const path = `user/balance/bank/${account}`;
        return api.get(path, { data: null });
    },
};
