// ! This function was created to be used in LimitPrice.vue only, to calculate the limit multiple
// ! be aware that if the number is too small, it my throw the first step of errors, this is cause by the divisions on small numbers... ej: 10,11 may pass and 10,12 may not

const getError = (value, multiple, msg) => {
    if ((value * (1 / multiple)) % 1 != 0) {
        return msg;
    }
    return '';
};

export const isValidPrice = (value) => {
    let multiple = 0;
    let msg = '';

    try {
        if (value > 0 && value <= 1000) {
            return '';
        }

        if (value > 1000 && value <= 10000) {
            multiple = 0.1;
            msg = 'Precio mayor a 1.000 debe ser múltiplo de 0,1';
            return getError(value, multiple, msg);
        }

        if (value > 10000 && value <= 100000) {
            multiple = 1;
            msg = 'Precio mayor a 10.000 debe ser múltiplo de 1';
            return getError(value, multiple, msg);
        }

        if (value > 100000 && value <= 1000000) {
            multiple = 10;
            msg = 'Precio mayor a 100.000 debe ser múltiplo de 10';
            return getError(value, multiple, msg);
        }

        if (value > 1000000 && value <= 10000000) {
            multiple = 100;
            msg = 'Precio mayor a 1.000.000 debe ser múltiplo de 100';
            return getError(value, multiple, msg);
        }

        if (value > 10000000) {
            multiple = 1000;
            msg = 'Precio mayor a 10.000.000 debe ser múltiplo de 1000';
            return getError(value, multiple, msg);
        }

        return '';
    } catch (e) {
        console.log(e);
    }
};
