import api from './api.js';
export default {
    getBankList({ account }) {
        const path = `${account}/bdp`;
        return api.get(path, { data: null });
    },
    initTransaction({ account, amount, bank, rut }) {
        const path = `${account}/bdp`;
        return api.post(path, { account, amount, bank, rut, source: '3' });
    },
};
