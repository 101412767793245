import { createI18n } from 'vue-i18n';
import esCL from './es-CL';

export const i18n = createI18n({
    legacy: false,
    allowComposition: true,
    locale: 'es-CL',
    fallbackLocale: 'es',
    messages: {
        'es-CL': esCL,
    },
});
