<template>
    <img
        :key="imageUrl"
        width="64"
        :src="imageUrl"
        alt=""
        class="icon"
    />
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    src: {
        type: String,
        default: '',
    },
});

const imageUrl = computed(() => {
    return new URL(props.src, import.meta.url).href
})

</script>

<style lang="scss" scoped>
.icon {
    background-color: #d2e5ff;
    border-radius: 999px;
}
</style>
