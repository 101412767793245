<template>
    <OrqDrawerBody>
        <template #header>
            <div class="header__container">
                <OrqColumn :gap="8">
                    <span
                        class="heading-md header__title text--emphasis-base-medium"
                    >
                        {{ title }}
                    </span>
                    <span class="text--secondary-base" style="font-size: 24px">
                        <OrqAmount :format="'CLP'" :amount="totalAmount" />
                    </span>
                </OrqColumn>
            </div>
        </template>

        <template #body>
            <OrqColumn class="column__container" :gap="16">
                <OrqColumn gap="24">
                    <OrqColumn gap="8">
                        <OrqGrid :cols="5" style="margin-bottom: 8px">
                            <span
                                class="orq-col-3 heading-md text--emphasis-base-high"
                            >
                                Abono APV - {{ selectedRegimeText }}
                            </span>
                            <span
                                class="orq-col-2 heading-md text--emphasis-base-high"
                                style="text-align: right"
                            >
                                Porcentaje
                            </span>
                        </OrqGrid>
                        <OrqGrid
                            v-for="(i, index) in items"
                            :key="index"
                            :cols="5"
                            :gap="8"
                        >
                            <span
                                class="orq-col-4 body-baseline text--emphasis-base-medium"
                            >
                                {{ i.symbol }}
                            </span>
                            <span
                                class="orq-col-1 text--on-color-emphasis-medium body-sm"
                                style="text-align: right"
                            >
                                <OrqAmount
                                    format="PERCENTSIMPLE"
                                    :amount="percentages[i.securityId]"
                                />
                            </span>
                        </OrqGrid>
                    </OrqColumn>
                </OrqColumn>
                <OrqDivider :inverse="inverse" />
                <OrqColumn gap="24">
                    <span class="heading-md text--emphasis-base-high">
                        Origen del abono
                    </span>
                    <span class="body-baseline text--emphasis-base-medium">
                        <template
                            v-if="
                                selectedDepositMethod ==
                                APV_DEPOSIT_METHODS.FROM_BTG
                            "
                        >
                            Cuenta BTG Pactual {{ selectedAccount }}
                        </template>
                        <template v-else> Fintoc </template>
                    </span>
                </OrqColumn>
                <OrqDivider :inverse="inverse" />
                <DocButton
                    label="Selección de Alternativas de APV"
                    :loading="loadingDocument"
                    @click="downloadAPVDocument"
                />
            </OrqColumn>
        </template>

        <template #footer>
            <div class="footer_content">
                <div class="footer_content__body">
                    <OrqColumn style="margin-bottom: 32px" gap="0">
                        <span class="body-sm text--emphasis-base-low">
                            Al presionar Siguiente, aceptas haber leído y estar
                            de acuerdo con los términos establecidos.
                        </span>
                    </OrqColumn>
                    <TransactionalFooter
                        :inverse="inverse"
                        @next="showTokenModal = true"
                        @cancel="emit('cancel')"
                        @back="goBack()"
                    />
                </div>
            </div>
        </template>
    </OrqDrawerBody>

    <Teleport to="#modals">
        <TokenModal
            :show="showTokenModal"
            :loading="tokenLoading || loadingSignAPV || loadingFintoc"
            @submit="signAPV"
            @cancel="showTokenModal = false"
        />
    </Teleport>
</template>

<script setup>
import {
    OrqColumn,
    OrqDrawerBody,
    OrqDivider,
    OrqAmount,
    OrqGrid,
} from '@digital/orquestra';
import DocButton from '@/components/Shared/DocButton.vue';
import TransactionalFooter from './TransactionalFooter.vue';
import TokenModal from '@/components/Token/TokenModal.vue';

import { ref, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';

import { useTokenStore } from '@/_store/token.store.js';
const tokenStore = useTokenStore();
const {
    token,
    timer: tokenTimer,
    loading: tokenLoading,
} = storeToRefs(tokenStore);

const showTokenModal = ref(false);
const loadingFintoc = ref(false);

import { useApvStore } from '@/_store/apv.store.js';
const apvStore = useApvStore();
const {
    totalAmount,
    selectedRegimeText,
    selectedAccount,
    apvID,
    loadingSignAPV,
    items,
    percentages,
    selectedDepositMethod,
    operationError,
} = storeToRefs(apvStore);

import { APV_DEPOSIT_METHODS } from '@/consts';
import { useFintoc } from '@/composables/useFintoc';
const { create, widget } = useFintoc();

const signAPV = async () => {
    await apvStore.signAPV({
        token: token.value,
        timer: tokenTimer.value,
    });
    if (selectedDepositMethod.value == APV_DEPOSIT_METHODS.FROM_BTG) {
        apvStore.nextStep();
    } else {
        loadingFintoc.value = true;
        await create({ amount: totalAmount.value });
        showTokenModal.value = false;
        loadingFintoc.value = false;
    }
};

import { useRootEvents } from '@/composables/useRootEvents';
const { addListener, removeListeners } = useRootEvents();

addListener('FINTOC_SUCCESS', () => {
    apvStore.nextStep();
    widget.value.destroy();
});

addListener('FINTOC_EXIT', () => {
    operationError.value = true;
    widget.value.destroy();
});

const emit = defineEmits(['cancel']);

const loadingDocument = ref(false);
import apvService from '@/_services/apv.service';
const downloadAPVDocument = async () => {
    try {
        loadingDocument.value = true;
        const { data } = await apvService.getDocument(apvID.value);
        const blob = new Blob([data], {
            type: 'application/pdf',
        });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
    } finally {
        loadingDocument.value = false;
    }
};

defineProps({
    title: {
        type: String,
        default: '',
    },
    subtitle: {
        type: String,
        default: '',
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

const goBack = () => {
    if (selectedDepositMethod.value == APV_DEPOSIT_METHODS.FROM_BTG) {
        apvStore.goToStep('selectAccount');
    } else {
        apvStore.goToStep('selectDepositMethod');
    }
};

onUnmounted(() => {
    removeListeners();
});
</script>

<style lang="scss" scoped>
.column {
    &__container {
        padding-top: 32px;
        padding-bottom: 40px;
    }
}

.text-button:hover {
    cursor: pointer;
    text-decoration: underline;
}

.header {
    // &__container {}
    &__title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.footer_content {
    margin-left: -48px;
    margin-right: -48px;
    border-top: solid 1px var(--outline);
    &__body {
        padding: 24px 48px 24px 48px;
    }
}
</style>
