import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useFinMarketsStore = defineStore('finmarkets', () => {
    const subscriptions = ref({});
    const uniqueID = ref(1);

    const $reset = () => {
        subscriptions.value = {};
        uniqueID.value = 1;
    };

    return {
        subscriptions,
        uniqueID,
        $reset,
    };
});
