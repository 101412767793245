import { ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';

import { useRouter } from 'vue-router';

import { useAuthStore } from '@/_store/auth.store.js';
import { useUserStore } from '@/_store/user.store.js';

export const useAgencyStore = defineStore('agency', () => {
    const router = useRouter();

    const authStore = useAuthStore();
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore); 

    const agent = ref(false);

    const loading = ref(false);
    const depersonalize = async (token) => {
        loading.value = true;

        await authStore.loginAgent(token);
        await userStore.getUser();

        // If user hasn't logged in yet, user is undefined.
        if (!user.value.rut) return;

        agent.value = true;
        loading.value = false;

        router.push({ name: 'b2b-profile-selection' });
    };

    const MOCK = ref(
        [
            {
                id: 1,
                operacion: {
                    title: 'Aporte',
                    subtitle: 'Fondo mutuo',
                },
                representative: 'Francisco José Valenzuela',
                descripcion: 'BTG Pactual Renta Latinoamericana Serie A',
                fecha: {
                    date: '22/11/23',
                    time: 'Mañana a las 10:24',
                },
                monto: {
                    title: '$ 345.454',
                    subtitle: '4,47 cuotas',
                },
                estado: {
                    label: 'Aprobada',
                    type: 'success',
                },
            },
            {
                id: 2,
                operacion: {
                    title: 'Compra',
                    subtitle: 'Depósitos a plazo',
                },
                representative: 'Francisco José Valenzuela',
                descripcion: 'DAP - CLP ',
                fecha: {
                    date: '22/11/23',
                    time: 'Mañana a las 10:24',
                },
                monto: {
                    title: '$ 20.500.000',
                    subtitle: '',
                },
                estado: {
                    label: 'Vencida',
                    type: 'warning',
                },
            },
            {
                id: 3,
                operacion: {
                    title: 'Rescate',
                    subtitle: 'Fondos mutuos',
                },
                representative: 'Francisco José Valenzuela',
                descripcion: 'BTG Pactual Money Market',
                fecha: {
                    date: '22/11/23',
                    time: 'Mañana a las 10:24',
                },
                monto: {
                    title: '80%',
                    subtitle: '',
                },
                estado: {
                    label: 'Rechazada',
                    type: 'negative',
                },
            },
            {
                id: 4,
                operacion: {
                    title: 'Venta',
                    subtitle: 'Fondos de inversión',
                },
                representative: 'Francisco José Valenzuela',
                descripcion: 'FI ETF Singular S&P 500',
                fecha: {
                    date: '22/11/23',
                    time: 'Mañana a las 10:24',
                },
                monto: {
                    title: 'US$ 1.445,92',
                    subtitle: '',
                },
                estado: {
                    label: 'Cancelada',
                    type: 'negative',
                },
            },
            {
                id: 5,
                operacion: {
                    title: 'Compra',
                    subtitle: 'Acción',
                },
                representative: 'Francisco José Valenzuela',
                descripcion: 'ENELAM',
                fecha: {
                    date: '22/11/23',
                    time: 'Mañana a las 10:24',
                },
                monto: {
                    title: '$ 54.456',
                    subtitle: '25 acciones',
                },
                estado: {
                    label: 'En proceso',
                    type: 'informative',
                },
            },
            {
                id: 6,
                operacion: {
                    title: 'Aporte',
                    subtitle: 'Fondo mutuo',
                },
                representative: 'Camilo Sosa',
                descripcion: 'BTG Pactual Renta Latinoamericana Serie A',
                fecha: {
                    date: '22/11/23',
                    time: 'Mañana a las 10:24',
                },
                monto: {
                    title: '$ 345.454',
                    subtitle: '4,47 cuotas',
                },
                estado: {
                    label: 'Aprobada',
                    type: 'success',
                },
            },
            {
                id: 7,
                operacion: {
                    title: 'Compra',
                    subtitle: 'Depósitos a plazo',
                },
                representative: 'Camilo Sosa',
                descripcion: 'DAP - CLP ',
                fecha: {
                    date: '22/11/23',
                    time: 'Mañana a las 10:24',
                },
                monto: {
                    title: '$ 20.500.000',
                    subtitle: '',
                },
                estado: {
                    label: 'Vencida',
                    type: 'warning',
                },
            },
            {
                id: 8,
                operacion: {
                    title: 'Rescate',
                    subtitle: 'Fondos mutuos',
                },
                representative: 'Camilo Sosa',
                descripcion: 'BTG Pactual Money Market',
                fecha: {
                    date: '22/11/23',
                    time: 'Mañana a las 10:24',
                },
                monto: {
                    title: '80%',
                    subtitle: '',
                },
                estado: {
                    label: 'Rechazada',
                    type: 'negative',
                },
            },
            {
                id: 9,
                operacion: {
                    title: 'Venta',
                    subtitle: 'Fondos de inversión',
                },
                representative: 'Camilo Sosa',
                descripcion: 'FI ETF Singular S&P 500',
                fecha: {
                    date: '22/11/23',
                    time: 'Mañana a las 10:24',
                },
                monto: {
                    title: 'US$ 1.445,92',
                    subtitle: '',
                },
                estado: {
                    label: 'Cancelada',
                    type: 'negative',
                },
            },
            {
                id: 10,
                operacion: {
                    title: 'Compra',
                    subtitle: 'Acción',
                },
                representative: 'Camilo Sosa',
                descripcion: 'ENELAM',
                fecha: {
                    date: '22/11/23',
                    time: 'Mañana a las 10:24',
                },
                monto: {
                    title: '$ 54.456',
                    subtitle: '25 acciones',
                },
                estado: {
                    label: 'En proceso',
                    type: 'informative',
                },
            },
        ]
    );
    const requests = ref({
        pending: MOCK,
        historic: MOCK,
    });

    return {
        requests,
        agent,
        loading,
        depersonalize,
    };
});
