<template>
    <OperationCompleted v-bind="data[order.operation]" />
</template>

<script setup>
import OperationCompleted from '@/components/transactional-funds/Shared/OperationCompleted.vue';
import { useOrderStore } from '@/_store/order.store.js';
import { storeToRefs } from 'pinia';
import { OPERATION } from '@/consts.js';
import { formatDate } from '@/utils/text.js';

import { useInstrumentStore } from '@/_store/instrument.store.js';
const instrumentStore = useInstrumentStore();
const { isMarketOpen } = storeToRefs(instrumentStore);

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const orderStore = useOrderStore();
const { order, orderResponse } = storeToRefs(orderStore);

const props = defineProps({
    item: {
        type: Object,
        default: () => ({}),
    },
});

const data = computed(() => ({
    [OPERATION.BUY]: {
        title: t('transactions.success.invest'),
        amount:
            order.value.amount -
            ((order.value?.commission ?? 0) + (order.value?.iva ?? 0)),
        format: order.value.currency,
        headline: t('transactions.in', { name: props.item?.info?.name }),
        outOfSchedule: !isMarketOpen.value?.open,
        cancelable: false,
        notification: t('transactions.success.differentTotalMsg'),
        info: [
            {
                title: t('transactions.success.transactionDate'),
                value: {
                    label: (() => {
                        if (isMarketOpen.value?.open) {
                            return formatDate(
                                isMarketOpen.value?.nextOpen,
                                'dayOfWeek'
                            );
                        }

                        return formatDate(
                            isMarketOpen.value?.afterNextOpen,
                            'dayOfWeek'
                        );
                    })(),
                },
            },
            {
                title: t('transactions.success.execDate'),
                value: {
                    label: formatDate(
                        orderResponse.value.liqDate * 1000,
                        'dayOfWeek'
                    ),
                },
                tooltip: t('transactions.success.execDateTooltip'),
            },
            {
                title: t('transactions.commissionAndIVA'),
                value: {
                    format: props.item?.config?.currency ?? 'CLP',
                    amount: props.item?.config?.comisionEnabled === false ? 0 : order.value?.commission + order.value?.iva,
                },
                // showZero: true,
            },
            {
                title: t('transactions.success.total'),
                value: {
                    format: props.item?.config?.currency ?? 'CLP',
                    amount: props.item?.config?.comisionEnabled === false ? 0 : order.value.amount,
                },
                // tooltip: t('transactions.success.totalTooltip'),
                // showZero: true,
            },
        ],
    },
    [OPERATION.SELL]: {
        title: t('transactions.success.retrieve'),
        amount: order.value.quantity,
        format: 'PLAIN',
        measure: t('transactions.quotas', order.value.quantity),
        headline: t('transactions.of', { name: props.item?.info?.name }),
        outOfSchedule: !isMarketOpen.value?.open,
        cancelable: false,
        notification: t('transactions.finalAmountReminder'),
        info: [
            {
                title: t('transactions.success.transactionDate'),
                value: {
                    label: (() => {
                        if (isMarketOpen.value?.open) {
                            return formatDate(new Date(), 'dayOfWeek');
                        }

                        return formatDate(
                            isMarketOpen.value?.nextOpen,
                            'dayOfWeek'
                        );
                    })(),
                },
            },
            {
                title: t('transactions.success.liquidityDate'),
                value: {
                    label: formatDate(
                        orderResponse.value.liqDate * 1000,
                        'dayOfWeek'
                    ),
                },
                tooltip: t('transactions.success.liquidityDateTooltip'),
            },
            {
                title: t('transactions.success.estSell'),
                value: {
                    format: props.item?.config?.currency ?? 'CLP',
                    amount: order.value?.amount,
                },
                showZero: true,
            },
        ],
    },
}));
</script>
