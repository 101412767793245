import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import notificationsService from '../_services/notifications.service';

/**
 * Important: We are using 'Setup Store' definition.
 * @see https://pinia.vuejs.org/core-concepts/#setup-stores
 *
 * ref()s become state properties
 * computed()s become getters
 * function()s become actions
 *
 */
export const useNotificationsStore = defineStore('notifications', () => {
    const loading = ref(false);
    const notifications = ref([]);

    const getNotifications = async () => {
        loading.value = true;
        const res = await notificationsService.getAll();
        notifications.value =
            res.status === 200
                ? res?.data.map((el) => ({
                      ...el,
                      notificationDate: el?.notificationDate * 1000,
                      notificationReadDate: el?.notificationReadDate * 1000
                  }))
                : [];
        loading.value = false;
    };

    const pendingNotification = computed(() => {
        return notifications.value.some((notification) => !notification?.read);
    });

    const readNotification = async ({ id }) => {
        loading.value = true;
        await notificationsService.readItem({ id });
        await getNotifications();
        loading.value = false;
    };

    const readAllNotifications = async () => {
        loading.value = true;
        await notificationsService.readAll();
        await getNotifications();
        loading.value = false;
    };

    return {
        notifications,
        getNotifications,
        readNotification,
        readAllNotifications,
        pendingNotification
    };
});
