/**
 *  Remove autocomplete from intput
 *
 *  We are using this because at this time, OrqInput doesn't support autocomplete off
 *
 * how to use:
 * <div v-remove-autocomplete>
 *  <OrqInput ... />
 * </div>
 */
export const removeAutocomplete = {
    mounted(el) {
        const elementInput = el.querySelector('input');
        elementInput.autocomplete = 'off';
    },
};
