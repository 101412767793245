<template>
    <template v-if="operationError">
        <OperationError @close="onClose" />
    </template>
    <template v-else-if="step === 'confirm'">
        <ConfirmOperation
            v-bind="{
                title: `Vas a traspasar tu APV`,
            }"
            @next="step = 'completed'"
        />
    </template>
    <template v-else-if="step === 'completed'">
        <OperationCompleted />
    </template>
</template>

<script setup>
import ConfirmOperation from '@/components/transactional-funds/APVTransfer/ConfirmOperation.vue';
import OperationCompleted from '@/components/transactional-funds/APVTransfer/OperationCompleted.vue';
import OperationError from '@/components/transactional-funds/Shared/OperationError.vue';

import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useApvStore } from '@/_store/apv.store.js';
const apvStore = useApvStore();
const { operationError } = storeToRefs(apvStore);

const step = ref('confirm');

import { EVENTS } from '@/consts';
import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();

const onClose = () => {
    operationError.value = false;
    rootEvents.emit(EVENTS.APV_TRANSFER_CLOSE);
};
</script>
