<template>
    <OrqDrawerBody>
        <template #body>
            <BodyOutOfProfile
                v-model:is-checked="isValid"
                :risk="user?.extraData?.perfilInversion ?? ''"
                :anexo="isAnexoPending"
                :out-of-profile="isOutOfProfile"
                :inverse="inverse"
            />
        </template>

        <template #footer>
            <TimerContainer
                v-if="order.timer"
                :inverse="inverse"
                :id-encoded="props.item?.idEncoded"
            />
            <TransactionalFooterContainer
                :inverse="inverse"
                :next-enabled="nextEnabled"
                :sign="isAnexoPending"
                @cancel="emit('cancel')"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import { OrqDrawerBody } from '@digital/orquestra';

import BodyOutOfProfile from '@/components/transactional-funds/body-components/BodyOutOfProfile.vue';
import TransactionalFooterContainer from '../TransactionalFooterContainer.vue';
import TimerContainer from '@/components/Timer/TimerContainer.vue';

import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useOrderStore } from '@/_store/order.store.js';
import { useInstrumentStore } from '@/_store/instrument.store.js';
import { useUserStore } from '@/_store/user.store';

const orderStore = useOrderStore();
const { order, nextEnabled } = storeToRefs(orderStore);
const instrumentStore = useInstrumentStore();
const { isAnexoPending, isOutOfProfile } = storeToRefs(instrumentStore);
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

defineProps({
    steps: {
        type: Number,
        default: 4,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
    item: {
        type: Object,
        default: () => {},
    },
});

const emit = defineEmits(['cancel']);

const isValid = ref(false);

watch(
    isValid,
    (value) => {
        nextEnabled.value = value;
    },
    { immediate: true }
);
</script>


