<template>
    <OrqDrawerBody>
        <template #header>
            <TransactionalHeader
                :title="title"
                :subtitle="subtitle"
                :inverse="inverse"
            />
        </template>

        <template #body>
            <OrqColumn :gap="48">
                <BodyAmount
                    :title="t('transactions.availableQuotas')"
                    :amount="insBalance.quantity"
                    currency="PLAINEXT"
                    :inverse="inverse"
                    hide-link
                />
                <BodySellAmount
                    v-model:amount.number="order.percent"
                    v-focus
                    :mode="SELL_MODES.PERCENT"
                    :label="t('transactions.percentToRescue')"
                    :buttons="percentButtons"
                    :available-balance="insBalance.quantity"
                    currency="PERCENT"
                    :error-msg="errorMsg"
                    :inverse="inverse"
                    @update:amount="calculateQuantity()"
                />
                <BodyInfo :data="investInfo" :inverse="inverse" />
            </OrqColumn>
        </template>

        <template #footer>
            <TransactionalFooter
                :steps="totalSteps"
                :next-enabled="isValid"
                :inverse="inverse"
                @next="nextStep()"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import { computed } from 'vue';
import TransactionalHeader from '../TransactionalHeader.vue';
import TransactionalFooter from '../TransactionalFooter.vue';
import BodySellAmount from '../Shared/BodySellAmount.vue';
import BodyInfo from '../body-components/BodyInfo.vue';
import BodyAmount from '../body-components/BodyAmount.vue';
import { OrqDrawerBody, OrqColumn } from '@digital/orquestra';
import { TYPES_DATA, OPERATION, SELL_MODES } from '@/consts';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useVuelidate } from '@vuelidate/core';
import { overZero, nonZero, maxValue } from '@/_helpers/rules.helper.js';

import { useOrderStore } from '@/_store/order.store.js';
const orderStore = useOrderStore();
const { order, totalSteps } = storeToRefs(orderStore);
const { initOrder, nextStep } = orderStore;

import { useAccountsStore } from '@/_store/accounts.store.js';
const accountsStore = useAccountsStore();
const { accounts, currentAccount } = storeToRefs(accountsStore);

import { useInstrumentStore } from '@/_store/instrument.store.js';
const instrumentStore = useInstrumentStore();
const { paymentMethods, balance: insBalance } = storeToRefs(instrumentStore);

const calculateQuantity = () => {
    v$.value.$touch();
    order.value.quantity =
        insBalance.value.quantity * (order.value.percent ?? 0) * 0.01;
    order.value.amount = order.value.quantity * price.value;
};

const price = computed(() => {
    return props.item?.insPrice?.price;
});

const detail = computed(() => {
    return paymentMethods[0]?.paramCodigo;
});

const bank = computed(() => {
    return currentAccount.id;
});

const portfolioFFMM = computed(() => {
    return currentAccount.value.prtfolioFFM
        ? currentAccount.value.prtfolioFFM
        : accounts.value[0]?.prtfolioFFM;
});

const { t } = useI18n();

const title = computed(() => {
    return props.item?.info?.name;
});

const subtitle = computed(() => {
    return TYPES_DATA[props.item?.info?.type?.id]?.name ?? '';
});

const percentButtons = computed(() => [
    {
        label: '25%',
        percent: 25,
    },
    {
        label: '50%',
        percent: 50,
    },
    {
        label: '75%',
        percent: 75,
    },
    {
        label: '100%',
        percent: 100,
    },
]);

const props = defineProps({
    item: {
        type: Object,
        default: () => ({}),
    },
    balance: {
        type: Number,
        default: 0,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

const investInfo = computed(() => {
    const data = [
        {
            title: t('transactions.liquidityPeriod'),
            value: {
                label: t('transactions.day', props.item?.config?.rescTime),
            },
        },
    ];
    return data;
});

const rules = computed(() => {
    const percentRules = {
        quantityOverZero: overZero(insBalance.value.quantity),
        noMoreThanTotal: maxValue(100, t('transactions.moreThanAmountSell')),
        nonZero: nonZero(t('transactions.lessThanMinSell')),
    };

    return {
        percent: percentRules,
    };
});

const v$ = useVuelidate(rules, order);

const errorMsg = computed(() => v$.value.$errors[0]?.$message ?? null);

const isValid = computed(
    () => v$.value.$dirty && v$.value.$errors.length === 0
);

initOrder({
    idEncoded: props.item?.idEncoded,
    amount: 0,
    bank: bank.value,
    detail: detail.value,
    percent: 0,
    price: price.value,
    type: SELL_MODES.PERCENT,
    prtfolioFFM: portfolioFFMM.value,
    operation: OPERATION.SELL,
    quantity: insBalance.value.quantity * (order.value.percent ?? 0) * 0.1,
    currency: props.item?.config?.currency,
});
</script>
