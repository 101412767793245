import { defineStore } from 'pinia';
import tokenService from '@/_services/token.service';
import obolService from '@/_services/obol.service';
import { errorHandler } from '@/_services/errorHandler';
import { ref, computed } from 'vue';
import { TOKEN_TYPE } from '@/consts.js';
import { useUserStore } from '@/_store/user.store.js';
import { storeToRefs } from 'pinia';

export const useTokenStore = defineStore('token', () => {
    const timer = ref(0);
    const MAX_TOKEN = 58;
    const token = ref('');
    const qr = ref(null);
    const loading = ref(false);
    const qrLoading = ref(false);
    const error = ref(null);

    const timerInterval = ref(null);

    const timeLeft = computed(() => MAX_TOKEN - timer.value);

    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);

    const type = computed(() => {
        return user.value.extraData.tokenType || TOKEN_TYPE.SMS;
    });

    const runTimer = () => {
        timer.value = 0;
        timerInterval.value = setInterval(() => {
            timer.value = timer.value + 1;
            if (timer.value > MAX_TOKEN) {
                clearInterval(timerInterval.value);
            }
        }, 1000);
    };

    const requestToken = async () => {
        if (type.value === TOKEN_TYPE.SOFTTOKEN) {
            return;
        }
        try {
            loading.value = true;
            token.value = '';
            // The timer must start before the api call
            runTimer();
            await tokenService.requestToken();
        } catch (error) {
            errorHandler(error);
        }
        loading.value = false;
    };

    const requestObolToken = async () => {
        try {
            loading.value = true;
            token.value = '';
            // The timer must start before the api call
            runTimer();
            await obolService.requestToken();
        } catch (error) {
            errorHandler(error);
        }
        loading.value = false;
    };

    // This is a parcial reset of the token. If you want to empty the store, use $reset()
    const resetInput = () => {
        token.value = '';
        error.value = null;
    };

    const $reset = () => {
        timer.value = 0;
        token.value = '';
        loading.value = false;
        error.value = null;

        if (timerInterval.value) {
            clearInterval(timerInterval.value);
            timerInterval.value = null;
        }
    };

    const activateSoftToken = async (token) => {
        loading.value = true;
        error.value = null;
        try {
            await tokenService.activateSoftToken({ token });
            user.value.extraData.tokenType = TOKEN_TYPE.SOFTTOKEN;
        } catch(serviceError) {
            error.value = serviceError;
        } finally {
            loading.value = false;
        }
    };
    
    const deactivateSoftToken = async (token) => {
        loading.value = true;
        error.value = null;
        try {
            await tokenService.deactivateSoftToken({ token });
            user.value.extraData.tokenType = TOKEN_TYPE.SMS;
        } catch(serviceError) {
            error.value = serviceError;
        } finally {
            loading.value = false;
        }
      };

    const getQR = async () => {
        qrLoading.value = true;
        const r = await tokenService.getQR();
        const reader = new window.FileReader();
        reader.onload = () => {
            qr.value = reader.result;
            qrLoading.value = false;
        };
        reader.readAsDataURL(r.data);
    };

    return {
        timer,
        type,
        requestToken,
        requestObolToken,
        token,
        qr,
        activateSoftToken,
        deactivateSoftToken,
        getQR,
        timeLeft,
        loading,
        qrLoading,
        error,
        resetInput,
        $reset,
    };
});
