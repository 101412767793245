import { defineStore } from 'pinia';
import { ref } from 'vue';

/**
 * Important: We are using 'Setup Store' definition.
 * @see https://pinia.vuejs.org/core-concepts/#setup-stores
 *
 * ref()s become state properties
 * computed()s become getters
 * function()s become actions
 *
 */
export const useTimersStore = defineStore('timers', () => {
    const timers = ref({});

    const updatePercent = (timer) => {
        timer.currentTime += 1 / timer.ticksPerSecond;
        if (timer.currentTime >= timer.seconds) {
            timer.timeUp = true;
            if (!timer.loop) {
                clearInterval(timer.interval);
            } else {
                timer.currentTime =
                    timer.seconds - timer.currentTime < 0
                        ? 0
                        : timer.seconds - timer.currentTime;
            }
        }
    };

    const setTimer = (
        id,
        seconds = 10,
        ticksPerSecond = 1,
        reversed = false
    ) => {
        // Do nothing if id already exists exists
        if (id in timers.value) {
            return;
        }
        timers.value[id] = {
            seconds,
            ticksPerSecond,
            reversed,
            currentTime: 0,
            timeUp: false,
        };
        timers.value[id].interval = setInterval(
            () => updatePercent(timers.value[id]),
            1000 / timers.value[id].ticksPerSecond
        );
    };

    const clearTimer = (id) => {
        if (!(id in timers.value)) return;
        clearInterval(timers.value[id].interval);
        delete timers.value[id];
    };

    const resetTimer = (id) => {
        const settings = timers.value[id];
        if (!settings) return;
        clearTimer(id);
        setTimer(
            id,
            settings.seconds,
            settings.ticksPerSecond,
            settings.reversed
        );
    };

    const getPercent = (id) => {
        const timer = timers.value[id];
        const percent = 100 * (timer?.currentTime / timer?.seconds);
        if (timer?.reversed) {
            return 100 - percent < 0 ? 0 : 100 - percent;
        }
        return percent > 100 ? 100 : percent;
    };

    const timeUp = (id) => {
        return timers.value[id]?.timeUp;
    };

    const getSeconds = (id) => {
        return Math.round(timers.value[id]?.currentTime);
    };

    return {
        timers,
        setTimer,
        resetTimer,
        clearTimer,
        getPercent,
        timeUp,
        getSeconds,
    };
});
