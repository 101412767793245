import { computed, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { PASS_STATUS } from '@/consts';
import { useUserStore } from '@/_store/user.store';

export const useChangePassword = () => {
    const changePassword = () => {
        const userStore = useUserStore();
        const { user } = storeToRefs(userStore);

        const route = useRoute();
        const router = useRouter();

        const estadoPassword = computed(() => user.value.estadoPassword);
        watch(
            () => estadoPassword.value,
            (newVal) => {
                if (
                    newVal === PASS_STATUS.CHANGE &&
                    route.name != 'change-password'
                ) {
                    router.push({ name: 'change-password' });
                }
            }
        );
    };

    return {
        changePassword,
    };
};
