import { digitalTheme } from '@/themes/digital.theme.js';
import { ref, computed } from 'vue';

// Decides which mode to use between light and dark
const darkMode = ref(false);

export const useTheme = (selectedTheme = 'digital') => {
    // Maps imported themes
    const themes = {
        digital: digitalTheme,
        // 'dark-digital': darkDigitalTheme
    };

    const theme = computed(
        () =>
            themes[`${darkMode.value ? 'dark-' : ''}${selectedTheme}`]
                .properties
    );

    return { theme };
};
