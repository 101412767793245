import { ref } from 'vue';
import api from '@/_services/api.js';

export const isInstalled = ref(false);
export const loadScript = () =>
    new Promise((res, rej) => {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://push.finmarkets.cl/files/push-api/1.0.0/client.js`;
        const firstScript = document.getElementsByTagName('script')[0];
        if (firstScript.parentNode) {
            firstScript.parentNode.insertBefore(script, firstScript);
        } else {
            rej(new Error('No script tag found'));
        }
        script.addEventListener('load', () => res());
        isInstalled.value = true;
    });

const clientAuth = ({ tokenPush }) => {
    return {
        incoming: (message, callback) => {
            if (!message.error) {
                callback(message);
            }
        },
        outgoing: (message, callback) => {
            message.ext = message.ext || {};
            message.ext.token = tokenPush;
            message.ext.host = 'mercadosenlinea.cl';
            callback(message);
        },
    };
};

export const finmarkets = ref(null);
export const install = async () => {
    if (finmarkets.value) {
        return;
    }

    if (!isInstalled.value) {
        await loadScript();
    }

    const tokenPush = ref(null);
    try {
        const r = await api.get('login/push');
        tokenPush.value = r.data['token_push'];
    } catch (error) {
        console.log(error);
    }

    return new Promise((res) => {
        if (finmarkets.value) {
            res();
            return;
        }

        if (typeof window.Faye === 'object') {
            const Faye = window.Faye;

            // TODO: I need to know if I can call the push
            finmarkets.value = new Faye.Client(
                'https://push2.finmarkets.cl/push'
            );
            finmarkets.value.addExtension(
                clientAuth({
                    client: finmarkets.value,
                    tokenPush: tokenPush.value,
                })
            );
            res();
        }
    });
};
