<template>
    <OrqDrawerBody>
        <template #header>
            <TransactionalHeader
                :inverse="inverse"
                :title="title"
                :subtitle="subtitle"
            />
        </template>

        <template #body>
            <OrqColumn :gap="48">
                <BodyAmount
                    :amount="balance[currency]"
                    :currency="currency"
                    :inverse="inverse"
                />
                <BodyInvestAmount
                    v-model.number="inputAmount"
                    :available-balance="balance[currency]"
                    :currency="currency"
                    :error-msg="errorMsg"
                    :inverse="inverse"
                    @update:model-value="debouncedTouch()"
                    @blur="v$.$touch()"
                />
                <BodyInfo :data="investInfo" :inverse="inverse" />
            </OrqColumn>
        </template>

        <template #footer>
            <TransactionalFooter
                :steps="totalSteps"
                :next-enabled="isValid"
                :inverse="inverse"
                :disabled="inputAmount <= 0"
                @next="nextStep()"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import { computed, ref } from 'vue';
import TransactionalHeader from '../TransactionalHeader.vue';
import TransactionalFooter from '../TransactionalFooter.vue';
import BodyInvestAmount from '../body-components/BodyInvestAmount.vue';
import BodyInfo from '../body-components/BodyInfo.vue';
import BodyAmount from '../body-components/BodyAmount.vue';
import { OrqDrawerBody, OrqColumn } from '@digital/orquestra';
import { TYPES_DATA, OPERATION } from '@/consts';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useVuelidate } from '@vuelidate/core';
import { debounce } from 'lodash';
import { removeFormat } from '@/directives/currencyFormat';
import {
    overZero,
    nonZero,
    minValue,
    maxValue,
} from '@/_helpers/rules.helper.js';

import { useOrderStore } from '@/_store/order.store.js';
const orderStore = useOrderStore();
const { order, totalSteps } = storeToRefs(orderStore);
const { initOrder, nextStep } = orderStore;

import { useAccountsStore } from '@/_store/accounts.store.js';
const accountsStore = useAccountsStore();
const { accounts, currentAccount } = storeToRefs(accountsStore);

import { useBalanceStore } from '@/_store/balance.store.js';
const balanceStore = useBalanceStore();
const { balance } = storeToRefs(balanceStore);
const { t } = useI18n();

const inputAmount = ref(null);

const portfolioFFMM = computed(() => {
    return currentAccount.value.prtfolioFFM
        ? currentAccount.value.prtfolioFFM
        : accounts.value[0]?.prtfolioFFM;
});

const currency = computed(() => {
    if (props.item?.config?.multiproduct || props.item?.config?.multiCurrency) {
        return 'CLP';
    }

    return !props.item?.config?.multiCurrency &&
        props?.item?.config?.currency === 'USD'
        ? 'USD'
        : 'CLP';
});

const title = computed(() => {
    return props.item?.info?.name;
});

const subtitle = computed(() => {
    return TYPES_DATA[props.item?.info?.type?.id]?.nameType ?? '';
});

const debouncedTouch = debounce(() => {
    v$.value.$touch();
    order.value.amount = removeFormat(inputAmount.value);
}, 500);

const isValid = computed(() => {
    if (balance.value[currency.value] <= 0) {
        return false;
    }

    if (props.item?.config?.invMinAmount > order.value.amount) {
        return false;
    }
    return order.value.amount <= balance.value[currency.value];
});

const props = defineProps({
    item: {
        type: Object,
        default: () => {},
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

import { parseRemuneration } from '@/_helpers/instrument.helper';
const maxRemuneration = computed(() => {
    const remunerationStr = props.item?.config?.remuneration ?? '';
    return parseRemuneration(remunerationStr);
});

const investInfo = computed(() => {
    const data = [
        {
            title: t('transactions.minInvestment'),
            value: {
                amount: props.item?.config?.invMinAmount,
                format: currency.value,
            },
        },
        {
            title: t('transactions.maxRemuneration'),
            value: {
                amount: maxRemuneration.value,
                format: 'PERCENT',
            },
        },
    ];
    if (props.item?.config?.retiroAnticipado) {
        data.push({
            title: t('transactions.earlyRescueCommission'),
            value: {
                label: props.item?.config?.comisionRescate || '-',
            },
        });
    }
    return data;
});

const rules = computed(() => {
    return {
        amount: {
            balanceOverZero: overZero(balance.value[currency.value]),
            lessThanBalance: maxValue(balance.value[currency.value]),
            moreThanMin: minValue(props.item?.config?.invMinAmount),
            nonZero: nonZero(),
        },
    };
});

const v$ = useVuelidate(rules, order);

const errorMsg = computed(() => v$.value.$errors[0]?.$message ?? null);

initOrder({
    idEncoded: props.item?.idEncoded,
    prtfolioFFM: portfolioFFMM.value,
    currency: currency.value,
    operation: OPERATION.BUY,
});
</script>
