import { install, finmarkets } from './setup';
import { useFinMarketsStore } from '@/_store/finmarkets.store';
import { storeToRefs } from 'pinia';

/**
 * How to use:
 *
 * Subscription
 *
 * subscribe({
 *      id: 'toplocal-10-2',
 *      callback: (message) => {
 *          console.log('message', message);
 *      },
 *  });
 *
 * Unsubscription
 *
 * unsubscribe({ id: 'toplocal-10-2' });
 */

export const useFinmarkets = () => {
    const finMarketsStore = useFinMarketsStore();
    const { subscriptions, uniqueID: currentID } = storeToRefs(finMarketsStore);
    
    const subscribe = ({ id, callback }) => {
        if (!finmarkets.value || !id) {
            return;
        }
        currentID.value += 1;
        if (Object.keys(subscriptions.value).includes(id) && subscriptions.value[id].length > 0) {
            subscriptions.value[id] = [...subscriptions.value[id], { callback, id: `${currentID.value}` }];
        } else {
            subscriptions.value[id] = [{ callback, id: `${currentID.value}` }];
            finmarkets.value?.subscribe(`/${id}`, (message) => {
                subscriptions.value[id]?.forEach((sub) => {
                    sub.callback(message);
                })
            });
        }
        return `${currentID.value}`;
    };

    const unsubscribe = ({ id, subID }) => {
        if (finmarkets.value === undefined) {
            return;
        }
        if (subID) {
            subscriptions.value[id] = subscriptions.value[id]?.filter((sub) => {
                return sub.id !== subID;
            });
        }
        else {
            delete subscriptions.value[id];
        }
    };

    return { subscribe, unsubscribe, install };
};
