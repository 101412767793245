import { onMounted, ref } from 'vue';
import { useAuthStore } from '@/_store/auth.store';
import router from '@/router';

export const useInactivity = () => {
    const debug = !!localStorage.getItem('DEBUG');

    const time = ref(0);
    const totalTimeInactivity = debug ? 20 : 120; // Seconds
    const totalTimeRenewToken = debug ? 30 : 60 * 15;
    const isActive = ref(true);

    const authStore = useAuthStore();

    const startTimer = () => {
        time.value = 0;
        setInterval(() => {
            time.value = time.value + 1;
            if (time.value >= totalTimeInactivity) {
                isActive.value = false;
            }
        }, 1000);
    };

    const resetTimer = () => {
        isActive.value = true;
        time.value = 0;
    };

    onMounted(() => {
        startTimer();
        document.addEventListener('mousemove', resetTimer);
        document.addEventListener('keydown', resetTimer);

        time.value = setInterval(() => {
            if (authStore.isLoggedIn) {
                if (isActive.value) {
                    authStore.renew();
                } else {
                    router.push({
                        name: 'logout',
                        query: {
                            redirectTo: router.currentRoute.value?.path,
                        },
                    });
                }
            }
        }, totalTimeRenewToken * 1000);
    });

    return { isActive, time };
};
