import { toSVG } from '@/utils/text.js';

export const normalizeSymbol = ({ symbol }) => {
    if (!symbol) return null;
    const _symbol = toSVG(symbol);
    return `https://static.btgpactual.cl/apps/icons/${_symbol}.svg`;
};

export const normalizeFlag = ({ flag }) => {
    if (!flag) return null;
    const _flag = flag.toUpperCase();
    return `https://static.btgpactual.cl/apps/flags/${_flag}.svg`;
};
