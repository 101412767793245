<template>
    <OrqDrawerBody>
        <template #header>
            <OrqColumn :gap="16">
                <div class="heading-md">{{ t('withdraw.title') }}</div>
                <div class="heading-lg">Retiro en USD</div>
            </OrqColumn>
        </template>

        <template #body>
            <OrqColumn>
                <OrqColumn :gap="8">
                    <OrqFlex style="justify-content: space-between">
                        <div class="body-sm text--emphasis-base-medium">
                            Saldo disponible
                        </div>
                        <div class="body-sm text--emphasis-base-medium">
                            <OrqAmount :amount="balance.USD" format="USD" />
                        </div>
                    </OrqFlex>
                    <OrqFlex style="justify-content: space-between">
                        <div class="body-sm text--emphasis-base-medium">
                            Monto mínimo a retirar
                        </div>
                        <div class="body-sm text--emphasis-base-medium">
                            <OrqAmount
                                :amount="minAmountForGiro.USD"
                                format="USD"
                            />
                        </div>
                    </OrqFlex>
                </OrqColumn>

                <div v-currency-format="'USDEXT'">
                    <OrqInput
                        v-model="amount"
                        placeholder="US$0"
                        label="Monto a retirar"
                        :max-length="12"
                        :error="investError"
                        :description="investError"
                        @blur="v$.$touch()"
                    />
                </div>

                <div>
                    <OrqButton
                        :disabled="balance.USD < minAmountForGiro.USD"
                        label="Retirar el 100%"
                        @click="takeAll"
                    />
                </div>
            </OrqColumn>
        </template>

        <template #footer>
            <RetirarFooter
                next-label="Retirar"
                :next-enabled="isValid"
                @next="saveAndContinue"
            />
        </template>
    </OrqDrawerBody>
    <Teleport to="#modals">
        <TokenModal
            :show="showToken"
            :loading="postRetiroLoading"
            @submit="triggerOperation()"
            @cancel="showToken = false"
        />
    </Teleport>
</template>

<script setup>
import {
    OrqDrawerBody,
    OrqColumn,
    OrqInput,
    OrqFlex,
    OrqAmount,
    OrqButton,
} from '@digital/orquestra';
import {
    removeFormat,
    formatCurrency,
    currencyFormat as vCurrencyFormat,
} from '@/directives/currencyFormat';
import TokenModal from '@/components/Token/TokenModal.vue';
import RetirarFooter from './shared/RetirarFooter.vue';
import { computed, ref } from 'vue';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

import { useBalanceStore } from '@/_store/balance.store.js';
import { storeToRefs } from 'pinia';
const balanceStore = useBalanceStore();
const { balance } = storeToRefs(balanceStore);

import { useRetirarStore } from '@/_store/retirar.store.js';
const retirarStore = useRetirarStore();
const { postRetiroLoading, minAmountForGiro } = storeToRefs(retirarStore);

import { nonZero, maxValue, minValue } from '@/_helpers/rules.helper.js';
import { useVuelidate } from '@vuelidate/core';

const amount = ref(null);

const rules = computed(() => {
    return {
        lessThanBalance: maxValue(balance.value.USD, `Saldo insuficiente`),
        lessThanMinium: minValue(
            minAmountForGiro.value.USD,
            t('transactions.lessThanMinWithdraw')
        ),
        nonZero: nonZero(t('transactions.lessThanMinWithdraw')),
    };
});

const v$ = useVuelidate(rules, amount);

const investError = computed(() => v$.value.$errors[0]?.$message ?? false);

const takeAll = () => {
    amount.value = formatCurrency(balance.value.USD, 'USD');
};

const isValid = computed(() => {
    if (removeFormat(amount.value) < minAmountForGiro.value.USD) {
        return false;
    }
    if (removeFormat(amount.value) > balance.value.USD) {
        return false;
    }
    return true;
});

const saveAndContinue = () => {
    retirarStore.amount = removeFormat(amount.value);
    showToken.value = true;
};

const showToken = ref(false);
const triggerOperation = () => {
    retirarStore
        .postRetiro()
        .then(() => {
            retirarStore.nextStep();
        })
        .catch(() => {
            retirarStore.nextStep();
        });
};
</script>


