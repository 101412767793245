<template>
    <OrqDrawer
        :is-open="isDrawerOpen"
        :inverse="isInverse"
        @closed="onClosed"
        @close="isDrawerOpen = false"
    >
        <OperationLoading v-if="loading && isDrawerOpen" :inverse="isInverse" />
        <TransactionalFunds
            v-if="!loading && isDrawerOpen"
            :inverse="isInverse"
            v-bind="{
                item,
                balance: balance[item.config?.currency],
                availableAmount: availableAmount,
                availableQuantity: availableQuantity,
                component: whatComponent?.component(operation),
                confirmComponent: whatComponent?.confirmComponent,
                completedComponent: whatComponent?.completedComponent,
                noBalanceComponent: whatComponent?.noBalance,
                closedComponent: whatComponent?.closedComponent,
                hasInstrumentBalance,
                operation,
                term,
                currency,
                blockedBackBtns,
                intercom,
            }"
        />
        <pre v-if="debug" style="margin-bottom: 0">{{
            whatComponent?.desc
        }}</pre>
    </OrqDrawer>
</template>

<script setup>
import { OrqDrawer } from '@digital/orquestra';
import { computed, onUnmounted, ref } from 'vue';
import { useRootEvents } from '@/composables/useRootEvents';
import { EVENTS, OPERATION, TYPES } from '@/consts';

import { useInstrument } from '@/composables/useInstrument.js';
import TransactionalFunds from './TransactionalFunds.vue';
import OperationLoading from '@/components/transactional-funds/Shared/OperationLoading.vue';

const debug = !!localStorage.getItem('DEBUG');

import { storeToRefs } from 'pinia';

import { useTimersStore } from '@/_store/timers.store.js';
const timersStore = useTimersStore();

import { useInstrumentStore } from '@/_store/instrument.store.js';
const instrumentStore = useInstrumentStore();
const { balance: insBalance } = storeToRefs(instrumentStore);

import { useBalanceStore } from '@/_store/balance.store.js';
const balanceStore = useBalanceStore();
const { balance } = storeToRefs(balanceStore);

import { useAccountsStore } from '@/_store/accounts.store.js';
const accountsStore = useAccountsStore();
const { currentAccount } = storeToRefs(accountsStore);

import { useOrderStore } from '@/_store/order.store.js';
const orderStore = useOrderStore();

const isAvailable = computed(() => {
    if (!currentAccount.value.invertirEnabled) return false;

    let insType = item.value.info ? item.value.info?.type?.id : null;
    if (!insType) return true;

    // For hashdex-like instruments, we have to use FM permissions.
    if (item.value.config.multiproduct) insType = TYPES.FONDO_MUTUO;

    const typeDict = {
        [TYPES.FONDO_MUTUO]:
            operation.value === OPERATION.BUY
                ? 'aporteEnabled'
                : 'rescateEnabled',
        [TYPES.FONDO_DE_INVERSION]: 'finvEnabled',
        [TYPES.FONDO_INTERNACIONAL]: 'allfundsEnabled',
        [TYPES.ACCION]: 'accEnabled',
        [TYPES.DEPOSITO_A_PLAZO]: 'dapEnabled',
        [TYPES.MONEDA]: 'spotEnabled',
    };
    if (!(insType in typeDict)) return true;

    return currentAccount.value[typeDict[insType]];
});

const instrument = useInstrument();

const availableQuantity = computed(() => {
    return TYPES.MONEDA === item.value.info?.type?.id
        ? balance.value.USD
        : insBalance.value.quantity;
});

const availableAmount = computed(() => {
    return insBalance.value.amount;
});

const hasInstrumentBalance = computed(() => {
    
    // If we are buying, we don't need to check for balance.
    if(operation.value === OPERATION.BUY){
        return true;
    }

    return (
        operation.value === OPERATION.SELL &&
        (!!availableAmount.value || !!availableQuantity.value)
    );
});
const whatComponent = computed(() => {
    if (!isDrawerOpen.value) {
        return {};
    }
    return instrument.whatComponent({
        isAvailable: isAvailable.value,
        item: item.value,
        hasInstrumentBalance: !isAvailable.value ? true : hasInstrumentBalance.value,
    });
});

const idEncoded = ref(null);
const isDrawerOpen = ref(false);
const isInverse = ref(false);

const term = ref(7);
const currency = ref('CLP');
const blockedBackBtns = ref([]);

const onCancel = () => {
    isDrawerOpen.value = false;
};

const onClosed = async () => {
    const lastStep = orderStore.step;

    // Drawer is closed so it's safe to do anything extra here.
    idEncoded.value = null;
    await orderStore.$reset();

    if (lastStep === 'completed') {
        rootEmit(EVENTS.TRANSACCIONAL_FINISHED);
    }
};

const { addListener, removeListeners, emit: rootEmit } = useRootEvents();

const loading = ref(true);
const operation = ref(OPERATION.BUY);
const intercom = ref({});
addListener(EVENTS.TRANSACCIONAL_OPEN, (evt) => {
    intercom.value = evt.intercom ?? {};
    isDrawerOpen.value = true;
    idEncoded.value = evt.idEncoded;
    operation.value = evt.operation;

    isInverse.value = evt.inverse ?? false;

    if (!isAvailable.value) {
        loading.value = false;
        return;
    }

    term.value = evt.term ?? 7;
    currency.value = evt.currency ?? 'CLP';
    blockedBackBtns.value = evt.blockedBackBtns ?? [];

    // instrumentStore
    //     .fetchItem({ idEncoded: evt.idEncoded })
    //     .then(() => console.log('item ready'))
    //     .catch((e) => console.log('da hek!', e));
    timersStore.resetTimer('usdRateTimer');

    loading.value = true;
    Promise.all([
        instrumentStore.fetchItem({
            idEncoded: evt.idEncoded,
            quiet: true,
            operation: evt.operation,
            slim: false,
            currency: currency.value,
        }),
        balanceStore.getBalance(),
    ])
        .then(() => {
            console.log('Now we are safe to go!');
        })
        .catch((e) => console.log(e))
        .finally(() => {
            loading.value = false;
        });
});

addListener(EVENTS.TRANSACCIONAL_CLOSE, () => {
    onCancel();
});

const item = computed(() => instrumentStore.currentItem(idEncoded.value));

onUnmounted(() => {
    removeListeners();
});
</script>
