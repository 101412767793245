<template>
    <div class="body__available-amount">
        <span
            class="body-sm"
            :class="{
                'text--emphasis-base-medium': !inverse,
                'text--emphasis-base-medium-inverse': inverse,
            }"
        >
            {{
                !!title
                    ? title
                    : t('transactions.availableBalance', { currency })
            }}
        </span>
        <span
            class="body-baseline"
            :class="{
                'text--emphasis-base-high': !inverse,
                'text--emphasis-base-high-inverse': inverse,
            }"
        >
            <OrqAmount :amount="amount" :format="currency" />
        </span>
        <OrqLink
            v-if="!hideLink"
            link="#"
            :label="t('transactions.contributeBalance')"
            icon="default"
            :inverse="inverse"
            @click="openAbonar"
        />
    </div>
</template>

<script setup>
import { OrqLink, OrqAmount } from '@digital/orquestra';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

defineProps({
    title: {
        type: String,
        default: null,
    },
    currency: {
        type: String,
        default: 'CLP',
    },
    amount: {
        type: Number,
        default: 0,
    },
    hideLink: {
        type: Boolean,
        default: false,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

import { EVENTS } from '@/consts';
import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();

const openAbonar = () => {
    rootEvents.emit(EVENTS.TRANSACCIONAL_CLOSE);
    rootEvents.emit(EVENTS.ABONAR_OPEN);
};
</script>

<style lang="scss" scoped>
.body {
    &__available-amount {
        display: flex;
        flex-direction: column;
    }
}
</style>
