import { defineStore, storeToRefs } from 'pinia';
import instrumentService from '@/_services/instrument.service';
import fundsService from '@/_services/funds.service';
import investmentFundsService from '@/_services/investmentFunds.service';
import sharesService from '@/_services/shares.service';
import internationalFundsService from '@/_services/internationalFunds.service';
import investmentsService from '@/_services/investments.service';
import dapService from '@/_services/dap.service';
import currencyService from '@/_services/currency.service';
import { errorHandler } from '@/_services/errorHandler';
import { ref, computed } from 'vue';
import { normalizeInstrument } from '@/_helpers/instrument.helper';
import { useAccountsStore } from '@/_store/accounts.store.js';

import { useStatusStore } from '@/_store/status.store.js';
import { useInternationalFundsStore } from '@/_store/internationalFunds.store.js';
import { useDapStore } from '@/_store/dap.store';
import { useCurrencyStore } from '@/_store/currency.store.js';

import { useAuthStore } from '@/_store/auth.store.js';

import { TYPES, MARKET, ARCHITECTURE } from '@/consts';
import { OPERATION } from '../consts';

/**
 * Important: We are using 'Setup Store' definition.
 * @see https://pinia.vuejs.org/core-concepts/#setup-stores
 *
 * ref()s become state properties
 * computed()s become getters
 * function()s become actions
 *
 */
export const useInstrumentStore = defineStore('instrument', () => {
    const loading = ref(true);
    const timestamp = ref(null);

    const itemsById = ref({});
    const currentEncodedId = ref('Q0ZJLkNGSUFEVkFFRkEuQkNT');
    const currentItem = computed(() => {
        return (encodedId) => itemsById.value[encodedId] || {};
    });

    const serviceByType = {
        [TYPES.FONDO_MUTUO]: fundsService,
        [TYPES.FONDO_DE_INVERSION]: investmentFundsService,
        [TYPES.ACCION]: sharesService,
        [TYPES.FONDO_INTERNACIONAL]: internationalFundsService,
        [TYPES.FONDO_MUTUO_INTERNACIONAL]: internationalFundsService,
        [TYPES.DEPOSITO_A_PLAZO]: dapService,
        [TYPES.MONEDA]: currencyService,
    };

    const isMarketOpen = ref({ open: true });
    const fetchIsOpen = async ({ idEncoded, operation, currency }) => {
        const type = (() => {
            // if (currentItem.value(idEncoded).config.multiproduct) {
            //     return TYPES.FONDO_MUTUO;
            // }
            if (
                currentItem.value(idEncoded).info.type.id ===
                    TYPES.FONDO_MUTUO &&
                currentItem.value(idEncoded).config.bolsa &&
                !currentItem.value(idEncoded).config.nav
            ) {
                return TYPES.FONDO_DE_INVERSION;
            }
            return currentItem.value(idEncoded).info.type.id;
        })();

        const market = currentItem.value(idEncoded).config?.nav
            ? MARKET.NAV
            : MARKET.BOLSA;

        if (typeof serviceByType[type]?.isOpen !== 'function') {
            // Prevents this call if we don't have a service for this
            console.warn('No isOpen service for:', type);
            return;
        }

        const res = await serviceByType[type].isOpen({
            idEncoded,
            operation,
            market,
            currency,
        });
        isMarketOpen.value = res.data;
    };
    const isOpen = computed(() => {
        return isMarketOpen.value.open || isMarketOpen.value.overTimeEnabled;
    });

    const isMirrorMarketOpen = ref({ open: true });
    const fetchIsOpenMirror = async ({ idEncoded, operation, currency }) => {
        const current = currentItem.value(idEncoded);
        const type = current.info.type?.id;

        if (current.architecture !== ARCHITECTURE.NAV_Y_BOLSA) {
            return;
        }

        // Market is inverted because is mirror ins we are looking for.
        const market = current.config?.nav ? MARKET.BOLSA : MARKET.NAV;
        const res = await serviceByType[type].isOpen({
            idEncoded: window.btoa(current.config.mirrorIns),
            operation,
            market,
            currency,
        });
        isMirrorMarketOpen.value = res.data;
    };
    const isMirrorOpen = computed(() => {
        return (
            isMirrorMarketOpen.value.open ||
            isMirrorMarketOpen.value.overTimeEnabled
        );
    });

    const composition = ref({});
    const fetchComposition = async ({ idEncoded }) => {
        const res = await instrumentService.fetchComposition({ idEncoded });
        composition.value = res.data ? res.data : [];
    };

    const balance = ref({
        amount: null,
        price: null,
        quantity: null,
    });

    const resetBalance = () => {
        balance.value = {
            amount: null,
            price: null,
            quantity: null,
        };
    };

    const fetchBalance = async ({ idEncoded }) => {
        const type = (() => {
            if (currentItem.value(idEncoded).config.multiproduct) {
                return TYPES.FONDO_MUTUO;
            }
            if (
                currentItem.value(idEncoded).info.type.id ===
                    TYPES.FONDO_MUTUO &&
                currentItem.value(idEncoded).config.bolsa
            ) {
                return TYPES.FONDO_DE_INVERSION;
            }
            return currentItem.value(idEncoded).info.type.id;
        })();

        const idBtg = currentItem.value(idEncoded).info.idBtg;
        const accountsStore = useAccountsStore();
        const { currentAccount } = storeToRefs(accountsStore);

        if (typeof serviceByType[type]?.getBalance !== 'function') {
            // Prevents this call if we don't have a service for this
            console.warn('No getBalance service for:', type);
            return;
        }
        resetBalance();

        try {
            const res = await serviceByType[type].getBalance({
                idBtg,
                idEncoded,
                account: Number(currentAccount.value.idCuentaGrupo),
                currency: currentItem.value(idEncoded).config.multiproduct
                    ? 'CLP'
                    : currentItem.value(idEncoded).config.currency,
            });

            // Normalize balance
            balance.value = (() => {
                const { data } = res;
                var _balance = {};
                if (typeof data === 'number') {
                    _balance.quantity = data;
                }
                if (typeof data === 'object') {
                    _balance = {
                        ...data,
                        quantity: data.positions,
                        price: data.vc, // vc stands for 'valor cuota'
                    };
                }
                return _balance;
            })();
        } catch (error) {
            errorHandler(error);
        }
    };

    const isOutOfProfile = ref(false);
    const outOfProfile = async ({ idEncoded }) => {
        const type = (() => {
            // if (currentItem.value(idEncoded).config.multiproduct) {
            //     return TYPES.FONDO_MUTUO;
            // }
            if (
                currentItem.value(idEncoded).info.type.id ===
                    TYPES.FONDO_MUTUO &&
                currentItem.value(idEncoded).config.bolsa
            ) {
                return TYPES.FONDO_DE_INVERSION;
            }
            return currentItem.value(idEncoded).info.type.id;
        })();

        const idBtg = currentItem.value(idEncoded).info.idBtg;

        if (typeof serviceByType[type]?.outOfProfile !== 'function') {
            // Prevents this call if we don't have a service for this
            console.warn('No OutOfProfile service for:', type);
            return;
        }

        try {
            const res = await serviceByType[type].outOfProfile({
                idBtg,
                idEncoded,
            });
            isOutOfProfile.value = res.data;
        } catch (error) {
            errorHandler(error);
        }
    };

    const currencyStore = useCurrencyStore();
    const internationalFundsStore = useInternationalFundsStore();

    const USDRate = ref({});
    const fetchUSDRate = async (idEncoded, operation) => {
        const type = currentItem.value(idEncoded).info.type.id;

        let value;
        if (
            [
                TYPES.FONDO_INTERNACIONAL,
                TYPES.FONDO_MUTUO_INTERNACIONAL,
            ].includes(type)
        ) {
            value = await internationalFundsStore.fetchUSDRate();
        } else if (type === TYPES.MONEDA) {
            value = await currencyStore.fetchUSDRate(operation);
        }
        USDRate.value = value;
    };

    const { fetchServerStatus } = useStatusStore();

    const paymentMethods = ref({});
    const fetchPaymentMethod = async () => {
        try {
            paymentMethods.value.data =
                await investmentsService.getPaymentMethod();
        } catch (error) {
            errorHandler(error);
        }
    };

    const isAnexoPending = ref(false);
    const fetchAnexo = async ({ idEncoded }) => {
        const type = (() => {
            // if (currentItem.value(idEncoded).config.multiproduct) {
            //     return TYPES.FONDO_MUTUO;
            // }
            if (
                currentItem.value(idEncoded).info.type.id ===
                    TYPES.FONDO_MUTUO &&
                currentItem.value(idEncoded).config.bolsa
            ) {
                return TYPES.FONDO_DE_INVERSION;
            }
            return currentItem.value(idEncoded).info.type.id;
        })();
        const risk = currentItem.value(idEncoded).config.risk;

        if (typeof serviceByType[type]?.getAnexo !== 'function') {
            // sets pending anexo to false if function not exists
            isAnexoPending.value = false;
            console.warn('No getAnexo service for:', type);
            return;
        }
        const isPending = await serviceByType[type]
            ?.getAnexo({ risk })
            .then((r) => {
                if (r.data !== '') {
                    isAnexoPending.value = r.data;
                }
            })
            .catch(() => {});

        return isPending;
    };

    const { getDays } = useDapStore();
    const fetchDays = async ({ idEncoded }) => {
        const type = currentItem.value(idEncoded).info.type.id;
        if (type !== TYPES.DEPOSITO_A_PLAZO) {
            return;
        }
        const res = await getDays();
        return res;
    };

    /**
     * Fetch the instrument data by id and calls all the dependencies
     * @param {string} idEncoded - Base64 item.info.idCode
     * @param {boolean=} quiet - if true won't change the loading state
     * @returns {void}
     */
    const fetchItem = async ({
        idEncoded,
        quiet = false,
        slim = true,
        operation = 1,
        currency = 'CLP',
    }) => {
        try {
            if (!quiet) {
                loading.value = true;
            }

            await fetchServerStatus();
            const res = await instrumentService.getItem({ idEncoded });

            if (res.data) {
                itemsById.value[idEncoded] = normalizeInstrument(res.data);
            }

            // Here we should call all services related to instrument.
            // for instance: isOpen (Sell and Buy), composition, charts, etc.
            if (!slim) {
                const { isLoggedIn } = storeToRefs(useAuthStore());

                const guestApiCalls = [fetchDays({ idEncoded })];

                const loggedApiCalls = isLoggedIn.value
                    ? [
                          fetchIsOpen({ idEncoded, operation, currency }),
                          fetchIsOpenMirror({ idEncoded, operation, currency }),
                          outOfProfile({ idEncoded }),
                          fetchBalance({ idEncoded }),
                          fetchUSDRate(idEncoded, operation),
                          fetchPaymentMethod(),
                          fetchDays({ idEncoded }),
                      ]
                    : [];

                const buyApiCalls =
                    operation === OPERATION.BUY
                        ? [fetchAnexo({ idEncoded })]
                        : [];

                await Promise.all([
                    ...guestApiCalls,
                    ...loggedApiCalls,
                    ...buyApiCalls,
                ]);
            }

            loading.value = false;
        } catch (error) {
            errorHandler(error);
            loading.value = false;
        }
    };

    const fetchChart = async (idEncoded, interval, historical) => {
        try {
            const res = await instrumentService.getChart({
                idEncoded: idEncoded,
                ...interval,
                ...historical,
            });

            return res.data.chart;
        } catch (error) {
            errorHandler(error);
        }
    };

    const classes = ref([]);
    const getClasses = async () => {
        try {
            if (sectors.value.length === 0) {
                const res = await instrumentService.getClasses();
                classes.value = res.data;
            }
            return sectors.value;
        } catch (error) {
            errorHandler(error);
        }
    };

    const markets = ref([]);
    const getMarkets = async () => {
        try {
            if (sectors.value.length === 0) {
                const res = await instrumentService.getMarkets();
                markets.value = res.data;
            }
            return sectors.value;
        } catch (error) {
            errorHandler(error);
        }
    };

    const sectors = ref([]);
    const getSectors = async () => {
        try {
            if (sectors.value.length === 0) {
                const res = await instrumentService.getSectors();
                sectors.value = res.data.map((item) =>
                    normalizeInstrument(item)
                );
            }
        } catch (error) {
            errorHandler(error);
        }
    };

    const setAnexoSign = async ({ idEncoded }) => {
        const type = currentItem.value(idEncoded).info.type.id;

        if (typeof serviceByType[type]?.signAnexo !== 'function') {
            console.warn('No signAnexo service for:', type);
            return;
        }
        const res = await serviceByType[type]
            ?.signAnexo()
            .then(() => {})
            .catch(() => {});

        return res;
    };

    const filters = ref({
        orderBy: [
            { value: 'TP', label: 'Top Funds' },
            { value: '', label: 'Nombre' },
            { value: 'LQ', label: 'Liquidez' },
            { value: 'MN', label: 'Monto Mínimo' },
            { value: 'R30D', label: 'Rentabilidad 30d' },
            { value: 'R12M', label: 'Rentabilidad 12m' },
        ],
        risk: [
            { value: 'uc', label: 'Ultra Conservador' },
            { value: 'c', label: 'Conservador' },
            { value: 'b', label: 'Balanceado' },
            { value: 'a', label: 'Agresivo' },
            { value: 'ua', label: 'Ultra Agresivo' },
        ],
        currency: [
            { value: 'CLP', label: 'Pesos Chilenos' },
            { value: 'USD', label: 'Dólares' },
        ],
    });
    const getFilters = async (types) => {
        try {
            const typeRes = await instrumentService.getTypes();
            typeRes.data.forEach((item) => {
                item.label = item.nameType;
                item.value = item.id;
            });
            filters.value.type = typeRes.data.filter((item) =>
                [TYPES.FONDO_MUTUO, TYPES.FONDO_DE_INVERSION].includes(
                    item.value
                )
            );

            const managerRes = await instrumentService.getManagers(types);
            filters.value.manager = managerRes.data.map((item) => {
                return {
                    label: item,
                    value: item,
                };
            });

            const strategiesRes = await instrumentService.getStrategies(types);
            filters.value.strategy = strategiesRes.data.map((item) => {
                return {
                    label: item,
                    value: item,
                };
            });
        } catch (error) {
            errorHandler(error);
        }
    };

    const getCustodians = async ({
        cuenta,
        claseActivo,
        origen,
        idInstrumento,
        portfolio,
    }) => {
        try {
            const response = await instrumentService.getCustodians({
                cuenta,
                claseActivo,
                origen,
                idInstrumento,
                portfolio,
            });

            if (response.status != 200) {
                return { glosaCustodia: '-' };
            }

            return response.data[0];
        } catch (error) {
            return { glosaCustodia: '-' };
        }
    };

    return {
        getCustodians,
        loading,
        timestamp,
        currentEncodedId,
        currentItem,
        isAnexoPending,
        isMarketOpen,
        isOpen,
        USDRate,
        isMirrorMarketOpen,
        isMirrorOpen,
        fetchChart,
        itemsById,
        fetchItem,
        composition,
        fetchComposition,
        paymentMethods,
        balance,
        fetchBalance,
        fetchIsOpen,
        isOutOfProfile,
        serviceByType,
        markets,
        getMarkets,
        classes,
        getClasses,
        sectors,
        getSectors,
        setAnexoSign,
        filters,
        getFilters,
    };
});
