<template>
    <Teleport to="#modals">
        <TimerModal
            :show="showModal"
            :inverse="inverse"
            @submit="resetTimer"
            @cancel="onCancel"
        />
    </Teleport>

    <div
        v-if="currency !== 'USD'"
        style="display: flex; flex-direction: column"
    >
        <TimerDetailed v-bind="timerArgs" @time-up="onTimeout" />
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import TimerDetailed from '@/components/Timer/TimerDetailed.vue';
import TimerModal from '@/components/Timer/TimerModal.vue';
import { storeToRefs } from 'pinia';

import { useTimersStore } from '@/_store/timers.store.js';
const timersStore = useTimersStore();

import { useOrderStore } from '@/_store/order.store.js';
const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);

import { useInstrumentStore } from '@/_store/instrument.store.js';
const instrumentStore = useInstrumentStore();
const { USDRate } = storeToRefs(instrumentStore);

import { useRootEvents } from '@/composables/useRootEvents';
import { EVENTS, OPERATION } from '@/consts';
const rootEvents = useRootEvents();

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const timerArgs = computed(() => {
    return {
        timerId: 'usdRateTimer',
        title:
            order.value.operation === OPERATION.BUY
                ? t('timer.purchasePrice', { currency: 'USD' })
                : t('timer.salePrice', { currency: 'USD' }),
        amount: USDRate.value.value,
        format: 'CLPEXT',
        maxSeconds: 90,
        ticksPerSecond: 60,
        reversed: false,
        inverse: props.inverse,
    };
});

const showModal = ref(false);
const onTimeout = () => {
    showModal.value = true;
    emit('timeUp');
};

function onCancel() {
    showModal.value = false;
    rootEvents.emit(EVENTS.TRANSACCIONAL_CLOSE);
}

const resetTimer = () => {
    rootEvents.emit(EVENTS.TRANSACCIONAL_OPEN, {
        idEncoded: props.idEncoded,
        operation: order.value.operation,
        inverse: props.inverse,
    });
    timersStore.resetTimer('usdRateTimer');
};

const emit = defineEmits(['timeUp']);

const props = defineProps({
    idEncoded: {
        type: String,
        required: true,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});
</script>
