import api from './api.js';

export default {
    isOpen({ idEncoded, operation }) {
        return api.get(`currency/${idEncoded}/open/${operation}`);
    },
    getBalance({ account }) {
        const path = `user/balance/${account}/USD`;
        return api.get(path, { data: null });
    },
    getCurrencyPrice(currency) {
        const path = `termDeposit/currency`;
        return api.get(path, {
            params: {
                currency: currency,
            },
        });
    },
    getUSDRate(operation) {
        return api.get(`currency/rate/${operation}`, { data: null });
    },
    buy({ amount, account, idBtg, prtfolioFFM, rate, token, timer }) {
        return api.post('currency/purchases', {
            countID: account,
            idFondo: idBtg,
            monto: amount,
            prtfolioFFM,
            rate: rate.id,
            secondGenerateToken: timer,
            tokenTrx: token,
        });
    },
    sell({ amount, account, idBtg, prtfolioFFM, rate, token, timer }) {
        return api.post('currency/sales', {
            countID: account,
            idFondo: idBtg,
            amount,
            prtfolioFFM,
            rate: rate.id,
            secondGenerateToken: timer,
            tokenTrx: token,
        });
    },
};
