<template>
    <template v-if="operationError">
        <OperationError @close="onClose" />
    </template>
    <template v-else-if="step === 'init'">
        <InitOperation
            v-bind="{
                item: currentItem,
            }"
        />
    </template>
    <template v-else-if="step === 'addMore'">
        <AddMoreFunds
            v-bind="{
                items: items,
                title: 'Abono APV Fondos Mutuos',
                subtitle:
                    'Agrega más fondos a tu lista o continúa para completar tu inversión.',
            }"
        />
    </template>
    <template v-else-if="step === 'selectRegime'">
        <SelectRegime
            v-bind="{
                title: 'Abono APV Fondos Mutuos',
                subtitle:
                    'Elige el régimen APV que prefieras. Si tienes dudas revisa nuestro centro de ayuda.',
            }"
        />
    </template>
    <template v-else-if="step === 'selectDepositMethod'">
        <SelectDepositMethod
            v-bind="{
                title: 'Abono APV Fondos Mutuos',
                subtitle:
                    'Elije cómo quieres realizar el abono a tu cuenta APV.',
            }"
        />
    </template>
    <template v-else-if="step === 'selectAccount'">
        <SelectAccount
            v-bind="{
                title: 'Abono APV Fondos Mutuos',
                subtitle:
                    'Selecciona una de tus cuentas BTG Pactual con saldo en caja suficiente para el abono.',
            }"
        />
    </template>
    <template v-else-if="step === 'outOfProfile'">
        <OutOfProfile @details="$emit('details')" />
    </template>
    <template v-else-if="step === 'confirm'">
        <ConfirmOperation
            v-bind="{
                title: `Vas a invertir`,
            }"
        />
    </template>
    <template v-else-if="step === 'completed'">
        <OperationCompleted
            v-bind="{
                title: `Vas a invertir`,
            }"
        />
    </template>
</template>

<script setup>
import { watch } from 'vue';

import InitOperation from '@/components/transactional-funds/APV/InitOperation.vue';
import AddMoreFunds from '@/components/transactional-funds/APV/AddMoreFunds.vue';
import SelectRegime from '@/components/transactional-funds/APV/SelectRegime.vue';
import SelectDepositMethod from '@/components/transactional-funds/APV/SelectDepositMethod.vue';
import SelectAccount from '@/components/transactional-funds/APV/SelectAccount.vue';
import ConfirmOperation from '@/components/transactional-funds/APV/ConfirmOperation.vue';
import OperationCompleted from '@/components/transactional-funds/APV/OperationCompleted.vue';
import OperationError from '@/components/transactional-funds/Shared/OperationError.vue';
import OutOfProfile from '@/components/transactional-funds/APV/OutOfProfile.vue';

defineProps({
    inverse: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['details', 'step']);

import { storeToRefs } from 'pinia';
import { useApvStore } from '@/_store/apv.store.js';
const apvStore = useApvStore();
const { items, step, currentItem, operationError } = storeToRefs(apvStore);

import { EVENTS } from '@/consts';
import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();

const onClose = () => {
    operationError.value = false;
    rootEvents.emit(EVENTS.APV_CLOSE);
};

watch(
    () => step.value,
    () => {
        emit('step');
    }
);
</script>
