import api from '../api.js';

export const serviceFactory = ({ path }) => {
    return {
        fetchItems() {
            return api.get(path, {
                params: {
                    withoutToken: true,
                },
            });
        },
    };
};
