import api from './api.js';

export default {
    requestToken() {
        return api.put('tokens', { data: null });
    },

    activateSoftToken({ token }) {
        const path = `tokens/config`;
        return api.put(path, {
          token,
          type: 2
        });
    },

    deactivateSoftToken({ token }) {
        const path = `tokens/config`;
        return api.put(path, {
          token,
          type: 1
        });
    },

    getQR() {
        const path = `tokens/qr`;
        return api.get(path, {
          data: null,
          responseType: "blob"
        });
    }
};
