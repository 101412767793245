import { defineStore } from 'pinia';
import authService from '@/_services/auth.service.js';
import { computed, nextTick, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAccountsStore } from '@/_store/accounts.store.js';

import { LOGIN_ERROR_STATUS } from '@/consts';
import { useUserStore } from './user.store';
import { useToast } from '@/composables/useToast';

export const useAuthStore = defineStore('auth', () => {
    const router = useRouter();
    const toast = useToast();
    const loading = ref(true);
    const token = ref(localStorage.getItem('token'));

    const obolEnabled = ref(
        JSON.parse(localStorage.getItem('obolEnabled') || false)
    );

    const timer = ref(0);
    const MAX_TOKEN = 300;

    const timerInterval = ref(null);
    const errorResponse = ref(null);

    const isLoggedIn = computed(() => {
        return token.value !== null;
    });

    const resetError = () => (errorResponse.value = null);

    const login = (payload) => {
        loading.value = true;
        errorResponse.value = null;
        return new Promise((resolve, reject) => {
            authService
                .login(payload)
                .then((r) => {
                    if (r.data.token) {
                        token.value = r.data.token;
                        obolEnabled.value = r.data.obolEnabled;
                        localStorage.setItem('token', r.data.token);
                        localStorage.setItem('obolEnabled', r.data.obolEnabled);
                        resolve();
                    }
                })
                .catch((e) => {
                    if (e.response) {
                        if (
                            Object.keys(LOGIN_ERROR_STATUS).includes(
                                e.response.data
                            )
                        ) {
                            errorResponse.value = e.response.data;
                        } else {
                            errorResponse.value =
                                LOGIN_ERROR_STATUS.GENERIC_ERROR;
                        }
                    }
                    reject(e);
                })
                .finally(() => {
                    loading.value = false;
                });
        });
    };

    const loginWithToken = (payload) => {
        return new Promise((resolve, reject) => {
            authService
                .login(payload)
                .then((r) => {
                    if (r.data.token) {
                        token.value = r.data.token;
                        localStorage.setItem('token', r.data.token);
                        resolve(r.data.token);
                    }
                })
                .catch((e) => {
                    console.log('e', e);
                    reject(e);
                });
        });
    };

    const loginAgent = (payload) => {
        return new Promise((resolve, reject) => {
            authService
                .loginAgent(payload)
                .then((r) => {
                    if (r.data.token) {
                        token.value = r.data.token;
                        localStorage.setItem('token', r.data.token);
                        resolve();
                    }
                })
                .catch((e) => {
                    console.log('e', e);
                    reject(e);
                });
        });
    };

    const logout = async ({
        msg = '',
        refresh = false,
        redirectTo = '/login',
    } = {}) => {
        try {
            await authService.logout();
        } catch (e) {
            console.log(e);
        } finally {
            const accountStore = useAccountsStore();
            accountStore.$reset();
            localStorage.removeItem('token');
            localStorage.removeItem('obolEnabled');
            token.value = null;
            obolEnabled.value = false;
            toast.close();
            const userStore = useUserStore();
            userStore.$reset();
            await nextTick();

            if (refresh) {
                router.go();
            } else if (msg) {
                window.location.href = '/login?error=quiz';
            } else {
                window.location.href = redirectTo;
            }
        }
    };

    const runTimer = () => {
        clearInterval(timerInterval.value);
        timer.value = 0;
        timerInterval.value = setInterval(() => {
            timer.value = timer.value + 1;
            if (timer.value > MAX_TOKEN) {
                clearInterval(timerInterval.value);
            }
        }, 1000);
    };

    const forgot = async (payload) => {
        loading.value = true;
        await authService.forgot(payload);
        runTimer();
        loading.value = false;
    };

    const generatePass = async (payload) => {
        loading.value = true;
        await authService.generatePass({ ...payload, seconds: timer.value });
        loading.value = false;
    };

    const changePass = async (payload) => {
        loading.value = true;
        await authService.changePass(payload);
        loading.value = false;
    };

    const renew = async () => {
        try {
            const newToken = await authService.renew();
            token.value = newToken.data;
            localStorage.setItem('token', newToken.data);
        } catch (e) {
            console.log('Error renew token', e);
        }
    };

    return {
        login,
        loginWithToken,
        loginAgent,
        loading,
        logout,
        isLoggedIn,
        token,
        obolEnabled,
        forgot,
        generatePass,
        changePass,
        timer,
        errorResponse,
        renew,
        resetError,
    };
});
