import { defineStore } from 'pinia';
import portfolioService from '../_services/portfolio.service';
import { computed, ref } from 'vue';
import { useAccountsStore } from './accounts.store';
import { TYPES, TYPES_DATA, PORTFOLIO_MODES, PORTFOLIO_DATA } from '@/consts';

import { useCurrencyStore } from './currency.store.js';

import { normalizePortfolio } from '@/_helpers/portfolio.helper';

/**
 * Important: We are using 'Setup Store' definition.
 * @see https://pinia.vuejs.org/core-concepts/#setup-stores
 *
 * ref()s become state properties
 * computed()s become getters
 * function()s become actions
 *
 */
export const usePortfolioStore = defineStore('portfolio', () => {
    const loading = ref(false);
    const simulation = ref({});
    const investments = ref({});
    const lastUpdate = ref(null);
    const timestamp = ref(null);

    const accountsStore = useAccountsStore();

    const getPortfolio = async ({ byType = PORTFOLIO_MODES.TYPE } = {}) => {
        loading.value = true;

        const currencyStore = useCurrencyStore();

        /**
         * Portfolio needs data from Accounts store.
         */
        return new Promise((resolve, reject) => {
            // Preventing unhandled error
            if (!accountsStore.selected) {
                loading.value = false;
                reject();
            }

            portfolioService
                .getPortfolioByAccount({
                    account: accountsStore.selected,
                    mode: byType,
                })
                .then((r) => {
                    lastUpdate.value = new Date().toLocaleString('es-CL', {
                        dateStyle: 'long',
                        timeStyle: 'short',
                    });
                    simulation.value = r.data.simulacion;
                    investments.value = r.data.inversiones;
                    investments.value.types = normalizePortfolio({
                        types: investments.value.types,
                        totalInvest: investments.value.totalInvest,
                        UFPrice: currencyStore.UFPrice,
                    });
                })
                .catch((e) => {
                    reject(e);
                })
                .finally(() => {
                    timestamp.value = new Date().getTime();
                    loading.value = false;
                    resolve();
                });
        });
    };

    const sortSeriesBy = ref(PORTFOLIO_MODES.TYPE);

    const resetSortSeriesBy = () => {
        sortSeriesBy.value = PORTFOLIO_MODES.TYPE;
    };

    const series = computed(() => {
        if (
            !investments.value?.types ||
            investments.value?.types?.length == 0
        ) {
            return [];
        }

        if (sortSeriesBy.value === PORTFOLIO_MODES.TYPE) {
            const data = investments.value?.types
                ?.map((type) => {
                    if (Object.values(TYPES).includes(type.id)) {
                        return {
                            name: TYPES_DATA[type.id]['name'],
                            y: type.percent,
                            color:
                                TYPES_DATA[type.id]['color']?.length > 0
                                    ? TYPES_DATA[type.id]['color']
                                    : '#c0c0c0',
                        };
                    } else {
                        return {
                            name: type.name,
                            y: type.percent,
                            color: '#c0c0c0',
                        };
                    }
                })
                .sort((a, b) => b.y - a.y);

            return {
                name: 'Porcentaje',
                data: data ?? [],
            };
        }

        if (sortSeriesBy.value === PORTFOLIO_MODES.PORTFOLIO) {
            const data = sortByRegime(investments.value?.types);

            return {
                name: 'Porcentaje',
                data: data ?? [],
            };
        }

        return {
            name: 'Porcentaje',
            data: [],
        };
    });

    const sortByRegime = (types) => {
        return types
            ?.map((type) => {
                return {
                    name: type.name,
                    y: type.percent,
                    color:
                        PORTFOLIO_DATA[type.id] && PORTFOLIO_DATA[type.id].color
                            ? PORTFOLIO_DATA[type.id].color
                            : type.id === TYPES.BILLETERA_ML
                            ? TYPES_DATA[type.id]['color']
                            : '#c0c0c0',
                };
            })
            .sort((a, b) => b.y - a.y);
    };

    const orderedInvestments = computed(() => {
        let orderedTypes = investments.value?.types || [];

        // Sort types by amount
        orderedTypes = orderedTypes.sort(
            (a, b) => b.amountActual - a.amountActual
        );

        // Sort items by amount
        orderedTypes.forEach((element) => {
            if (element.items) {
                element.items = element.items.sort(
                    (a, b) => b.totalMoney - a.totalMoney
                );
            }
        });

        orderedTypes = orderedTypes.filter(
            (type) => type.id === TYPES.BILLETERA_ML || type.amountActual !== 0
        );

        // Moneda will be previous to the last one if present
        const monedaIndex = orderedTypes.findIndex(
            (item) => item.id === TYPES.MONEDA
        );

        const moneda =
            monedaIndex > -1 ? orderedTypes.splice(monedaIndex, 1) : []; // We remove the item from the original array

        if (moneda[0]) {
            moneda[0].items = walletCustomOrder(moneda[0].items);
        }

        // Billetera should be last, it's always present.
        const billeteraMLIndex = orderedTypes.findIndex(
            (item) => item.id === TYPES.BILLETERA_ML
        );
        const billeteraML =
            billeteraMLIndex >= 0
                ? orderedTypes.splice(billeteraMLIndex, 1)
                : [];

        if (billeteraML[0]) {
            billeteraML[0].items = walletCustomOrder(billeteraML[0].items);

            // Temporary till Billetera still appears outside regimenes.
            if (accountsStore.isCurrentAccountAPV) {
                billeteraML[0].name = 'Saldo en caja';
            }
        }

        return [...orderedTypes, ...moneda, ...billeteraML].map((i) => {
            return i;
        });
    });

    const walletCustomOrder = (items) => {
        const retenido = items.find((i) => i.productName === 'Saldo Retenido');
        const pendiente = items.find(
            (i) => i.productName === 'Saldo Pendiente'
        );
        const disponible = items.find(
            (i) => i.productName === 'Saldo Disponible'
        );
        return [disponible, retenido, pendiente];
    };

    return {
        getPortfolio,
        investments,
        orderedInvestments,
        series,
        sortSeriesBy,
        resetSortSeriesBy,
        simulation,
        loading,
        lastUpdate,
    };
});
