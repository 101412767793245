import { defineStore } from 'pinia';
import statusService from '@/_services/status.service';
import { errorHandler } from '@/_services/errorHandler';
import { ref, computed } from 'vue';

import { SERVER_STATUS } from '@/consts';

export const useStatusStore = defineStore('status', () => {
    const fallbackMessage = `Plataforma en mantención. El acceso está temporalmente deshabilitado. 
        El monitor público y el detalle de instrumentos sigue funcionando normalmente.`;

    const status = ref({
        status: 0,
        message: '',
    });

    const loading = ref(false);

    const fetchServerStatus = async () => {
        try {
            loading.value = true;
            const res = await statusService.getStatus();
            status.value.status = res.data.status;
            status.value.message = res.data.message || fallbackMessage;
            loading.value = false;
        } catch (error) {
            errorHandler(error);
        }
    };

    const canLogin = computed(() => status.value.status < SERVER_STATUS.DOWN);
    const canTrans = computed(
        () => status.value.status < SERVER_STATUS.NOTRANS
    );

    return {
        status,
        loading,
        fetchServerStatus,
        canLogin,
        canTrans,
    };
});
