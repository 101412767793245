<template>
    <OrqColumn>
        <div
            v-currency-format="
                currency === 'PERCENT' ? 'PERCENTSIMPLE' : currency
            "
        >
            <OrqInput
                v-model="inputValue"
                size="large"
                :label="label ?? t('transactions.amountToInvest')"
                :description="errorMsg"
                :placeholder="
                    currency === 'PERCENT'
                        ? `0${FORMAT_PRESETS.PERCENT.suffix}`
                        : `${FORMAT_PRESETS[currency]?.preffix}0`
                "
                :error="!!errorMsg"
                :inverse="inverse"
                @focus="$emit('update:mode', SELL_MODES.AMOUNT)"
            />
        </div>

        <div
            v-if="buttons.length > 0"
            class="buttons-container"
            :style="buttons.length >= 4 ? 'justify-content: space-between' : ''"
        >
            <OrqButton
                v-for="(button, index) in buttons"
                :key="index"
                :appearance="isSelected(button.percent) ? 'primary' : 'line'"
                :label="button.label"
                size="compact"
                :disabled="availableBalance <= 0"
                :inverse="inverse"
                @click="retrievePercent(button.percent)"
            />
        </div>
    </OrqColumn>
</template>

<script setup>
import { SELL_MODES } from '@/consts';
import { onMounted, ref, watch } from 'vue';

import { OrqButton, OrqInput, OrqColumn } from '@digital/orquestra';

import { FORMAT_PRESETS } from '@/consts';
import {
    currencyFormat as vCurrencyFormat,
    removeFormat,
    formatCurrency,
} from '@/directives/currencyFormat';

import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const inputValue = ref(null);

const props = defineProps({
    currency: {
        type: String,
        default: '',
    },
    amount: {
        type: Number,
        default: 0,
    },
    mode: {
        type: Number,
        required: true,
    },
    availableBalance: {
        type: Number,
        default: 0,
    },
    label: {
        type: String,
        default: null,
    },
    buttons: {
        type: Array,
        default: () => [],
    },
    errorMsg: {
        type: String,
        default: null,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:amount', 'update:mode']);

onMounted(() => {
    if (props.amount !== 0) {
        inputValue.value = formatCurrency(props.amount, props.currency);
    }
});

watch(inputValue, (newVal, oldVal) => {
    if (removeFormat(newVal) === removeFormat(oldVal)) {
        return; // Preventing emits when the value is the same
    }
    emit('update:amount', removeFormat(newVal));
});

const retrievePercent = (percent) => {
    inputValue.value =
        props.currency === 'PERCENT'
            ? formatCurrency(
                  percent,
                  props.currency === 'PERCENT'
                      ? 'PERCENTSIMPLE'
                      : props.currency
              )
            : formatCurrency(
                  (props.availableBalance * percent) / 100,
                  props.currency === 'PERCENT'
                      ? 'PERCENTSIMPLE'
                      : props.currency
              );
    emit('update:amount', removeFormat(inputValue.value));
    emit('update:mode', SELL_MODES.PERCENT);
};

const isSelected = (percent) => {
    const inPercentMode = props.mode === SELL_MODES.PERCENT;
    const isOnlyButton = props.buttons.length === 1;
    const isPercentOnly = props.currency === 'PERCENT';
    const amountEqualsPercent = isPercentOnly && props.amount === percent;
    return inPercentMode && (isOnlyButton || amountEqualsPercent);
};
</script>

<style lang="scss" scoped>
.buttons {
    &-container {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }
}
</style>
