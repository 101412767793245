<template>
    <OperationCompleted :inverse="inverse" v-bind="data[order.operation]" />
</template>

<script setup>
import OperationCompleted from '@/components/transactional-funds/Shared/OperationCompleted.vue';
import { useOrderStore } from '@/_store/order.store.js';
import { storeToRefs } from 'pinia';
import { OPERATION } from '@/consts.js';
import { formatDate } from '@/utils/text.js';

import { useInstrumentStore } from '@/_store/instrument.store.js';
const instrumentStore = useInstrumentStore();
const { isMarketOpen } = storeToRefs(instrumentStore);

import { useCurrencyStore } from '@/_store/currency.store.js';
const currencyStore = useCurrencyStore();
const { USDRate } = storeToRefs(currencyStore);

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);

const data = computed(() => ({
    [OPERATION.BUY]: {
        title: t('moneda.purchased'),
        amount: Math.floor(order.value.amount / USDRate.value.value),
        format: 'USD',
        isDollar: t('moneda.availableSoon'),
        info: [
            {
                title: t('transactions.success.transactionDate'),
                value: {
                    label: isMarketOpen.value?.open
                        ? formatDate(Date.now(), 'dayOfWeek')
                        : formatDate(
                              isMarketOpen.value?.nextOpen,
                              'dayOfWeek'
                          ) ?? '-',
                },
            },
            {
                title: t('moneda.purchaseRate'),
                value: {
                    format: 'CLPEXT',
                    amount: USDRate.value?.value,
                },
                showZero: true,
            },
        ],
    },
    [OPERATION.SELL]: {
        title: t('moneda.sold'),
        amount: order.value.amount,
        format: 'USD',
        isDollar: t('moneda.availableSoonSell'),
        info: [
            {
                title: t('transactions.success.transactionDate'),
                value: {
                    label: (() => {
                        return formatDate(Date.now(), 'dayOfWeek');
                    })(),
                },
            },
            {
                title: t('moneda.saleRate'),
                value: {
                    format: 'CLPEXT',
                    amount: USDRate.value?.value,
                },
                showZero: true,
            },
        ],
    },
}));

defineProps({
    inverse: {
        type: Boolean,
        default: false,
    },
});
</script>


