<template>
    <OrqDrawerBody>
        <template #header>
            <TransactionalHeader
                :title="title"
                :subtitle="subtitle"
                :inverse="inverse"
            />
        </template>

        <template #body>
            <OrqColumn :gap="48">
                <BodyAmount
                    :title="t('transactions.availableQuotas')"
                    :amount="availableQuantity"
                    currency="PLAIN"
                    :inverse="inverse"
                    hide-link
                />
                <BodySellAmount
                    v-model:amount.number="order.quantity"
                    v-model:mode="mode"
                    v-focus
                    :label="t('transactions.quotasToSell')"
                    :buttons="percentButtons"
                    :available-balance="availableQuantity"
                    currency="PLAIN"
                    :error-msg="errorMsg"
                    :inverse="inverse"
                    @update:amount="handleUpdate"
                />
                <BodyInfo :data="investInfo" :inverse="inverse" />
                <div
                    v-if="props?.item?.config?.mirrorIns"
                    class="switch-container"
                >
                    <div class="switch-label">
                        <span
                            class="body-baseline"
                            :class="{
                                'text--emphasis-base-high': !inverse,
                                'text--emphasis-base-high-inverse': inverse,
                            }"
                            >{{ t('transactions.secondaryMarket') }}</span
                        >

                        <OrqTooltip
                            :label="t('transactions.secondaryMarketTooltip')"
                            :inverse="inverse"
                        >
                            <OrqIconContainer
                                name="info"
                                :size="13"
                                :color="`emphasis-base-high${
                                    inverse ? '-inverse' : ''
                                }`"
                            />
                        </OrqTooltip>
                    </div>
                    <OrqSwitcher
                        v-if="isMirrorOpen"
                        :checked="switcher"
                        :inverse="inverse"
                        @change="switchMirror"
                    />
                    <div
                        v-else
                        :class="{ 'text--emphasis-base-high-inverse': inverse }"
                    >
                        Cerrado
                    </div>
                </div>
            </OrqColumn>
        </template>

        <template #footer>
            <TransactionalFooter
                :steps="totalSteps"
                :next-enabled="isValid"
                :inverse="inverse"
                @next="nextStep()"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import { computed, ref } from 'vue';
import TransactionalHeader from '../TransactionalHeader.vue';
import TransactionalFooter from '../TransactionalFooter.vue';
import BodySellAmount from '../Shared/BodySellAmount.vue';
import BodyInfo from '../body-components/BodyInfo.vue';
import BodyAmount from '../body-components/BodyAmount.vue';
import {
    OrqIconContainer,
    OrqSwitcher,
    OrqDrawerBody,
    OrqColumn,
    OrqTooltip,
} from '@digital/orquestra';
import { TYPES_DATA } from '@/consts';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useVuelidate } from '@vuelidate/core';
import {
    overZero,
    nonZero,
    minValue,
    maxValue,
} from '@/_helpers/rules.helper.js';

import { useRootEvents } from '@/composables/useRootEvents';
import { EVENTS } from '@/consts';
const rootEvents = useRootEvents();

import { OPERATION, MARKET, SELL_MODES } from '@/consts';

import { useOrderStore } from '@/_store/order.store.js';
const orderStore = useOrderStore();
const { order, totalSteps } = storeToRefs(orderStore);
const { initOrder, nextStep } = orderStore;

import { useInstrumentStore } from '@/_store/instrument.store.js';
const instrumentStore = useInstrumentStore();
const { isMirrorOpen } = storeToRefs(instrumentStore);

// Mode is not needed. We use quantity.
const mode = ref(SELL_MODES.AMOUNT);

const handleUpdate = () => {
    v$.value.$touch();
    order.value.amount = Math.ceil(
        props.item.insPrice.price * order.value.quantity
    );
};

const { t } = useI18n();

const title = computed(() => {
    return props.item?.info?.name;
});

const subtitle = computed(() => {
    return TYPES_DATA[props.item?.info?.type?.id]?.nameType ?? '';
});

const percentButtons = computed(() => [
    {
        label: t('transactions.percentRescue', { percent: '100%' }),
        percent: 100,
    },
]);

const switcher = ref(false);
const switchMirror = async () => {
    const operation = order.value.operation;
    await orderStore.$reset();

    rootEvents.emit(EVENTS.TRANSACCIONAL_OPEN, {
        idEncoded: props.item?.mirrorInsEncoded,
        operation,
        inverse: props.inverse,
    });
};

const props = defineProps({
    item: {
        type: Object,
        default: () => ({}),
    },
    availableQuantity: {
        type: Number,
        default: 0,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

const investInfo = computed(() => {
    return [
        {
            title: t('transactions.liquidityPeriod'),
            value: {
                label: t('transactions.day', props.item?.config?.rescTime),
            },
        },
    ];
});

const rules = computed(() => {
    const defaultRules = {
        quantityOverZero: overZero(props.availableQuantity),
        lessThanQuantity: maxValue(
            props.availableQuantity,
            t('transactions.moreThanAmountSell')
        ),
        nonZero: nonZero(t('transactions.lessThanMinSell')),
    };
    if (props.item?.config?.sellMinAmount > 0) {
        defaultRules.moreThanMin = minValue(props.item?.config?.sellMinAmount);
    }
    if (props.item?.config?.sellMaxAmount > 0) {
        defaultRules.lessThanMax = maxValue(props.item?.config?.sellMaxAmount);
    }

    return {
        quantity: defaultRules,
    };
});

const v$ = useVuelidate(rules, order);

const errorMsg = computed(() => v$.value.$errors[0]?.$message ?? null);

const isValid = computed(
    () => v$.value.$dirty && v$.value.$errors.length === 0
);

const cuotaPrice = computed(() => {
    return props.item?.insPrice?.sellPrice === 0
        ? props.item?.insPrice?.price
        : props.item?.insPrice?.sellPrice;
});

initOrder({
    idEncoded: props.item?.idEncoded,
    amount: 0,
    quantity: 0,
    price: cuotaPrice,
    market: MARKET.NAV,
    operation: OPERATION.SELL,
    currency: props.item?.config?.currency,
});
</script>

<style lang="scss" scoped>
.switch-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.switch-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}
</style>
