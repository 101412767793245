import { defineStore, storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import { useAccountsStore } from '@/_store/accounts.store.js';
import apvService from '@/_services/apv.service.js';
import fintocService from '@/_services/fintoc.service.js';
import { CURRENCY_FORMAT, RESPONSES_STATUS } from '@/consts';
import { useTokenStore } from '@/_store/token.store.js';
import { removeFormat } from '@/directives/currencyFormat';
import { APV_DEPOSIT_METHODS, ACCOUNT_TYPE } from '@/consts';

export const useApvStore = defineStore('apv', () => {
    const items = ref([]);
    const amounts = ref({});
    const currentItem = ref({});
    const selectedRegime = ref(null);
    const selectedAccount = ref(null);
    const apvID = ref(null);
    const operationError = ref(false);
    const mode = ref('normal');

    // Traspaso APV
    const savingType = ref();
    const selectedInstitution = ref();
    const savingTypes = ref([
        {
            label: 'Ahorro Previsional Voluntario',
            description: 'De cualquier régimen APV',
            value: 1,
        },
        {
            label: 'Depósito Convenido',
            value: 3,
        },
    ]);
    const selectedAmountType = ref();
    const amountTypes = ref([
        {
            label: 'El total',
            value: 1,
        },
        {
            label: 'Solo una parte',
            value: 2,
        },
    ]);
    const transferAmount = ref();
    const multiFondo = ref('');
    const destinationPercentages = ref({});
    const destinationInstruments = ref({});

    const stepIndex = ref(0);
    const steps = computed(() => {
        return [
            'init',
            'addMore',
            'selectRegime',
            'selectDepositMethod',
            'selectAccount',
            ...(outOfProfile.value && Object.keys(outOfProfile.value).length
                ? ['outOfProfile']
                : []),
            'confirm',
            'completed',
        ];
    });
    const step = computed(() => {
        return steps.value[stepIndex.value];
    });

    const transferStepIndex = ref(0);
    const transferSteps = computed(() => {
        return [
            'selectSavingType',
            'selectInstitution',
            'selectAmount',
            'selectFunds',
        ];
    });
    const transferStep = computed(() => {
        return transferSteps.value[transferStepIndex.value];
    });

    const updateCurrentItem = (item) => {
        currentItem.value = item;
    };

    const openingAPVAccount = ref(false);

    const insertToItems = (item) => {
        // idBtg is in b64, we pass it as integer
        const idBtg = parseInt(window.atob(item.info.idBtg), 10);
        if (items.value.length == 0) {
            items.value = [
                {
                    securityId: idBtg,
                    securityExchange: item.config.series,
                    symbol: item.info.name,
                    currency: item.config.currency,
                    index: 0,
                },
            ];
        } else {
            if (
                items.value.findIndex((item) => item.securityId == idBtg) === -1
            ) {
                items.value = [
                    ...items.value,
                    {
                        securityId: idBtg,
                        securityExchange: item.config.series,
                        symbol: item.info.name,
                        currency: item.config.currency,
                        index: items.value.length,
                    },
                ];
            }
        }
        stepIndex.value = 0;
        currentItem.value = item;
    };

    const isGiroEnabled = ref(false);
    const isGiroEnabledLoading = ref(false);
    const getGiroEnabled = async () => {
        isGiroEnabledLoading.value = true;
        try {
            const { data } = await apvService.getGiroEnabled();
            isGiroEnabled.value = data;
        } catch (error) {
            // Something went wrong. Deactivate the option.
            isGiroEnabled.value = false;
        } finally {
            isGiroEnabledLoading.value = false;
        }
    };

    const isFintocEnabled = ref(false);
    const isFintocEnabledLoading = ref(false);
    const getFintocEnabled = async () => {
        isFintocEnabledLoading.value = true;
        try {
            const { data } = await fintocService.getFintocEnabled();
            isFintocEnabled.value = data;
        } catch (error) {
            // Something went wrong. Deactivate the option.
            isFintocEnabled.value = false;
        } finally {
            isFintocEnabledLoading.value = false;
        }
    };

    const goToStep = (destinationStep) => {
        const index = steps.value.findIndex((step) => destinationStep === step);
        // defaults to 0 so stepIndex doesn't get stuck
        stepIndex.value = index === -1 ? 0 : index;
    };

    const nextStep = () => {
        if (step.value == 'init') {
            if (mode.value == 'autoinvest') {
                goToStep('selectRegime');
            } else {
                goToStep('addMore');
            }
        } else if (step.value == 'addMore') {
            goToStep('selectRegime');
        } else if (step.value == 'selectRegime') {
            goToStep('selectDepositMethod');
        } else if (step.value == 'selectDepositMethod') {
            if (selectedDepositMethod.value == APV_DEPOSIT_METHODS.FROM_BTG) {
                goToStep('selectAccount');
            } else {
                if (
                    outOfProfile.value &&
                    Object.keys(outOfProfile.value).length
                ) {
                    goToStep('outOfProfile');
                } else {
                    goToStep('confirm');
                }
            }
        } else if (step.value == 'selectAccount') {
            if (outOfProfile.value && Object.keys(outOfProfile.value).length) {
                goToStep('outOfProfile');
            } else {
                goToStep('confirm');
            }
        } else if (step.value == 'outOfProfile') {
            goToStep('confirm');
        } else if (step.value == 'confirm') {
            goToStep('completed');
        }
    };

    const goToTransferStep = (destinationStep) => {
        const index = transferSteps.value.findIndex(
            (step) => destinationStep === step
        );
        // defaults to 0 so stepIndex doesn't get stuck
        transferStepIndex.value = index === -1 ? 0 : index;
    };

    const nextTransferStep = () => {
        if (transferStep.value == 'selectSavingType') {
            goToTransferStep('selectInstitution');
        } else if (transferStep.value == 'selectInstitution') {
            goToTransferStep('selectAmount');
        } else if (transferStep.value == 'selectAmount') {
            goToTransferStep('selectFunds');
        }
    };

    const accountsStore = useAccountsStore();
    const { accounts, currentAccount } = storeToRefs(accountsStore);

    const hasAPVAccount = computed(() => {
        return accounts.value.some(
            (account) => account.tipoCuenta === ACCOUNT_TYPE.APV_FONDOS_MUTUOS
        );
    });

    const APVAccountID = computed(() => {
        return (
            accounts.value.find(
                (account) =>
                    account.tipoCuenta === ACCOUNT_TYPE.APV_FONDOS_MUTUOS
            )?.idCuentaGrupo ?? ''
        );
    });

    const accountForPayload = computed(() => {
        return hasAPVAccount.value
            ? APVAccountID.value
            : currentAccount.value.idCuentaGrupo;
    });

    const totalAmount = computed(() => {
        return Object.values(amounts.value).reduce((acc, cur) => acc + cur, 0);
    });

    const instrumentToRemove = ref();
    const ffmmPayload = computed(() => {
        const total = Object.entries(amounts.value)
            .filter((item) => item[0] != `${instrumentToRemove.value}`)
            .map((item) => item[1])
            .reduce((acc, cur) => acc + cur, 0);
        const ffmm = items.value
            .filter((item) => item.securityId != instrumentToRemove.value)
            .map((item) => {
                return {
                    ...item,
                    amount: Math.trunc(
                        (amounts.value[item.securityId] / total) * 100
                    ),
                    text: accountForPayload.value,
                };
            })
            .filter((item) => {
                return item.amount > 0;
            });
        if (total > 0) {
            const delta = 100 - ffmm.reduce((acc, cur) => acc + cur.amount, 0);
            if (delta != 0 && ffmm.length > 0) {
                ffmm[0] = {
                    ...ffmm[0],
                    amount: delta + ffmm[0].amount,
                };
            }
        }
        return ffmm;
    });

    const tokenStore = useTokenStore();
    const { error: tokenError } = storeToRefs(tokenStore);

    const outOfProfile = ref(null);

    const loadingCreateAPV = ref(false);
    const createAPV = async (getOutOfProfile = false) => {
        try {
            loadingCreateAPV.value = true;
            const response = await apvService.createAPV({
                id: apvID.value,
                subscription: 1,
                regimen: selectedRegime.value,
                account: accountForPayload.value,
                invAmount: totalAmount.value,
                instruments: ffmmPayload.value,
                sourceAccount: parseInt(selectedAccount.value, 10),
                transfer: null,
                mode: selectedDepositMethod.value,
            });
            apvID.value = response.data;

            if (!getOutOfProfile) return;
            const oopResponse = await apvService.outOfProfile(apvID.value);
            if (oopResponse.data) {
                outOfProfile.value = oopResponse.data.reduce(
                    (groups, item) => ({
                        ...groups,
                        [item.config.risk]: [
                            ...(groups[item.config.risk] || []),
                            item,
                        ],
                    }),
                    {}
                );
            }
        } catch {
            operationError.value = true;
        } finally {
            loadingCreateAPV.value = false;
        }
    };

    const loadingSignAPV = ref(false);
    const signAPV = async ({ token, timer }) => {
        try {
            loadingSignAPV.value = true;
            await apvService.signAPV({
                id: apvID.value,
                subscription: 1,
                regimen: selectedRegime.value,
                account: accountForPayload.value,
                invAmount: totalAmount.value,
                instruments: ffmmPayload.value,
                sourceAccount: parseInt(selectedAccount.value, 10),
                transfer: null,
                mode: selectedDepositMethod.value,
                token,
                timer,
            });
        } catch (error) {
            const { response } = error;
            if (response?.status === 400) {
                tokenError.value = RESPONSES_STATUS.TOKEN_ERROR;
            } else {
                operationError.value = true;
            }
            // We throw it to interrupt any steps further
            throw error;
        } finally {
            loadingSignAPV.value = false;
        }
    };

    const deleteItemFromAPV = async (id) => {
        try {
            instrumentToRemove.value = id;
            loadingCreateAPV.value = true;
            const total = Object.entries(amounts.value)
                .filter((item) => item[0] != `${instrumentToRemove.value}`)
                .map((item) => item[1])
                .reduce((acc, cur) => acc + cur, 0);
            await apvService.createAPV({
                id: apvID.value,
                subscription: 1,
                regimen: selectedRegime.value,
                account: accountForPayload.value,
                invAmount: total,
                instruments: ffmmPayload.value,
                transfer: null,
            });
            amounts.value[id] = 0;
            items.value = items.value.filter((item) => item.securityId != id);
        } catch {
            operationError.value = true;
        } finally {
            instrumentToRemove.value = null;
            loadingCreateAPV.value = false;
        }
    };

    const deleteAllAPV = async () => {
        try {
            loadingCreateAPV.value = true;
            await apvService.createAPV({
                id: apvID.value,
                subscription: 1,
                regimen: selectedRegime.value,
                account: accountForPayload.value,
                invAmount: totalAmount.value,
                instruments: [],
                transfer: null,
            });
            items.value = [];
            amounts.value = {};
        } catch {
            operationError.value = true;
        } finally {
            loadingCreateAPV.value = false;
        }
    };

    const loadingTransferAPV = ref(false);
    const createTransferAPV = async () => {
        try {
            loadingTransferAPV.value = true;
            const ahorroType = {
                type: savingType.value,
                balance: selectedAmountType.value,
            };
            if (selectedAmountType.value == 2) {
                ahorroType.amount = removeFormat(transferAmount.value);
            }
            if (multiFondo.value.length > 0) {
                ahorroType.multiFondo = multiFondo.value.reduce(
                    (acc, cur) => `${acc},${cur}`
                );
            }
            const instruments = Object.entries(destinationPercentages.value)
                .filter((i) => removeFormat(i[1]) > 0)
                .map((i) => {
                    const instrument = destinationInstruments.value[i[0]];
                    return {
                        securityId: parseInt(
                            window.atob(instrument.info.idBtg),
                            10
                        ),
                        securityExchange: instrument.config.series,
                        symbol: instrument.info.name,
                        currency: instrument.config.currency,
                        amount: removeFormat(i[1]),
                        text: accountForPayload.value,
                    };
                });
            const response = await apvService.createAPV({
                id: apvID.value,
                subscription: 2,
                account: accountForPayload.value,
                instruments,
                transfer: {
                    entity: selectedInstitution.value.id,
                    entityName: selectedInstitution.value.name,
                    ahorroType: [ahorroType],
                },
                invAmount: null,
            });
            items.value = [];
            amounts.value = {};
            apvID.value = response.data;
        } catch {
            operationError.value = true;
        } finally {
            loadingTransferAPV.value = false;
        }
    };

    const loadingSignTransferAPV = ref(false);
    const signTransferAPV = async ({ token, timer }) => {
        try {
            loadingSignTransferAPV.value = true;
            const ahorroType = {
                type: savingType.value,
                balance: selectedAmountType.value,
            };
            if (selectedAmountType.value == 2) {
                ahorroType.amount = removeFormat(transferAmount.value);
            }
            if (multiFondo.value.length > 0) {
                ahorroType.multiFondo = multiFondo.value.reduce(
                    (acc, cur) => `${acc},${cur}`
                );
            }
            const instruments = Object.entries(destinationPercentages.value)
                .filter((i) => removeFormat(i[1]) > 0)
                .map((i) => {
                    const instrument = destinationInstruments.value[i[0]];
                    return {
                        securityId: parseInt(
                            window.atob(instrument.info.idBtg),
                            10
                        ),
                        securityExchange: instrument.config.series,
                        symbol: instrument.info.name,
                        currency: instrument.config.currency,
                        amount: removeFormat(i[1]),
                        text: accountForPayload.value,
                    };
                });
            await apvService.signAPV({
                id: apvID.value,
                subscription: 2,
                account: accountForPayload.value,
                instruments,
                transfer: {
                    entity: selectedInstitution.value.id,
                    entityName: selectedInstitution.value.name,
                    ahorroType: [ahorroType],
                },
                invAmount: null,
                token,
                timer,
            });
        } catch (error) {
            const { response } = error;
            if (response?.status === 400) {
                tokenError.value = RESPONSES_STATUS.TOKEN_ERROR;
            } else {
                operationError.value = true;
            }
            // We throw it to interrupt any steps further
            throw error;
        } finally {
            loadingSignTransferAPV.value = false;
        }
    };

    const regimes = ref([
        {
            label: 'Régimen A',
            value: 'A',
            image: 'https://static.btgpactual.cl/apps/apv/regimen-a.svg',
        },
        {
            label: 'Régimen B',
            value: 'B',
            image: 'https://static.btgpactual.cl/apps/apv/regimen-b.svg',
        },
        // {
        //     label: 'Depósito Convenido',
        //     value: 'DC',
        //     image : 'https://static.btgpactual.cl/apps/apv/deposito-convenido.svg',
        // },
    ]);

    const selectedRegimeText = computed(() => {
        return regimes.value.find((r) => r.value == selectedRegime.value).label;
    });

    const selectedDepositMethod = ref();
    const depositMethods = computed(() => {
        const methods = [];

        if (isGiroEnabled.value) {
            methods.push({
                label: 'Depósito desde BTG Pactual',
                description:
                    'Utiliza el saldo en caja de tus cuentas existentes en BTG Pactual.',
                value: 1,
            });
        }

        if (isFintocEnabled.value) {
            methods.push({
                label: 'Por Fintoc',
                description:
                    'Transfiere desde tu banco sin salir de la aplicación de BTG Pactual.',
                extra: 'Aplican los límites de transferencia de tu banco',
                value: 2,
            });
        }

        return methods;
    });

    const loadingPendingAPV = ref(false);
    const getPendingAPV = async () => {
        loadingPendingAPV.value = true;
        const response = await apvService.getPendingAPV();
        if (response.data.suscription == 1) {
            apvID.value = response.data.id;
            items.value = response.data.ffmm
                .filter((item) => {
                    return item.amount > 0;
                })
                .map(
                    (
                        { symbol, securityExchange, securityId, currency },
                        index
                    ) => {
                        return {
                            symbol,
                            securityExchange,
                            securityId: parseInt(securityId, 10),
                            currency: CURRENCY_FORMAT[currency],
                            index,
                        };
                    }
                );
            amounts.value = Object.fromEntries(
                response.data.ffmm
                    .filter((item) => {
                        return item.amount > 0;
                    })
                    .map((item) => {
                        return [
                            item.securityId,
                            (item.amount * response.data.invAmount) / 100,
                        ];
                    })
            );
        }
        loadingPendingAPV.value = false;
        return response.data;
    };

    const $reset = () => {
        items.value = [];
        amounts.value = {};
        stepIndex.value = 0;
        transferStepIndex.value = 0;
        currentItem.value = {};
        selectedRegime.value = null;
        selectedDepositMethod.value = null;
        selectedAccount.value = null;
        apvID.value = null;
        operationError.value = false;
        savingType.value = null;
        selectedInstitution.value = null;
        selectedAmountType.value = null;
        multiFondo.value = '';
        destinationPercentages.value = {};
        destinationInstruments.value = {};
        openingAPVAccount.value = false;
        mode.value = 'normal';
    };

    const APVInstitutions = ref([]);
    const loadingAPVInstitutions = ref(false);
    const getAPVInstitutions = async () => {
        try {
            loadingAPVInstitutions.value = true;
            const response = await apvService.getAPVInstitutions();
            APVInstitutions.value = response.data;
        } finally {
            loadingAPVInstitutions.value = false;
        }
    };

    const percentages = computed(() => {
        return Object.fromEntries(
            ffmmPayload.value.map((e) => [e.securityId, e.amount])
        );
    });

    return {
        items,
        selectedRegime,
        selectedDepositMethod,
        depositMethods,
        selectedAccount,
        currentItem,
        amounts,
        savingType,
        selectedInstitution,
        savingTypes,
        selectedAmountType,
        transferAmount,
        amountTypes,
        multiFondo,
        destinationPercentages,
        destinationInstruments,
        insertToItems,
        updateCurrentItem,
        step,
        transferStep,
        nextStep,
        goToStep,
        nextTransferStep,
        goToTransferStep,
        createAPV,
        signAPV,
        loadingSignAPV,
        signTransferAPV,
        loadingSignTransferAPV,
        deleteItemFromAPV,
        deleteAllAPV,
        loadingCreateAPV,
        loadingTransferAPV,
        createTransferAPV,
        apvID,
        totalAmount,
        regimes,
        selectedRegimeText,
        getPendingAPV,
        loadingPendingAPV,
        operationError,
        APVInstitutions,
        loadingAPVInstitutions,
        getAPVInstitutions,
        $reset,
        openingAPVAccount,
        mode,
        outOfProfile,
        percentages,
        isGiroEnabled,
        isGiroEnabledLoading,
        getGiroEnabled,
        isFintocEnabled,
        isFintocEnabledLoading,
        getFintocEnabled,
    };
});
