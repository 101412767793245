<template>
    <OrqDrawerBody>
        <template #header>
            <OrqColumn :gap="16">
                <div
                    class="heading-md"
                    :class="`text--emphasis-base-high${
                        inverse ? '-inverse' : ''
                    }`"
                >
                    Abonar
                </div>
                <div
                    class="heading-lg"
                    :class="`text--emphasis-base-high${
                        inverse ? '-inverse' : ''
                    }`"
                >
                    Selecciona la cuenta de destino en BTG Pactual
                </div>
            </OrqColumn>
        </template>

        <template #body>
            <OrqRadioButtonGroup
                v-model="accountSelectedIndex"
                :items="accounts"
                :inverse="inverse"
            />
        </template>

        <template #footer>
            <AbonarFooter
                :loading="isSaving"
                :inverse="inverse"
                @next="saveAndContinue"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import {
    OrqDrawerBody,
    OrqRadioButtonGroup,
    OrqColumn,
} from '@digital/orquestra';
import { computed, ref } from 'vue';

import AbonarFooter from './shared/AbonarFooter.vue';

import { useAbonarStore } from '@/_store/abonar.store';
const abonarStore = useAbonarStore();
const { accountSelected } = storeToRefs(abonarStore);

import { useAccountsStore } from '@/_store/accounts.store.js';
import { storeToRefs } from 'pinia';
const accountStore = useAccountsStore();

const accountSelectedIndex = computed({
    get() {
        return accounts.value.findIndex((item) => {
            return Number(item.idCuentaGrupo) === Number(accountSelected.value);
        });
    },
    set(newValue) {
        accountSelected.value = accounts.value[newValue].idCuentaGrupo;
    },
});
const accounts = computed(() => {
    return accountStore.accounts
        .filter((i) => i.tipoCuenta === '4' && i.estadoVisible === '1')
        .map((i) => {
            return {
                label: i.glosaCuenta,
                description: i.idCuentaGrupo,
                idCuentaGrupo: i.idCuentaGrupo,
                tipoCuenta: i.tipoCuenta,
            };
        });
});

const isSaving = ref(false);
const saveAndContinue = async () => {
    isSaving.value = true;
    try {
        await abonarStore.setAccountSelected();
    } catch (error) {
        console.log("Couldn't save the account", error);
    }
    isSaving.value = false;
    abonarStore.nextStep();
};

defineProps({
    inverse: {
        type: Boolean,
        default: false,
    },
});
</script>
