<template>
    <OrqDrawer :is-open="isDrawerOpen" @close="isDrawerOpen = false">
        <template v-if="!currentAccount?.giroEnabled || agent">
            <RetirarNotAvailable />
        </template>

        <template v-else-if="getBankAccountsForGiroLoading || balanceLoading || statusLoading">
            <RetirarLoading />
        </template>

        <template v-else>
            <component :is="components[currentStep]" />
        </template>
    </OrqDrawer>
</template>

<script setup>
import { OrqDrawer } from '@digital/orquestra';
import { EVENTS } from '@/consts';
import { useRootEvents } from '@/composables/useRootEvents';
import { onUnmounted, ref, computed } from 'vue';

import RetirarNotAvailable from './RetirarNotAvailable.vue';
import RetirarLoading from './RetirarLoading.vue';
import MaintenanceMode from '@/components/transactional-funds/Shared/MaintenanceMode.vue';

const { addListener, removeListeners } = useRootEvents();
const isDrawerOpen = ref(false);

import { useRetirarStore } from '@/_store/retirar.store';
import { storeToRefs } from 'pinia';
const retirarStore = useRetirarStore();

import { useAgencyStore } from '@/_store/agency.store.js';
const agencyStore = useAgencyStore();
const { agent } = storeToRefs(agencyStore);

const { currentStep, getBankAccountsForGiroLoading, components: storeComponents } =
storeToRefs(retirarStore);

import { useStatusStore } from '@/_store/status.store';
const statusStore = useStatusStore();
const { canTrans, loading: statusLoading } = storeToRefs(statusStore);

const components = computed(() => {
    if (!canTrans.value) {
        return [MaintenanceMode];
    }
    return storeComponents.value;
});

import { useAccountsStore } from '@/_store/accounts.store.js';
const accountsStore = useAccountsStore();
const { currentAccount } = storeToRefs(accountsStore);

import { useBalanceStore } from '@/_store/balance.store.js';
const balanceStore = useBalanceStore();
const { loading: balanceLoading } = storeToRefs(useBalanceStore);

addListener(EVENTS.RETIRAR_OPEN, () => {
    isDrawerOpen.value = true;
    if (!currentAccount.value?.giroEnabled) {
        return;
    }

    // Init retirar
    retirarStore.$reset();
    retirarStore.getBankAccountsForGiro();
    retirarStore.getMinAmountForGiro();

    statusStore.fetchServerStatus();
    balanceStore.getBalance();
});

addListener(EVENTS.RETIRAR_CLOSE, () => {
    isDrawerOpen.value = false;
});

onUnmounted(() => {
    removeListeners();
});
</script>
