<template>
    <OrqDrawerBody>
        <template #header>
            <OrqColumn :gap="16">
                <div class="heading-md">{{ t('withdraw.title') }}</div>
                <div class="heading-lg">
                    {{ t('withdraw.commissionsWarning.title') }}
                </div>
            </OrqColumn>
        </template>

        <template #body>
            <OrqColumn>
                <OrqNotificationFixed
                    notification-type="fixed"
                    kind="warning"
                    :label="t('withdraw.commissionsWarning.message')"
                    :label-size="14"
                />
                <OrqCheckbox
                    v-model="accept"
                    value="accept"
                    label="Acepto las condiciones"
                />
            </OrqColumn>
        </template>

        <template #footer>
            <RetirarFooter :next-enabled="isValid" @next="saveAndContinue" />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import {
    OrqDrawerBody,
    OrqColumn,
    OrqNotificationFixed,
    OrqCheckbox,
} from '@digital/orquestra';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

import RetirarFooter from './shared/RetirarFooter.vue';
import { computed, ref } from 'vue';

const accept = ref([]);

const isValid = computed(() => {
    return accept.value.includes('accept');
});

import { useRetirarStore } from '@/_store/retirar.store';
const retirarStore = useRetirarStore();
const saveAndContinue = async () => {
    retirarStore.nextStep();
};
</script>


