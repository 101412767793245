<template>
    <OperationCompleted v-bind="data[order.operation]" />
</template>

<script setup>
import OperationCompleted from '@/components/transactional-funds/Shared/OperationCompleted.vue';
import { useOrderStore } from '@/_store/order.store.js';
import { storeToRefs } from 'pinia';
import { OPERATION, SELL_MODES } from '@/consts.js';
import { formatDate } from '@/utils/text.js';

import { useInstrumentStore } from '@/_store/instrument.store.js';
const instrumentStore = useInstrumentStore();
const { isMarketOpen } = storeToRefs(instrumentStore);

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const orderStore = useOrderStore();
const { order, orderResponse } = storeToRefs(orderStore);

const props = defineProps({
    item: {
        type: Object,
        default: () => ({}),
    },
});

const data = computed(() => ({
    [OPERATION.BUY]: {
        title: t('transactions.success.invest'),
        amount: order.value.amount,
        format: order.value.currency,
        headline: t('transactions.in', { name: props.item?.info?.name }),
        outOfSchedule: !isMarketOpen.value?.open,
        cancelable: false,
        info: [
            {
                title: t('transactions.entryDate'),
                value: {
                        label: (() => {
                            if (isMarketOpen.value?.open) {
                                return formatDate(Date.now(), 'dayOfWeek');
                            }

                            if (!isMarketOpen.value?.nextOpen) {
                                return '-';
                            }

                            return formatDate(
                                isMarketOpen.value?.nextOpen,
                                'dayOfWeek'
                            );
                        })(),
                    },
            },
            {
                title: t('transactions.success.execDate'),
                value: {
                    label: formatDate(
                        orderResponse.value.liqDate * 1000,
                        'dayOfWeek'
                    ),
                },
                tooltip: t('transactions.success.execDateTooltip'),
            },
        ],
    },
    [OPERATION.SELL]: {
        title: t('transactions.success.retrieve'),
        ...(order.value.mode === SELL_MODES.AMOUNT
            ? {
                  amount: order.value.amount,
                  format: order.value.currency,
              }
            : {
                  amount:  order.value.quantity,
                  format: 'NONE',
                  measure: t('transactions.quotas', Math.round(order.value.quantity)),
              }),
        headline: t('transactions.of', { name: props.item?.info?.name }),
        outOfSchedule: !isMarketOpen.value?.open,
        cancelable: false,
        notification:
            order.value?.mode === SELL_MODES.PERCENT
                ? t('transactions.finalAmountReminder')
                : '',
        info: [
            {
                title: t('transactions.success.transactionDate'),
                value: {
                    label: (() => {
                        if (isMarketOpen.value?.open) {
                            return formatDate(new Date(), 'dayOfWeek');
                        }

                        return formatDate(
                            isMarketOpen.value?.nextOpen,
                            'dayOfWeek'
                        );
                    })(),
                },
            },
            {
                title: t('transactions.success.liquidityDate'),
                value: {
                    label: formatDate(
                        orderResponse.value.liqDate * 1000,
                        'dayOfWeek'
                    ),
                },
                tooltip: t('transactions.success.liquidityDateTooltip'),
            },
            ...(order.value?.mode === SELL_MODES.PERCENT
                ? [
                      {
                          title: t('transactions.success.estSell'),
                          value: {
                              amount:
                                  order.value?.amount -
                                  Number(
                                      props.item?.config?.comisionEnabled
                                          ? order.value.amountRescueAnticipated ?? 0
                                          : 0
                                  ),
                              format: props.item?.config?.multiproduct
                                  ? 'CLP'
                                  : props.item?.config?.currency ?? 'CLP',
                          },
                          showZero: true,
                      },
                  ]
                : []),
        ],
    },
}));
</script>
