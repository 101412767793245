<template>
    <MarketClosed v-bind="data[operation]" :title="t('transactions.fundClosedTitle')"  />
</template>

<script setup>
import MarketClosed from '../Shared/MarketClosed.vue';
import { OPERATION } from '@/consts.js';

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { formatDate } from '@/utils/text.js';

const { t } = useI18n();

const props = defineProps({
    item: {
        type: Object,
        default: () => ({}),
    },
    operation: {
        type: String,
        default: OPERATION.BUY,
    },
});

const data = computed(() => {
    let windows = props.item?.config?.windows.filter(
        (el) => el.side === props.operation
    );

    return {
        [OPERATION.BUY]: {
            label: `${t('transactions.fundClosed', {
                transaction: t('transactions.contribute'),
            })} ${
                windows.length
                    ? t('transactions.fundClosedDate', {
                          date: formatDate(windows[0].openDate * 1000),
                      })
                    : ''
            }`,
            operation: OPERATION.BUY,
            item: props.item,
        },
        [OPERATION.SELL]: {
            label: `${t('transactions.fundClosed', {
                transaction: t('transactions.retrieve'),
            })} ${
                windows.length
                    ? t('transactions.fundClosedDate', {
                          date: formatDate(windows[0].openDate * 1000),
                      })
                    : ''
            }`,
            operation: OPERATION.SELL,
            item: props.item,
        },
    };
});
</script>
