import router from '@/router';
import axios from 'axios';
import { ref } from 'vue';
const slugs = ref([]);

export const useSlugs = () => {
    const install = async () => {
        try {
            const path = `/slugs.json`;
            const r = await axios.get(path);
            slugs.value = r.data;
        } catch (error) {
            console.log(error);
        }
    };

    const toIntrumentObject = (pattern) => {
        const instrument = slugs.value.find(
            (i) => i.id.toLowerCase() === pattern.toLowerCase()
        );

        if (instrument) {
            return instrument;
        }
        // Returning ACCION by default, because is most common. No other desicion was made.
        return { id: pattern, slug: null, typeID: null, type: null };
    };

    const toSlug = (id) => {
        const instrument = slugs.value.find(
            (i) => i.id.toLowerCase() === id.toLowerCase()
        );
        if (instrument) {
            return instrument.slug;
        }
        return id;
    };
    const toID = (pattern) => {
        const instrument = slugs.value.find((i) => {
            // to prevent further errors return undefined
            if (i.slug && pattern) {
                return i.slug.toLowerCase() === pattern.toLowerCase();
            }
            return false;
        });
        if (instrument) {
            return instrument.id;
        }
        return pattern;
    };

    const createURL = (idEncoded) => {
        const specialCases = {
            Q1VSLlVTRC9DTFBTUE9ULkZN: '/productos-de-inversion/dolar',
        };

        if (Object.keys(specialCases).includes(idEncoded)) {
            return router.resolve({
                path: specialCases[idEncoded],
            }).href;
        }

        const id = toSlug(idEncoded);
        const { slug, type } = toIntrumentObject(idEncoded);

        const href = router.resolve({
            name: 'productos-de-inversion-detail',
            params: { slug: slug || id, type },
        }).href;

        return href;
    };

    return { toID, toSlug, toIntrumentObject, createURL, install };
};
