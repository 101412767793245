import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

import { DOCUMENTS as DOCUMENT_CODES } from '@/consts';

import contractsService from '@/_services/contracts.service.js';
import { useAccountsStore } from './accounts.store';

import { formatDate } from '@/utils/text.js';

export const useContractsStore = defineStore('contracts', () => {
    const CONTRACTS = 'contracts',
        DOCUMENTS = 'documents';

    // TODO: pendingContracts and fetchPendings is now redundant with fetchContractsAndDocuments mutation.
    const pendingContracts = ref([]);
    const fetchPendings = async () => {
        const contracts = await contractsService
            .getContracts(true)
            .then((r) => {
                if (r.data !== '') {
                    pendingContracts.value = r.data;
                }
            })
            .catch(() => {});
        return contracts;
    };

    const loading = ref(false);
    const contractsAndDocuments = ref({
        [CONTRACTS]: {
            true: [],
            false: [],
        },
        [DOCUMENTS]: {
            true: [],
            false: [],
        },
    });

    /**
     * Returns the contracts that are shown in the pending contract list.
     **/
    const visiblePendingContracts = computed(() => {
        const pendingContracts = contractsAndDocuments.value[CONTRACTS].true
        const notVisible = [DOCUMENT_CODES.NCG380, DOCUMENT_CODES.FICHA_PN, DOCUMENT_CODES.FICHA_PJ, "30", "36", "50"];

        return pendingContracts.filter(
            (item) => !(notVisible.includes(item.value)
                || (item.value === DOCUMENT_CODES.ANEXO_A 
                    && pendingContracts.find(item => item.value === DOCUMENT_CODES.NCG380))
            ));
    });

    const hasPendingContracts = computed(() => {
        return visiblePendingContracts.value.length !== 0 
            || contractsAndDocuments.value[DOCUMENTS].true.length !== 0;
    });

    const accountsStore = useAccountsStore();
    const fetchContractsAndDocuments = async (source, pending) => {
        loading.value = true;
        const listService =
            source === CONTRACTS ? 'getContracts' : 'getDocuments';

        const response = await contractsService[listService]({
            account: accountsStore.selected,
            pending,
        });
        let result = response.data;
        if (response.status === 200 && response.data.length > 0) {
            result.forEach((item) => {
                item.value = item.codigo || item.codigoSADCO || item.idRegistro;
                item.label = item.nombre || item.nombreSADCO;
                item.fecOperacion = formatDate(item.fecOperacion);
                item.fecVencimiento = formatDate(item.fecVencimiento);
            });
            contractsAndDocuments.value[source][pending] = result;
        } else if (response.data.length === 0) {
            contractsAndDocuments.value[source][pending] = [];
        }
        loading.value = false;
    };

    const getPendingContracts = async () => {
        const contracts = fetchContractsAndDocuments('contracts', true);
        const documents = fetchContractsAndDocuments('documents', true);
        await Promise.allSettled([contracts, documents]);
    };

    const downloadContract = async ({ pending, id }) => {
        const response = await contractsService.downloadContracts({
            pending,
            id,
        });
        return response;
    };

    const downloadDocument = async ({ pending, id }) => {
        const response = await contractsService.downloadDocuments({
            account: accountsStore.selected,
            pending,
            id,
        });
        return response;
    };

    const signContract = async ({ data }) => {
        const response = await contractsService.signContracts({ data });

        /**
         * We are asuming the contract was signed so instead of calling the service again,
         * we just remove the item from the list.
         * Important: If the user reload and the contract wasn't signed,
         * it will be available.
         */
        data.forEach((item) => {
            const index = contractsAndDocuments.value.contracts.true?.findIndex(
                (contract) => contract.codigoSADCO === item.codigoSADCO
            );
            if (index !== -1) {
                contractsAndDocuments.value.contracts.true?.splice(index, 1);
            }
        });
        
        return response;
    };

    const signDocument = async ({ ids }) => {
        const response = await contractsService.signDocuments({
            account: accountsStore.selected,
            ids,
        });
        fetchContractsAndDocuments(DOCUMENTS, true);
        return response;
    };

    const pendingAnexoA = computed(() => {
        const match = pendingContracts.value.find(
            (contract) => contract.codigoSADCO === 58
        );
        return match ?? null;
    });

    const ficha = ref({});
    const loadingFicha = ref(false);
    const getFicha = async () => {
        loadingFicha.value = true;
        const response = await contractsService.getFicha();
        if (response.status === 200) {
            ficha.value = response.data;
        }

        loadingFicha.value = false;
    };

    const updateFicha = async () => {
        return await contractsService.updateFicha(ficha.value);
    };

    const ncg380 = ref({});
    const loadingNCG380 = ref(false);
    const getNCG380 = async () => {
        loadingNCG380.value = true;
        const response = await contractsService.getNCG380();
        if (response.status === 200) {
            ncg380.value = response.data;
        }

        loadingNCG380.value = false;
    };

    const updateNCG380 = async () => {
        return await contractsService.updateNCG380(ncg380.value);
    };

    return {
        loading,
        contractsAndDocuments,
        downloadContract,
        downloadDocument,
        signContract,
        signDocument,
        fetchContractsAndDocuments,
        getPendingContracts,
        pendingContracts,
        fetchPendings,
        pendingAnexoA,
        ficha,
        loadingFicha,
        getFicha,
        updateFicha,
        visiblePendingContracts,
        hasPendingContracts,
        ncg380,
        loadingNCG380,
        getNCG380,
        updateNCG380,
    };
});
