import api from './api.js';

export default {
    isOpen() {
        return { data: { open: true } };
    },
    buy({ account, simulation, timer, token }) {
        return api.post('termDeposit', {
            account,
            simulation,
            secondGenerateToken: timer,
            tokenTrx: token,
        });
    },
    simulation({ amount, currency = 'CLP', term }) {
        return api.get('termDeposit/simulation', {
            params: {
                amount,
                currency,
                term,
            },
        });
    },
    getDaysByCurrency(currency = 'CLP') {
        return api.get('termDeposit/simulation/days', {
            params: {
                currency,
            },
        });
    },
    getInterestRateTableByCurrency({ currency, term }) {
        return api.get('termDeposit/tasa', {
            params: {
                currency,
                term,
            },
        });
    },
    getUFRate() {
        return api.get('termDeposit/currency?currency=UF');
    },
};
