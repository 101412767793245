<template>
    <div class="footer__container">
        <div class="footer__step-controls">
            <span
                v-if="exit"
                class="footer__cancel body-baseline"
                :class="{
                    'text--emphasis-base-high': !inverse,
                    'text--emphasis-base-high-inverse': inverse,
                }"
                @click="onClose()"
            >
                Cancelar
            </span>
            <div v-else></div>
            <div class="footer__buttons">
                <OrqButton
                    v-if="back"
                    appearance="line"
                    size="compact"
                    :label="t('transactions.back')"
                    :inverse="inverse"
                    @click="$emit('back')"
                />
                <OrqButton
                    size="compact"
                    :label="nextLabel ?? 'Siguiente'"
                    :disabled="disabled || !nextEnabled || loading"
                    appearance="primary"
                    :inverse="!nextEnabled || loading ? inverse : false"
                    :loading="loading"
                    :icon-name="loading ? 'refresh' : ''"
                    @click="$emit('next')"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { OrqButton } from '@digital/orquestra';

import { useI18n } from 'vue-i18n';

const { t } = useI18n();

import { EVENTS } from '@/consts';
import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();

const onClose = () => {
    rootEvents.emit(EVENTS.APV_CLOSE);
    rootEvents.emit(EVENTS.APV_TRANSFER_CLOSE);
};

defineProps({
    steps: {
        type: Number,
        default: 1,
    },
    nextEnabled: {
        type: Boolean,
        default: true,
    },
    nextLabel: {
        type: String,
        default: null,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    back: {
        type: Boolean,
        default: true,
    },
    exit: {
        type: Boolean,
        default: true,
    },
});

defineEmits(['cancel', 'next', 'back']);
</script>

<style lang="scss" scoped>
.footer {
    &__container {
        display: flex;
        flex-direction: column;
    }
    &__step-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    &__buttons {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }
    &__progress {
        margin-inline: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    &__cancel {
        cursor: pointer;
        text-decoration: underline;
    }
}

:deep(.orq-progress-indicator__bar__item--active--inverse) {
    background-color: var(--action-secondary-selected-inverse) !important;
}
</style>
