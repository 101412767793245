export const updateMetaTitle = ({ title, description }) => {
    /**
     * Here I'm changing the title and the description for SEO porposes. This takes info from
     * routes.js and changes the DOM title and metas.
     *
     * We also have changes per TYPE
     */
    const defaultMeta = {
        title: `BTG Pactual | Inversiones Digitales`,
        description: `Invierte en acciones, fondos, dólares y depósitos a plazo con el mayor banco de inversión de Latinoamérica.`,
    };

    // Title
    document
        .querySelectorAll(
            'meta[name="twitter:title"], meta[property="og:title"]'
        )
        .forEach((i) => {
            i.setAttribute('content', title || defaultMeta.title);
        });

    document.title = title || defaultMeta.title;

    // Description
    document
        .querySelectorAll(
            'meta[name=description], meta[name="twitter:description"], meta[property="og:description"]'
        )
        .forEach((i) => {
            i.setAttribute('content', description || defaultMeta.description);
        });
};
