<template>
    <MarketClosed v-bind="data[operation]" :title="t('transactions.fundClosedTitle')" />
</template>

<script setup>
import MarketClosed from '../Shared/MarketClosed.vue';
import { OPERATION } from '@/consts.js';

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { formatDate } from '@/utils/text.js';

import { storeToRefs } from 'pinia';
import { useInstrumentStore } from '@/_store/instrument.store.js';
const instrumentStore = useInstrumentStore();
const { isMarketOpen } = storeToRefs(instrumentStore);

const { t } = useI18n();

defineProps({
    operation: {
        type: String,
        default: OPERATION.BUY,
    },
});

const data = computed(() => ({
    [OPERATION.BUY]: {
        label: `${t('transactions.fundClosed', {
            transaction: t('transactions.contribute'),
        })}${
            'nextOpen' in isMarketOpen.value
                ? ` ${t('transactions.fundClosedDate', {
                      date: formatDate(isMarketOpen.value.nextOpen),
                  })}`
                : ''
        }`,
    },
    [OPERATION.SELL]: {
        label: `${t('transactions.fundClosed', {
            transaction: t('transactions.retrieve'),
        })}${
            'nextOpen' in isMarketOpen.value
                ? ` ${t('transactions.fundClosedDate', {
                      date: formatDate(isMarketOpen.value.nextOpen),
                  })}`
                : ''
        }`,
    },
}));
</script>


