<template>
    <OrqDrawer :is-open="isDrawerOpen" :inverse="isInverse">
        <APVAbono
            v-if="isDrawerOpen"
            :inverse="isInverse"
            @details="modal = true"
            @step="modal = false"
        />
    </OrqDrawer>

    <OrqModal
        style="z-index: 5000; max-height: 544px"
        :visible="modal"
        title="Productos fuera de perfil"
        :show-actions="false"
        :show-one-action="false"
        @on-close="modal = false"
    >
        <template #wrapper>
            <div class="detail__container">
                <div
                    v-for="(risk, riskIndex) in Object.values(RISK)
                        .slice()
                        .reverse()"
                    :key="riskIndex"
                >
                    <template v-if="risk.key in outOfProfile">
                        <div class="instrument-list__container">
                            <div class="instrument-list">
                                <div
                                    v-for="(
                                        movement, movementIndex
                                    ) in outOfProfile[risk.key]"
                                    :key="movementIndex"
                                    class="instrument"
                                >
                                    <div class="body-sm instrument__title">
                                        {{ movement.info.type?.nameType }}
                                    </div>
                                    <div class="body-baseline">
                                        {{
                                            movement?.info?.name === 'Moneda'
                                                ? 'Dólar'
                                                : movement?.info?.name
                                        }}
                                    </div>
                                </div>
                            </div>
                            <RiskBadge :risk="risk.key" />
                        </div>
                        <OrqDivider />
                    </template>
                </div>
            </div>
        </template>
    </OrqModal>
</template>

<script setup>
import { OrqDrawer, OrqModal } from '@digital/orquestra';
import RiskBadge from '@/components/RiskBadge/RiskBadge.vue';
import APVAbono from '@/components/transactional-funds/APVAbono.vue';
import { onUnmounted, ref } from 'vue';
import { useRootEvents } from '@/composables/useRootEvents';
const { addListener, removeListeners } = useRootEvents();
import { EVENTS } from '@/consts';

const isDrawerOpen = ref(false);
const isInverse = ref(false);

import { useApvStore } from '@/_store/apv.store.js';
const apvStore = useApvStore();
const { outOfProfile, mode } = storeToRefs(apvStore);

import { storeToRefs } from 'pinia';

import { RISK } from '@/consts.js';

const modal = ref(false);

addListener(EVENTS.APV_OPEN, (evt) => {
    isDrawerOpen.value = true;
    isInverse.value = evt.inverse ?? false;
    mode.value = evt.mode ?? 'normal';
    apvStore.updateCurrentItem(evt.instrument ?? []);
    apvStore.goToStep(evt.step);
    apvStore.getGiroEnabled();
    apvStore.getFintocEnabled();
});

addListener(EVENTS.APV_CLOSE, () => {
    isDrawerOpen.value = false;
});

onUnmounted(() => {
    removeListeners();
});
</script>

<style lang="scss" scoped>
.detail {
    &__container {
        max-height: 442px;
        overflow-y: auto;
        padding-right: 8px;
    }
}

.instrument-list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 16px 0px 16px 0px;
    }
}

.instrument {
    gap: 8px;

    &__type {
        line-height: 24px;
    }

    &__title {
        color: var(--emphasis-base-medium);
        line-height: 21px;
    }
}
</style>
