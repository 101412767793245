<template>
    <OrqDrawerBody>
        <template #header>
            <TransactionalHeader
                :title="title"
                :subtitle="subtitle"
                :inverse="inverse"
            />
        </template>

        <template #body>
            <OrqColumn :gap="48">
                <BodyAmount
                    :title="t('transactions.availableShares')"
                    :amount="balance.quantity"
                    currency="PLAIN"
                    :inverse="inverse"
                    hide-link
                />

                <SelectInvestment
                    :options="priceOptions"
                    :label="t('transactions.price')"
                    :inverse="inverse"
                    @selected="priceSelectHandler"
                >
                    <div
                        v-if="selectedPrice === PRICE_TYPES.PEAK"
                        class="price-label body-baseline"
                        :class="{
                            'text--emphasis-base-high': !inverse,
                            'text--emphasis-base-high-inverse': inverse,
                        }"
                    >
                        {{ t('transactions.peakLabel') }}
                        <OrqAmount
                            :amount="cuotaPrice"
                            vertical-align=""
                            :format="
                                item?.config?.currency === 'CLP'
                                    ? 'CLPEXT'
                                    : item?.config?.currency
                            "
                        />
                        <OrqTooltip
                            :label="t('transactions.peakTooltip')"
                            :inverse="inverse"
                        >
                            <OrqIconContainer
                                name="info"
                                :size="13"
                                :color="`emphasis-base-high${
                                    inverse ? '-inverse' : ''
                                }`"
                            />
                        </OrqTooltip>
                    </div>
                    <LimitPrice
                        v-if="selectedPrice === PRICE_TYPES.LIMIT"
                        v-model.number="inputPrice"
                        :available-balance="balance"
                        :currency="item?.config?.currency"
                        :error="priceError"
                        :valid-multiple="v$.price?.multipleOfValue?.$message"
                        :validator="v$.price.$errors"
                        :item="item"
                        :last-price="limitPrice"
                        :min-price="minPrice"
                        :max-price="maxPrice"
                        :ins-price="item?.insPrice"
                        :inverse="inverse"
                        @update:model-value="debouncedGetCommission()"
                    />
                </SelectInvestment>
                <BodySellAmount
                    v-model:amount.number="order.quantity"
                    v-model:mode="mode"
                    v-focus
                    :label="t('transactions.sharesToSell')"
                    :buttons="percentButtons"
                    :available-balance="balance.quantity"
                    currency="PLAIN"
                    :error-msg="amountErrorMsg"
                    :inverse="inverse"
                    @update:amount="debouncedGetCommission(true)"
                />
                <OrqNotificationFixed
                    v-if="v$.$errors[0]?.$validator === 'lessThanMaxInv'"
                    notification-type="fixed"
                    kind="info"
                    :label-size="12"
                    :label="
                        t('transactions.surpassMaxInfo', {
                            max: formatCurrency(
                                item?.config?.sellMaxAmount,
                                item?.config?.currency
                            ),
                        })
                    "
                />
                <InfoCard
                    v-if="commissionError"
                    :text="
                        t('transactions.infoLessThanCommission', {
                            amount: formatCurrency(
                                order.commission + order.iva,
                                item?.config?.currency
                            ),
                        })
                    "
                    :inverse="inverse"
                />
            </OrqColumn>
        </template>

        <template #footer>
            <TransactionalFooter
                :steps="totalSteps"
                :next-enabled="isValid && !commissionLoading"
                :loading="commissionLoading"
                :inverse="inverse"
                @next="nextStep()"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import { computed, ref } from 'vue';
import { debounce } from 'lodash';
import TransactionalHeader from '@/components/transactional-funds/TransactionalHeader.vue';
import TransactionalFooter from '@/components/transactional-funds/TransactionalFooter.vue';
import BodySellAmount from '@/components/transactional-funds/Shared/BodySellAmount.vue';
import LimitPrice from '@/components/transactional-funds/Shared/LimitPrice.vue';
import InfoCard from '@/components/transactional-funds/Shared/InfoCard.vue';
import SelectInvestment from '@/components/transactional-funds/Shared/SelectInvestment.vue';
import BodyAmount from '@/components/transactional-funds/body-components/BodyAmount.vue';
import {
    OrqDrawerBody,
    OrqColumn,
    OrqAmount,
    OrqTooltip,
    OrqNotificationFixed,
    OrqIconContainer,
} from '@digital/orquestra';
import { TYPES_DATA, PRICE_TYPES } from '@/consts';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useVuelidate } from '@vuelidate/core';
import { formatCurrency } from '@/directives/currencyFormat';
import {
    nonZero,
    minValue,
    maxValue,
    multipleOfValue,
} from '@/_helpers/rules.helper.js';

import { OPERATION, MARKET, SELL_MODES, PRICES } from '@/consts';

import { useOrderStore } from '@/_store/order.store.js';
const orderStore = useOrderStore();
const { order, totalSteps, commissionLoading } = storeToRefs(orderStore);
const { initOrder, nextStep, getCommissionByQuantity } = orderStore;

import { useInstrumentStore } from '@/_store/instrument.store.js';
const instrumentStore = useInstrumentStore();
const { balance } = storeToRefs(instrumentStore);

const props = defineProps({
    item: {
        type: Object,
        default: () => ({}),
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

const mode = ref(SELL_MODES.AMOUNT);

const { t } = useI18n();

const title = computed(() => {
    return props.item?.info?.symbol;
});

const subtitle = computed(() => {
    return TYPES_DATA[props.item?.info?.type?.id]?.name ?? '';
});

const cuotaPrice = computed(() => {
    return props.item?.insPrice?.buyPrice ?? props.item?.insPrice?.price;
});
const limitPrice = ref(props.item?.insPrice?.price);

const priceOptions = ref(['peak', 'limit']);
const selectedPrice = ref(0);
const priceSelectHandler = (selection) => {
    v$.value?.price?.$reset();
    selectedPrice.value = selection;
    order.value.selectedPrice = priceOptions.value[selection];
};

const inputPrice = ref(0); // This is the inputed price by the user when OTHER is selected. Range 5%
const price = computed(() => {
    return selectedPrice.value === PRICE_TYPES.PEAK
        ? cuotaPrice.value
        : inputPrice.value;
});

const percentButtons = computed(() => [
    {
        label: t('transactions.percentSell', { percent: '100%' }),
        percent: 100,
    },
]);

// Puntas
const priceRange = computed(
    () => (limitPrice.value * PRICES.LIMIT_PERCENT) / 100
);
const minPrice = computed(() => limitPrice.value - priceRange.value);
const maxPrice = computed(() => limitPrice.value + priceRange.value);

const debouncedGetCommission = debounce((touchAmount = false) => {
    v$.value.price?.$touch();
    if (touchAmount) {
        v$.value.amount?.$touch();
        v$.value.quantity?.$touch();
    }

    order.value.price = price.value;

    getCommissionByQuantity({
        quantity: order.value?.quantity,
        operation: OPERATION.SELL,
    });
}, 500);

const totalCommission = computed(
    () => (order.value?.commission ?? 0) + (order.value?.iva ?? 0)
);

const rules = computed(() => {
    const amountRules = {
        moreThanCommission: minValue(
            totalCommission.value,
            t('transactions.lessThanCommission')
        ),
        ...(props.item?.config?.invMaxAmount > 0
            ? {
                  lessThanMaxInv: maxValue(
                      props.item?.config?.invMaxAmount,
                      t('transactions.surpassMax')
                  ),
              }
            : {}),
        nonZero: nonZero(t('transactions.lessThanMinSell')),
    };
    const quantityRules = {
        lessThanQuantity: maxValue(
            balance.value?.quantity,
            t('transactions.moreThanAmountSell')
        ),
    };
    const priceRules = {
        nonZero: nonZero(),
        lessThanMax: maxValue(maxPrice.value),
        moreThanMin: minValue(minPrice.value),
        multipleOfValue: multipleOfValue(order.value.price),
    };

    return {
        amount: amountRules,
        quantity: quantityRules,
        ...(selectedPrice.value === PRICE_TYPES.LIMIT
            ? { price: priceRules }
            : {}),
    };
});

const v$ = useVuelidate(rules, order);

const amountErrorMsg = computed(
    () =>
        v$.value?.amount?.$errors.concat(v$.value?.quantity?.$errors)[0]
            ?.$message ?? null
);

const priceError = computed(() => v$.value.price?.$error ?? false);

const commissionError = computed(
    () =>
        !!v$.value.amount?.$errors.find(
            (error) => error?.$validator === 'moreThanCommission'
        )
);

const isValid = computed(
    () => v$.value.$dirty && v$.value.$errors.length === 0
);

const currency = computed(() => {
    return !props.item?.config?.multiCurrency &&
        props.item?.config?.currency === 'USD'
        ? 'USD'
        : 'CLP';
});

initOrder({
    idEncoded: props.item.idEncoded,
    amount: 0,
    quantity: 0,
    market: MARKET.NAV,
    operation: OPERATION.SELL,
    currency: currency.value,
});
</script>

<style lang="scss" scoped>
.switch-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.switch-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}
</style>
