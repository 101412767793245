import { useAuthStore } from '@/_store/auth.store.js';
import { useUserStore } from '@/_store/user.store.js';
import { useAccountsStore } from '@/_store/accounts.store.js';
import { useInstrumentStore } from '@/_store/instrument.store.js';
import { useContractsStore } from '@/_store/contracts.store.js';
import { useAgencyStore } from '@/_store/agency.store.js';

import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useTagStore } from '@/_store/tags.store.js';
import { useSlugs } from '@/composables/useSlugs.js';

export const useInitApp = () => {
    // Login.
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const accountStore = useAccountsStore();
    const instrumentStore = useInstrumentStore();
    const contractsStore = useContractsStore();

    const route = useRoute();
    const router = useRouter();

    const { isLoggedIn, token } = storeToRefs(authStore);
    const { user } = storeToRefs(userStore);

    const loading = ref(true);

    const renewUser = async () => {
        await accountStore.getAccounts();
        await accountStore.getExecutive();
        if (user.value.btg) await contractsStore.getPendingContracts();
    };

    const renewToken = async () => {
        if (!route.meta.requiresAuth && !isLoggedIn.value) {
            loading.value = false;
            return Promise.reject('INFO: Not renewing token');
        }

        try {
            if (isLoggedIn.value) {
                loading.value = true;
                await authStore.login({
                    token: token.value,
                });
                await userStore.getUser();
                await renewUser();
            }
        } catch (e) {
            router.push('/login').then(() => {
                loading.value = false;
            });
            return Promise.reject(e);
        }
    };

    onMounted(async () => {
        // Calling all the dependences needed to start
        const classes = instrumentStore.getClasses();
        const markets = instrumentStore.getMarkets();
        const tags = useTagStore().fetch();
        const slugs = useSlugs().install();

        await Promise.allSettled([classes, markets, tags, slugs]);
        await router.isReady();

        const depersonalized = await checkDepersonalization();

        try {
            if (!depersonalized) await renewToken();
        } catch (error) {
            console.warn(error);
        }
        loading.value = false;
    });

    // ----
    // These are all related to depersonalization.
    // ----
    const agencyStore = useAgencyStore();
    const { depersonalize } = agencyStore;

    const channel = new BroadcastChannel('depersonalization');

    // This checks if "agent" query parm is present in the URL.
    const checkDepersonalization = async () => {
        // Query param is called 'data' because of agent web constraints.
        // Data represents agent token.
        loading.value = true;
        const query = route.query;
        if (query.data) {
            channel.postMessage(query.data);
            try {
                await depersonalize(query.data);
                // This renews user on this tab.
                await renewUser();
            } finally {
                loading.value = false;
            }
        }
        return 'data' in query;
    };

    // This receives renewal broadcasts from other tab.
    channel.onmessage = async (event) => {
        loading.value = true;
        try {
            await depersonalize(event.data);
            await renewUser();
        } finally {
            loading.value = false;
        }
    };

    return {
        loading,
    };
};
