import { defineStore } from 'pinia';
import currencyService from '@/_services/currency.service';
import { errorHandler } from '@/_services/errorHandler';
import { ref } from 'vue';

/**
 * Important: We are using 'Setup Store' definition.
 * @see https://pinia.vuejs.org/core-concepts/#setup-stores
 *
 * ref()s become state properties
 * computed()s become getters
 * function()s become actions
 *
 */
export const useCurrencyStore = defineStore('currency', () => {
    const UFPrice = ref(null);

    const fetchCurrency = async () => {
        try {
            if (UFPrice.value) return;
            const res = await currencyService.getCurrencyPrice('UF');
            UFPrice.value = parseInt(res.data.uf);
        } catch (error) {
            errorHandler(error);
        }
    };

    const USDRate = ref({});

    const fetchUSDRate = async (operation) => {
        const res = await currencyService.getUSDRate(operation);
        if (res.data) {
            USDRate.value = res.data;
        }
        return res.data;
    };

    return {
        UFPrice,
        fetchCurrency,
        USDRate,
        fetchUSDRate,
    };
});
