import api from './api.js';

export default {
    /** Retorna notificaciones de usuario */
    getAll() {
        const path = `notification`;
        return api.get(path, { data: null });
    },
    deleteAll() {
        const path = `notification`;
        return api.delete(path, { data: null });
    },
    readAll() {
        const path = `notification`;
        // Note: We are sending "true" as string because is required. Otherwise we are receiving a 500 response
        return api.put(path, { state: 'true' });
    },
    readItem({ id }) {
        const path = `notification/${id}`;
        // Note: We are sending "true" as string because is required. Otherwise we are receiving a 500 response
        return api.put(path, { state: 'true' });
    }
};
