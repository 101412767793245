<template>
    <OrqDrawer
        :is-open="isDrawerOpen"
        :inverse="isInverse"
        @close="isDrawerOpen = false"
    >
        <template v-if="!abonarEnabled">
            <AbonarNotAvailable />
        </template>
        <template v-else-if="transactionStatus">
            <AbonarBotonDePagoSuccess
                v-if="transactionStatus === 'ok'"
                :inverse="isInverse"
            />
            <AbonarBotonDePagoError
                v-if="transactionStatus === 'error'"
                :inverse="isInverse"
            />
        </template>
        <template v-else>
            <template v-if="accountSelectedLoading || statusLoading">
                <AbonarLoading :inverse="isInverse" />
            </template>
            <template v-else>
                <component :is="components[currentStep]" :inverse="isInverse" />
            </template>
        </template>
    </OrqDrawer>
</template>

<script setup>
import { OrqDrawer } from '@digital/orquestra';

// Components
import AbonarLoading from './AbonarLoading.vue';
import AbonarAccounts from './AbonarAccounts.vue';
import AbonarSelectMethod from './AbonarSelectMethod.vue';
import AbonarTransfer from './AbonarTransfer.vue';
import AbonarBotonDePago from './AbonarBotonDePago.vue';
import AbonarNotAvailable from './AbonarNotAvailable.vue';
import MaintenanceMode from '@/components/transactional-funds/Shared/MaintenanceMode.vue';

import { useAbonarStore } from '@/_store/abonar.store';
import { storeToRefs } from 'pinia';
const abonarStore = useAbonarStore();
const {
    currentStep,
    methodSelected,
    accountSelectedLoading,
    shouldSkipFirstStep,
} = storeToRefs(abonarStore);

import { useStatusStore } from '@/_store/status.store';
const statusStore = useStatusStore();
const { canTrans, loading: statusLoading } = storeToRefs(statusStore);

import { useAgencyStore } from '@/_store/agency.store.js';
const agencyStore = useAgencyStore();
const { agent } = storeToRefs(agencyStore);

const components = computed(() => {
    if (!canTrans.value) {
        return [MaintenanceMode];
    }
    const mustIncludeSteps = [
        AbonarSelectMethod,
        (() => {
            if (methodSelected.value === 0) {
                return AbonarTransfer;
            }
            return AbonarBotonDePago;
        })(),
    ];
    if (shouldSkipFirstStep.value) {
        return mustIncludeSteps;
    }
    return [AbonarAccounts, ...mustIncludeSteps];
});

import { EVENTS, ACCOUNT_TYPE } from '@/consts';
import { useRootEvents } from '@/composables/useRootEvents';
import { computed, onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const { addListener } = useRootEvents();
const isDrawerOpen = ref(false);
const route = useRoute();

import { useAccountsStore } from '@/_store/accounts.store.js';
const accountsStore = useAccountsStore();
const { currentAccount } = storeToRefs(accountsStore);

addListener(EVENTS.ABONAR_OPEN, (evt) => {
    statusStore.fetchServerStatus();
    // Init Abonar
    abonarStore.$reset();
    if (!evt.skipDefault) {
        abonarStore.getAccountSelected();
    }
    const shouldBeDark = route.path.includes('mercados-en-linea');
    isInverse.value = shouldBeDark;
    isDrawerOpen.value = true;
});

addListener(EVENTS.ABONAR_CLOSE, () => {
    isDrawerOpen.value = false;
    transactionStatus.value = null;
});

const router = useRouter();

// Estimado reviewer, mira lo que me enseño un roto
const shouldBeDark = route.name === 'mercados-en-linea';
const isInverse = ref(shouldBeDark);

import AbonarBotonDePagoError from './AbonarBotonDePagoError.vue';
import AbonarBotonDePagoSuccess from './AbonarBotonDePagoSuccess.vue';

const transactionStatus = ref(null);
onMounted(async () => {
    await router.isReady();
    if (['ok', 'error'].includes(route.query.transaccion)) {
        isDrawerOpen.value = true;
        transactionStatus.value = route.query.transaccion;
        router.replace({ query: {} });
    }
});

const abonarEnabled = computed(() => {
    return (
        currentAccount.value?.tipoCuenta === ACCOUNT_TYPE.INVESTMENT &&
        !agent.value
    );
});
</script>
