<template>
    <OrqDrawerBody>
        <template #header>
            <OrqColumn :gap="16">
                <div class="heading-md">{{ t('withdraw.title') }}</div>
                <div class="heading-lg">
                    <div>Retiro realizado</div>
                    <OrqAmount
                        class="text--action-main-enabled"
                        :amount="amount"
                        :format="currency"
                    />
                </div>
            </OrqColumn>
        </template>

        <template #body>
            <OrqColumn :gap="16">
                <OrqColumn :gap="8">
                    <OrqFlex style="justify-content: space-between">
                        <div class="body-sm text--emphasis-base-medium">
                            Fecha de ingreso
                        </div>
                        <div class="body-sm text--emphasis-base-medium">
                            {{ formatDate(date, 'today') }}
                        </div>
                    </OrqFlex>
                    <OrqFlex style="justify-content: space-between">
                        <div class="body-sm text--emphasis-base-medium">
                            Cuenta destino
                        </div>
                        <div
                            class="body-sm text--emphasis-base-medium trim text-right"
                        >
                            {{ bankSelected.name }}
                        </div>
                    </OrqFlex>
                </OrqColumn>
                <div class="body-sm text--emphasis-base-medium">
                    Pronto recibirás un comprobante del retiro en tu correo
                    electrónico.
                </div>
            </OrqColumn>
        </template>

        <template #footer>
            <RetirarFooter
                next-label="Cerrar"
                :show-cancel="false"
                @next="onClose"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import {
    OrqDrawerBody,
    OrqColumn,
    OrqFlex,
    OrqAmount,
} from '@digital/orquestra';
import { formatDate } from '@/utils/text.js';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

import RetirarFooter from './shared/RetirarFooter.vue';
import { computed } from 'vue';

const date = computed(() => new Date());

// Abonar store
import { useRetirarStore } from '@/_store/retirar.store';
import { storeToRefs } from 'pinia';
const retirarStore = useRetirarStore();
const { bankSelected, amount, currency } = storeToRefs(retirarStore);

import { EVENTS } from '@/consts';
import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();

const onClose = () => {
    rootEvents.emit(EVENTS.RETIRAR_CLOSE);
};
</script>

<style lang="scss" scoped>
.trim {
    max-width: 200px;
    display: block;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    width: 100%;
    overflow: hidden;
}
.text-right {
    text-align: right;
}
</style>
