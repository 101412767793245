import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import { ref, computed, onMounted, onUnmounted, watch, nextTick } from 'vue';
import { EVENTS } from '@/consts';
import { useRootEvents } from '@/composables/useRootEvents';
import { useUserStore } from '@/_store/user.store';
import { useBalanceStore } from '@/_store/balance.store';
import { usePortfolioStore } from '@/_store/portfolio.store';
import { useNotificationsStore } from '@/_store/notifications.store';
import { storeToRefs } from 'pinia';

export const useNotifications = () => {
    const rootEvents = useRootEvents();
    const userStore = useUserStore();
    const notificationsStore = useNotificationsStore();
    const balanceStore = useBalanceStore();
    const portfolioStore = usePortfolioStore();
    const { user } = storeToRefs(userStore);
    const { notifications } = storeToRefs(notificationsStore);

    const ws = ref(null);
    const message = ref({});

    const path = computed(() => {
        return `/user/${user.value.rut}/queue/notification`;
    });
    // Get URL
    const wsURL = import.meta.env.VITE_APP_API_URL;
    
    const unsubscribe = () => {
        try {
            ws.value.unsubscribe('payment');
        } catch (e) {
            console.warn("Couldn't unsubscribe payment WS", e);
        }
    };

    const subscribe = () => {
        if (!user.value.rut) {
            return;
        }
        const fullUrl = `${wsURL}/notifications/`;

        ws.value = new Client({
          reconnectDelay: 5000
        });
  
        ws.value.webSocketFactory = () => {
          return new SockJS(fullUrl);
        };
  
        ws.value.onConnect = () => {
            ws.value.subscribe(
                path.value,
                r => {
                    /**
                     * We are getting a String response like this
                     * {"id":0,"title":"Notificacion","description":"String","notificationDate":1668700846.998000000,"notificationReadDate":null,"read":false}
                     * So we need to parse it twice to get the description
                     */
                    const notification = JSON.parse(r.body);
                    rootEvents.emit(EVENTS.PAYMENT, notification);
                },
                { id: 'payment' },
            );
        };
        ws.value.activate();
    };

    const $reset = () => {
        unsubscribe();
        ws.value = null;
        message.value = {};
    };

    onMounted(subscribe);
    onUnmounted($reset);
    watch(() => user.value.rut, async () => {
        await nextTick();
        $reset();
        subscribe();
    });

    rootEvents.addListener(EVENTS.PAYMENT, (e) => {
        notify(e);
    })

    const notify = (notification = {}) => {
        const notificationInMilliseconds = {
            ...notification,
            notificationDate: notification.notificationDate * 1000,
        };
        notifications.value = [...notifications.value, notificationInMilliseconds];
  
        // Updating the balance after getting a notification
        balanceStore.getBalance();
        portfolioStore.getPortfolio();
    }
};
