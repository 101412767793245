import { useUserStatusStore } from '@/_store/userStatus.store';
import { useToast } from '@/composables/useToast';
import { useIntercomChat } from '@/composables/useIntercom';
import { storeToRefs } from 'pinia';

export const useBlockedAccount = () => {
    const { popIntercom } = useIntercomChat();
    const toast = useToast();
    const userStatusStore = useUserStatusStore();
    const { isBlocked } = storeToRefs(userStatusStore);

    const popBlockedAccount = () => {
        if (isBlocked.value) {
            toast.toasty({
                title: 'Cuenta bloqueada',
                message:
                    'Tu cuenta está bloqueada. Para más información, contáctanos por el chat.',
                kind: 'warning',
                link: 'Ir al chat',
                callback: () => popIntercom(),
            });
        }
    };

    return {
        popBlockedAccount,
    };
};
