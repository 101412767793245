<template>
    <OrqDrawerBody>
        <template #body>
            <div class="drawer_content">
                <div class="drawer_content__header bg--primary-extended-80">
                    <OrqColumn class="drawer_content__container" :gap="16">
                        <span
                            class="text--emphasis-base-high-inverse heading-xl"
                        >
                            {{ title }}
                        </span>
                        <span
                            class="text--emphasis-base-medium-inverse body-baseline"
                        >
                            {{ subtitle }}
                        </span>
                    </OrqColumn>
                </div>
                <div class="drawer_content__body">
                    <OrqColumn :gap="16">
                        <template v-if="depositMethods.length == 0">
                            <img
                                style="margin-top: 16px"
                                src="https://static.btgpactual.cl/apps/apv/apv-empty.svg"
                                height="48"
                                width="48"
                            />
                            <span
                                class="title--title-sm text-emphasis-base-high"
                            >
                                No hay mecanismos de abono APV activos
                            </span>
                            <span
                                class="body-baseline text-emphasis-base-medium"
                            >
                                Tu lista de aportes está guardada. Inténtalo
                                nuevamente más tarde o
                                <a @click="openIntercom"
                                    >Contáctanos por el chat</a
                                >.
                            </span>
                        </template>
                        <template v-else>
                            <OrqRadioButtonGroup
                                v-model="selectedDepositMethod"
                                :items="depositMethods"
                                :inverse="inverse"
                            >
                                <template #extra="{ index }">
                                    <template
                                        v-if="depositMethods[index].extra"
                                    >
                                        <div
                                            style="
                                                display: flex;
                                                align-items: center;
                                                gap: 8px;
                                            "
                                        >
                                            <OrqIconContainer
                                                name="info-on"
                                                :size="13"
                                                color="status-informative"
                                            />
                                            <span
                                                class="caption-xs text--emphasis-base-medium"
                                            >
                                                {{
                                                    depositMethods[index].extra
                                                }}
                                            </span>
                                        </div>
                                    </template>
                                </template>
                            </OrqRadioButtonGroup>
                        </template>
                    </OrqColumn>
                </div>
            </div>
        </template>

        <template #footer>
            <div class="footer_content">
                <OrqDivider />
                <div class="footer_content__body">
                    <TransactionalFooter
                        :next-enabled="selectedDepositMethod != -1"
                        :inverse="inverse"
                        :loading="loadingCreateAPV"
                        back
                        @next="nextStep()"
                        @cancel="emit('cancel')"
                        @back="apvStore.goToStep('selectRegime')"
                    />
                </div>
            </div>
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import {
    OrqColumn,
    OrqDrawerBody,
    OrqIconContainer,
    OrqRadioButtonGroup,
    OrqDivider,
} from '@digital/orquestra';
import TransactionalFooter from './TransactionalFooter.vue';

import { storeToRefs } from 'pinia';

import { useApvStore } from '@/_store/apv.store.js';

const apvStore = useApvStore();
const {
    selectedDepositMethod: storeSelectedDepositMethod,
    depositMethods,
    loadingCreateAPV,
} = storeToRefs(apvStore);
const { nextStep: storeNextStep } = apvStore;

import { computed, nextTick } from 'vue';

const selectedDepositMethod = computed({
    get() {
        return depositMethods.value.findIndex(
            (item) => item.value == storeSelectedDepositMethod.value
        );
    },
    set(newValue) {
        storeSelectedDepositMethod.value = depositMethods.value[newValue].value;
    },
});

import { APV_DEPOSIT_METHODS } from '@/consts';
const nextStep = async () => {
    if (storeSelectedDepositMethod.value == APV_DEPOSIT_METHODS.FINTOC) {
        await apvStore.createAPV(true);
        await nextTick();
    }
    storeNextStep();
};

const emit = defineEmits(['cancel']);

defineProps({
    title: {
        type: String,
        default: '',
    },
    subtitle: {
        type: String,
        default: '',
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

import { EVENTS } from '@/consts';
import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();
const openIntercom = () => {
    rootEvents.emit(EVENTS.INTERCOM_OPEN_SPACE, { space: 'help' });
};
</script>

<style lang="scss" scoped>
.drawer_content {
    margin-left: -48px;
    margin-right: -48px;

    &__container {
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 48px;
    }
    &__header {
        padding: 40px 0;
    }
    &__body {
        padding: 32px 48px;
    }
}

:deep(.title--title-md) {
    font-size: 20px;
}

.footer_content {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1008;

    &__body {
        padding: 24px 48px 24px 48px;
    }
}
</style>
