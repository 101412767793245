<template>
    <div class="timer__container" :class="{ inverse }">
        <div class="timer__labels">
            <OrqIconContainer
                name="timer"
                :size="16"
                :color="`emphasis-base-high${inverse ? '-inverse' : ''}`"
            />
            <span
                v-if="title"
                :class="`body-sm text--emphasis-base-high${
                    inverse ? '-inverse' : ''
                }`"
                >{{ title }}</span
            >
            <OrqAmount
                v-if="amount"
                :class="`body-sm text--emphasis-base-medium${
                    inverse ? '-inverse' : ''
                }`"
                :amount="amount"
                :format="format"
            />
        </div>
        <div class="timer__bar">
            <span
                :class="`caption-2xs text--emphasis-base-medium${
                    inverse ? '-inverse' : ''
                }`"
                >{{
                    t('timer.secondsLeft', {
                        time: maxSeconds - timersStore.getSeconds(timerId),
                    })
                }}</span
            >
            <div class="bar__container">
                <div
                    class="bg--informative"
                    :style="`height: 100%; width: ${timersStore.getPercent(
                        timerId
                    )}%;`"
                ></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { OrqIconContainer, OrqAmount } from '@digital/orquestra';

import { useTimersStore } from '@/_store/timers.store.js';

import { onMounted, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const timersStore = useTimersStore();

const props = defineProps({
    title: {
        type: String,
        default: null,
    },
    amount: {
        type: Number,
        default: 0,
    },
    format: {
        type: String,
        default: null,
    },
    timerId: {
        type: String,
        default: 'detailed',
    },
    maxSeconds: {
        type: Number,
        default: 60,
    },
    ticksPerSecond: {
        type: Number,
        default: 10,
    },
    reversed: {
        type: Boolean,
        default: false,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['timeUp']);

onMounted(() => {
    timersStore.setTimer(
        props.timerId,
        props.maxSeconds,
        props.maxSeconds,
        props.reversed
    );
});

const timeUp = computed(() => timersStore.timeUp(props.timerId));

watch(timeUp, () => {
    if (timeUp.value) {
        emit('timeUp');
    }
});
</script>

<style lang="scss" scoped>
.timer {
    &__container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: fit-content;
    }
    &__labels {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
    }
    &__bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 8px;
        align-items: center;
    }
}
.bar {
    &__container {
        height: 4px;
        width: 56px;
        border-radius: 100px;
        overflow: hidden;
        background-color: var(--neutral-extended-40);

        .bg--informative {
            background-color: var(--status-informative-base);
        }
    }
}

.inverse {
    .bar {
        &__container {
            background-color: var(--neutral-extended-70);
        }
        .bg--informative {
            background-color: var(--status-informative-base-inverse);
        }
    }
}
</style>
