import { TYPES, TYPES_DATA, OPERATION } from '@/consts';
import { normalizeArchitecture, normalizeWindow } from './architecture.helper';
import { normalizeSymbol, normalizeFlag } from './icons.helper';
import { getValueOrDefault } from '@/utils/ternaryConditional.js';

import { i18n } from '@/i18n';
const { t } = i18n.global;
/**
 * We are normalizing instrument because we have some inconsistencies
 * with data.
 */

export const normalizeInstrument = (item) => {
    const normalizedItem = {
        ...item,
        insPrice: {
            ...item.insPrice,
            accumulatedVolumeMoneyMM:
                item.insPrice?.accumulatedVolumeMoney / 1000000,
        },
        architecture: normalizeArchitecture({
            ...item.config,
            type: getValueOrDefault(
                item.info.type,
                item.info.type.id,
                TYPES.ACCION
            ),
        }),
        nextWindow: {
            buy: normalizeWindow({ operation: OPERATION.BUY, item }),
            sell: normalizeWindow({ operation: OPERATION.SELL, item }),
        },
        idEncoded: window.btoa(item.info.idCode), // Required to make request
        mirrorInsEncoded: window.btoa(item.config.mirrorIns), // Used in AA. NOTE that btoa(null) != null.
        config: {
            ...item.config,
            currency: (() => {
                /**
                 * We force Moneda to CLPEXT
                 * because we want USD to display as CLP ie: $800 not US$800
                 */
                if (item.info.type?.id === TYPES.MONEDA) {
                    return 'CLPEXT';
                }

                let currencies = {
                    'Peso Chileno': 'CLP',
                    Dolar: 'USD',
                };

                return currencies[item.config.currency]
                    ? currencies[item.config.currency]
                    : item.config.currency;
            })(),
        },
        info: (() => {
            const info = {
                ...item.info,
                type: item.info?.type || {
                    id: TYPES.ACCION,
                    nameType: 'Accion',
                },
                symbolURL: normalizeSymbol(item.info),
                flagURL: normalizeFlag(item.info),
            };
            return info;
        })(),
        meta: ((item) => {
            let title = getValueOrDefault(
                item.info.type,
                TYPES_DATA[item.info.type.id].title
            );
            let description = getValueOrDefault(
                item.info.type,
                TYPES_DATA[item.info.type.id].description
            );

            switch (
                getValueOrDefault(
                    item.info.type,
                    item.info.type.id,
                    TYPES.ACCION
                )
            ) {
                case TYPES.ACCION:
                    title = t(title, {
                        nemo: item.info.symbol,
                    });
                    description = t(description, { nemo: item.info.symbol });
                    break;

                case TYPES.FONDO_DE_INVERSION:
                    title = t(title, {
                        nemo: item.info.symbol,
                        name: getValueOrDefault(
                            item.info.name,
                            item.info.name,
                            item.info.shortName
                        ),
                    });
                    description = t(description, {
                        manager: item.config.manager || 'BTG Pactual',
                    });

                    break;
                case TYPES.FONDO_MUTUO:
                case TYPES.FONDO_MUTUO_INTERNACIONAL:
                case TYPES.FONDO_INTERNACIONAL:
                    title = t(title, {
                        name: getValueOrDefault(
                            item.info.shortName,
                            item.info.shortName,
                            item.info.name
                        ),
                    });
                    description = t(description, {
                        manager: item.config.manager || 'BTG Pactual',
                    });

                    break;
            }
            return {
                title,
                description,
            };
        })(item),
    };
    return normalizedItem;
};

export const parseRemuneration = (remunerationString) => {
    return parseFloat(remunerationString.replace(/,/g, '.'));
};
