import { defineStore, storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import bankAccountsService from '@/_services/bankAccounts.service.js';

import { useBalanceStore } from './balance.store';

import { useTokenStore } from '@/_store/token.store.js';
import { useAccountsStore } from '@/_store/accounts.store.js';

import RetirarAccounts from '@/components/Retirar/RetirarAccounts.vue';
import RetirarUSDTerms from '@/components/Retirar/RetirarUSDTerms.vue';
import RetirarUSD from '@/components/Retirar/RetirarUSD.vue';
import RetirarCLP from '@/components/Retirar/RetirarCLP.vue';
import RetirarSuccess from '@/components/Retirar/RetirarSuccess.vue';
import RetirarError from '@/components/Retirar/RetirarError.vue';
import { usePortfolioStore } from '@/_store/portfolio.store.js';

import { RESPONSES_STATUS } from '@/consts';

export const useRetirarStore = defineStore('retirar', () => {
    const portfolioStore = usePortfolioStore();
    const currentStep = ref(0);

    const currency = ref('CLP');
    const amount = ref(0);
    const bankSelected = ref({});
    const bankAccountsForGiro = ref([]);
    const postRetiroError = ref(null);

    const components = computed(
        () =>
            [
                RetirarAccounts,
                (() => (currency.value === 'USD' ? RetirarUSDTerms : null))(),
                (() => (currency.value === 'USD' ? RetirarUSD : RetirarCLP))(),
                (() =>
                    postRetiroError.value ? RetirarError : RetirarSuccess)(),
            ].filter((n) => n) // Remove empty
    );

    const maxSteps = computed(() => components.value.length);

    const nextStep = () => {
        if (currentStep.value < maxSteps.value - 1) {
            currentStep.value++;
        }
    };

    const getBankAccountsForGiroLoading = ref(false);
    const getBankAccountsForGiro = async () => {
        getBankAccountsForGiroLoading.value = true;
        try {
            bankAccountsForGiro.value = [];
            const getAccounts = (currency) => {
                return bankAccountsService
                    .getBankAccountsForGiro({ currency })
                    .then((r) => {
                        if (r.status === 200) {
                            bankAccountsForGiro.value = [
                                ...bankAccountsForGiro.value,
                                ...r.data,
                            ];
                        }
                    });
            };
            await Promise.all([getAccounts('CLP'), getAccounts('USD')]);

            bankAccountsForGiro.value.sort((a, b) => {
                return (
                    a.currency.localeCompare(b.currency) ||
                    a.id.localeCompare(b.id)
                );
            });
        } catch (error) {
            console.log('Error loading bankAccountsForGiro');
        }
        getBankAccountsForGiroLoading.value = false;
    };

    const minAmountForGiro = ref({
        CLP: 0,
        USD: 0,
    });
    const getMinAmountForGiro = () => {
        const getMin = (currency) => {
            return bankAccountsService
                .fetchMinWithdrawalAmount({ currency })
                .then((r) => (minAmountForGiro.value[currency] = r.data));
        };
        return Promise.all([getMin('CLP'), getMin('USD')]);
    };

    const postRetiroLoading = ref(false);
    const postRetiro = async () => {
        const {
            token,
            timer,
            error: tokenError,
        } = storeToRefs(useTokenStore());
        const accountStore = useAccountsStore();

        const payload = {
            account: parseInt(accountStore.selected),
            amount: parseInt(amount.value),
            bank: bankSelected.value.id,
            bankName: bankSelected.value.name,
            currency: currency.value,
            secondGenerateToken: timer.value,
            tokenTrx: token.value,
        };

        postRetiroLoading.value = true;
        return new Promise((resolve, reject) => {
            bankAccountsService
                .sendWithdrawal(payload)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    const { response } = error;
                    if (response?.status === 400) {
                        tokenError.value = RESPONSES_STATUS.TOKEN_ERROR;
                    } else {
                        postRetiroError.value = true;
                        reject();
                    }
                })
                .finally(async () => {
                    // Get new portfolio values
                    await portfolioStore.getPortfolio();
                    const balanceStore = useBalanceStore();
                    balanceStore.getBalance();
                    postRetiroLoading.value = false;
                });
        });
    };

    const $reset = () => {
        currentStep.value = 0;
        bankAccountsForGiro.value = [];
        currency.value = 'CLP';
        postRetiroError.value = null;
    };

    return {
        currentStep,
        maxSteps,
        nextStep,
        bankSelected,
        bankAccountsForGiro,
        getBankAccountsForGiro,
        getBankAccountsForGiroLoading,
        currency,
        components,
        amount,
        postRetiroError,
        postRetiro,
        postRetiroLoading,
        minAmountForGiro,
        getMinAmountForGiro,
        $reset,
    };
});
