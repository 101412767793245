import { ARCHITECTURE, OPERATION, TYPES } from '@/consts';

/**
 * By "architecture"
 */

export const normalizeArchitecture = ({ nav, aaEnabled, mirrorIns, type, bolsa }) => {
    if (type !== TYPES.FONDO_DE_INVERSION && type !== TYPES.FONDO_MUTUO) {
        return null;
    }

    const isNav =
        nav === true &&
        aaEnabled === true &&
        (mirrorIns === null || mirrorIns === '');

    const isBolsa = nav === false && aaEnabled === false && (type === TYPES.FONDO_MUTUO ? bolsa : true);

    const isNavBolsa = aaEnabled === true && !!mirrorIns;

    if (isNav) return ARCHITECTURE.NAV;
    if (isBolsa) return ARCHITECTURE.BOLSA;
    if (isNavBolsa) return ARCHITECTURE.NAV_Y_BOLSA;

    return ARCHITECTURE.NAV;
};

export const normalizeWindow = ({ operation = OPERATION.BUY, item }) => {
    // This should be limited to NAV
    if (
        ![ARCHITECTURE.NAV, ARCHITECTURE.NAV_Y_BOLSA].includes(
            normalizeArchitecture({
                ...item.config,
                type: item.info.type ? item.info.type.id : TYPES.ACCION,
            })
        )
    ) {
        return;
    }

    // Do we have windows? if not, early return.
    if (!item.config.windows?.length) {
        return;
    }

    // Deep cloning the element bc we don't want to change the original one
    const _item = JSON.parse(JSON.stringify(item));

    // Find the next window and add a flag for daily windows
    const windows = _item.config.windows;
    const _windows = windows
        .map((i) => {
            i.daily = i.description === 'Diaria';
            i.openDate = i.openDate * 1000; // We need to convert to ms
            i.closeDate = i.closeDate * 1000; // We need to convert to ms
            return i;
        })
        .filter((i) => i.side === operation);

    const nextWindow = _windows[0];

    return nextWindow || undefined;
};

export const normalizeArchitectureWithACC = ({
    nav,
    aaEnabled,
    mirrorIns,
    type,
    bolsa,
}) => {
    if (type === TYPES.ACCION) {
        return ARCHITECTURE.BOLSA;
    }
    return normalizeArchitecture({ nav, aaEnabled, mirrorIns, type, bolsa });
};
