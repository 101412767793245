import api from './api.js';

export default {
    getFintocOptionsAPV({ amount }) {
        return api.post(
            `fintoc/${amount}`,
            { data: null },
            { params: { acc: 2 } }
        );
    },
    getFintocEnabled() {
        return api.get(`fintoc/status`, { data: null });
    },
};
