import api from './api.js';
import { SELL_MODES } from '@/consts.js';

export default {
    getFunds() {
        return api.get('funds');
    },
    /**
     * Asks whether this a fund is open for buying or selling.
     * @param  {String}  idEncoded - Encoded id of the fund.
     * @param  {Integer}  operation - Type of operation, 1 means buying, 2 means selling.
     * @return {Object}  Object representation of the status of the instrument.
     */
    isOpen({ idEncoded, operation }) {
        return api.get(`funds/${idEncoded}/open/${operation}`);
    },
    buy({
        account,
        currency = 'CLP',
        idBtg,
        amount,
        prtfolioFFM,
        timer,
        token,
    }) {
        return api.post('funds/purchases', {
            countID: account,
            currency,
            idFondo: idBtg,
            monto: amount,
            prtfolioFFM,
            secondGenerateToken: timer,
            tokenTrx: token,
        });
    },
    sell({
        amount,
        account,
        idBtg,
        prtfolioFFM,
        timer,
        token,
        mode,
        currency,
    }) {
        return api.post('funds/sales', {
            countID: account,
            bank: ' ', // This field isn't being used, but needs to be sent or the service fails
            detail: 6, // This field means "Caja corredora BTG Pactual" from investment/paymentForms service as it's the one that's always used
            type: mode,
            idFondo: idBtg,
            amount: mode === SELL_MODES.AMOUNT ? amount : 100,
            prtfolioFFM,
            secondGenerateToken: timer,
            tokenTrx: token,
            currency,
        });
    },
    getAmountRescueAnticipated({ id, account, amount }) {
        const path = `funds/${id}/cobroAnticipado/${account}/${amount}`;
        return api.get(path, { data: null });
    },
    getRebalanceableFunds({ account, mk2 }) {
        const path = `funds/switch/${account}`;
        return api.get(path, { params: { mk2: mk2 ? 'C' : null } });
    },
    doRebalance({
        account,
        timer,
        token,
        idRecFound,
        rescuePercent,
        fundsData,
        portfolio,
        mk2,
    }) {
        // Yes, account goes both on path and body
        const path = `funds/switch/${account}`;
        return api.post(path, {
            secondGenerateToken: timer,
            tokenTrx: token,
            idRecFound,
            rescuePercent,
            fundsData,
            portfolio,
            account,
            mk2: mk2 ? 'C' : null,
        });
    },
    getRebalanceOptions({ account, idSwitch, percent, mk2, portfolio }) {
        const path = `funds/switch/${account}/${idSwitch}/${percent}/${portfolio}`;
        return api.get(path, { params: { mk2: mk2 ? 'C' : null } });
    },
    checkFundIsRebalanceable({ idFund }) {
        const path = `funds/switch/${idFund}/open`;
        return api.get(path, { data: null });
    },
    outOfProfile({ idBtg }) {
        const path = `user/profile/${idBtg}`;
        return api.get(path, { data: null });
    },
    rebalanceOutOfProfile({ idBtg }) {
        const path = `user/profileRB/${idBtg.reduce(
            (cur, acc) => `${cur},${acc}`
        )}`;
        return api.get(path, { data: null });
    },
    getBalance({ idBtg, account, currency }) {
        const path = `user/balance/${idBtg}/${account}/${currency}`;
        return api.get(path, { data: null });
    },
};
