<template>
    <OrqDrawerBody>
        <template #body>
            <div class="drawer_content">
                <div class="drawer_content__header bg--primary-extended-80">
                    <OrqColumn class="drawer_content__container" :gap="16">
                        <span
                            class="text--emphasis-base-high-inverse heading-xl"
                        >
                            {{ title }}
                        </span>
                        <span
                            class="text--emphasis-base-medium-inverse body-baseline"
                        >
                            {{ subtitle }}
                        </span>
                    </OrqColumn>
                </div>
                <div class="drawer_content__body">
                    <OrqColumn :gap="16">
                        <OrqRadioButtonGroup
                            v-model="selectedAccount"
                            :items="accounts"
                            :inverse="inverse"
                        >
                            <template #extra="{ index }">
                                <OrqFlex
                                    style="
                                        width: 100%;
                                        justify-content: space-between;
                                    "
                                >
                                    <OrqColumn gap="8">
                                        <span
                                            class="text--emphasis-base-high body-baseline"
                                        >
                                            Cuenta de Inversión
                                        </span>
                                        <span
                                            class="text--emphasis-base-medium body-sm"
                                        >
                                            {{ accounts[index]?.value }}
                                        </span>
                                    </OrqColumn>
                                    <OrqColumn gap="8">
                                        <span
                                            class="text--emphasis-base-high body-baseline"
                                            style="align-self: end"
                                            >Disponible</span
                                        >
                                        <template
                                            v-if="
                                                balances[accounts[index]?.value]
                                                    ?.loading
                                            "
                                        >
                                            <OrqSpinner
                                                :width="14"
                                                :height="14"
                                            />
                                            <div></div>
                                        </template>
                                        <template v-else>
                                            <template
                                                v-if="
                                                    balances[
                                                        accounts[index]?.value
                                                    ].value === 'error'
                                                "
                                            >
                                                <span
                                                    class="text--emphasis-base-medium body-sm"
                                                    style="align-self: end"
                                                    >$ -</span
                                                >
                                                <div
                                                    style="
                                                        display: flex;
                                                        align-self: end;
                                                    "
                                                >
                                                    <OrqLink
                                                        label="Reintentar"
                                                        type="primary"
                                                        @click="
                                                            retry(
                                                                accounts[index]
                                                                    ?.value
                                                            )
                                                        "
                                                    />
                                                </div>
                                            </template>
                                            <template v-else>
                                                <span
                                                    class="text--emphasis-base-medium body-sm"
                                                    style="align-self: end"
                                                >
                                                    <OrqAmount
                                                        :amount="
                                                            balances[
                                                                accounts[index]
                                                                    ?.value
                                                            ]?.value
                                                        "
                                                        format="CLP"
                                                    />
                                                </span>
                                                <div
                                                    style="
                                                        display: flex;
                                                        align-self: end;
                                                    "
                                                >
                                                    <OrqLink
                                                        v-if="
                                                            balances[
                                                                accounts[index]
                                                                    ?.value
                                                            ]?.value <
                                                            totalAmount
                                                        "
                                                        label="Abonar"
                                                        type="primary"
                                                        @click="
                                                            openBalanceWarning(
                                                                accounts[index]
                                                                    ?.value
                                                            )
                                                        "
                                                    />
                                                </div>
                                            </template>
                                        </template>
                                    </OrqColumn>
                                </OrqFlex>
                            </template>
                        </OrqRadioButtonGroup>
                    </OrqColumn>
                </div>
            </div>
        </template>

        <template #footer>
            <div class="footer_content">
                <div class="footer_content__body">
                    <TransactionalFooter
                        :next-enabled="selectedAccount != -1"
                        :inverse="inverse"
                        :loading="loadingCreateAPV"
                        @next="nextStep()"
                        @back="back()"
                    />
                </div>
            </div>
        </template>
    </OrqDrawerBody>

    <Teleport v-if="showAddBalanceWarning" to="#modals">
        <OrqModal
            :visible="showAddBalanceWarning"
            style="z-index: 1010"
            title="Ir a abonar saldo"
            :show-actions="false"
            :show-one-action="false"
            primary-button-text="Eliminar"
            secondary-button-text="Volver"
            @on-close="showAddBalanceWarning = false"
            @on-primary-click="goToAddBalance"
            @on-secondary-click="showAddBalanceWarning = false"
        >
            <template #wrapper>
                Vas a salir del flujo APV para ir a abonar saldo a tu cuenta de
                inversión. Puedes retomar mas tarde el flujo APV desde tu
                portafolio. Tu lista de fondos quedará guardada.
                <OrqDivider style="margin: 24px 0 24px 0" />
                <div style="display: flex; gap: 16px; justify-content: end">
                    <OrqButton
                        label="Cerrar"
                        appearance="line"
                        @click="showAddBalanceWarning = false"
                    />
                    <OrqButton
                        label="Ir a abonar saldo"
                        @click="goToAddBalance"
                    />
                </div>
            </template>
        </OrqModal>
        <OrqLockscreen v-if="showAddBalanceWarning" z-index="1009" />
    </Teleport>

    <Teleport v-if="loadingAccount" to="#modals">
        <OrqLockscreen v-if="loadingAccount" z-index="1009" />
        <OrqLoader v-if="loadingAccount" z-index="1009" inverse />
    </Teleport>
</template>

<script setup>
import { nextTick } from 'vue';

import {
    OrqColumn,
    OrqDrawerBody,
    OrqRadioButtonGroup,
    OrqDivider,
    OrqSpinner,
    OrqFlex,
    OrqLink,
    OrqAmount,
    OrqModal,
    OrqLockscreen,
    OrqLoader,
    OrqButton,
} from '@digital/orquestra';
import TransactionalFooter from './TransactionalFooter.vue';

import { storeToRefs } from 'pinia';

import { useApvStore } from '@/_store/apv.store.js';

const apvStore = useApvStore();
const {
    selectedAccount: storeSelectedAccount,
    loadingCreateAPV,
    totalAmount,
} = storeToRefs(apvStore);
const { nextStep: storeNextStep } = apvStore;

import { computed, onMounted, ref } from 'vue';
import { ACCOUNT_TYPE } from '@/consts';

import { useAccountsStore } from '@/_store/accounts.store.js';
const accountsStore = useAccountsStore();
const { accounts: storeAccounts, currentAccount } = storeToRefs(accountsStore);

const accounts = computed(() => {
    return storeAccounts.value
        .filter((account) => account.tipoCuenta === ACCOUNT_TYPE.INVESTMENT)
        .map((account) => ({
            value: account.idCuentaGrupo,
            disabled:
                account.estadoVisible !== '1' ||
                balances.value[account.idCuentaGrupo]?.value == 'error' ||
                (balances.value[account.idCuentaGrupo]?.value ?? 0) <
                    totalAmount.value,
        }));
});

const selectedAccount = computed({
    get() {
        return accounts.value.findIndex(
            (item) => item.value == storeSelectedAccount.value
        );
    },
    set(newValue) {
        storeSelectedAccount.value = accounts.value[newValue].value;
    },
});

const nextStep = async () => {
    await apvStore.createAPV(true);
    await nextTick();
    storeNextStep();
};

import balanceService from '@/_services/balance.service';
const balances = ref({});
onMounted(() => {
    accounts.value.forEach((account) => {
        balances.value[account.value] = {
            loading: true,
        };
        balanceService
            .getUserBalanceByAccount({
                account: account.value,
                currency: 'CLP',
            })
            .then((response) => {
                balances.value[account.value] = {
                    value: response.data,
                    loading: false,
                };
            })
            .catch(() => {
                balances.value[account.value] = {
                    value: 'error',
                    loading: false,
                };
            });
    });
});

const retry = (account) => {
    balances.value[account] = {
        loading: true,
    };
    balanceService
        .getUserBalanceByAccount({
            account: account,
            currency: 'CLP',
        })
        .then((response) => {
            balances.value[account] = {
                value: response.data,
                loading: false,
            };
        })
        .catch(() => {
            balances.value[account] = {
                value: 'error',
                loading: false,
            };
        });
};

defineProps({
    title: {
        type: String,
        default: '',
    },
    subtitle: {
        type: String,
        default: '',
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

const showAddBalanceWarning = ref(false);
import { EVENTS } from '@/consts';
import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();
import router from '@/router';

const accountID = ref();
const openBalanceWarning = (id) => {
    accountID.value = id;
    showAddBalanceWarning.value = true;
};

import { useAbonarStore } from '@/_store/abonar.store';
const abonarStore = useAbonarStore();
const { accountSelected } = storeToRefs(abonarStore);

import { useRoute } from 'vue-router';
const route = useRoute();

const loadingAccount = ref(false);
const goToAddBalance = async () => {
    showAddBalanceWarning.value = false;
    loadingAccount.value = true;
    try {
        if (accountID.value != currentAccount.value.idCuentaGrupo) {
            await accountsStore.selectAccount(accountID.value);
        }
        rootEvents.emit(EVENTS.APV_CLOSE);
        // apvStore.$reset();
        accountSelected.value = accountID.value;
        if (route.name != 'portfolio') {
            router.push({
                name: 'portfolio',
                query: { abonar: true },
            });
        } else {
            rootEvents.emit(EVENTS.ABONAR_OPEN, { skipDefault: true });
        }
    } finally {
        loadingAccount.value = false;
    }
};

const back = () => {
    storeSelectedAccount.value = null;
    apvStore.goToStep('selectDepositMethod');
};
</script>

<style lang="scss" scoped>
.drawer_content {
    margin-left: -48px;
    margin-right: -48px;

    &__container {
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 48px;
    }
    &__header {
        padding: 40px 0;
    }
    &__body {
        padding: 32px 48px;
    }
}

:deep(.title--title-md) {
    font-size: 20px;
}

:deep(div.radio-button--content-container) {
    width: 100%;
    justify-content: start;
}

:deep(div.radio-button--content-container > div.column) {
    width: 100%;
}

.footer_content {
    margin-left: -48px;
    margin-right: -48px;
    border-top: solid 1px var(--outline);

    &__body {
        padding: 24px 48px 0 48px;
    }
}
</style>
