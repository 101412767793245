import api from './api.js';

export default {
    /**
     *
     */
    getStatus() {
        return api.get('status', { data: null });
    },
};
