<template>
    <OrqDrawerBody>
        <template #header>
            <OrqColumn :gap="16">
                <div
                    class="heading-md"
                    :class="`text--emphasis-base-high${
                        inverse ? '-inverse' : ''
                    }`"
                >
                    Abonar
                </div>
                <div
                    class="heading-lg"
                    :class="`text--emphasis-base-high${
                        inverse ? '-inverse' : ''
                    }`"
                >
                    Por transferencia electrónica
                </div>
                <div
                    :class="`text--emphasis-base-medium${
                        inverse ? '-inverse' : ''
                    }`"
                >
                    Haz una transferencia a esta cuenta y el abono será
                    automáticamente reconocido en tu cuenta BTG Pactual
                </div>
            </OrqColumn>
        </template>
        <template #body>
            <template v-if="loading">
                <OrqShimmer :inverse="inverse" type="select" />
            </template>
            <template v-else>
                <OrqColumn :gap="16">
                    <OrqNotificationFixed
                        notification-type="fixed"
                        label="Si haces un abono antes de las 14:00 hrs. de un día hábil verás el saldo en unos minutos. En cualquier otro caso, lo verás reflejado al día hábil siguiente."
                        kind="info"
                        :label-size="12"
                        :inverse="inverse"
                    />

                    <div>
                        <AbonarCopyRow
                            v-for="(value, key) in transferData"
                            :key="key"
                            :label="labels[key]"
                            :text="value"
                            :inverse="inverse"
                        />
                    </div>

                    <!-- Don't delete this. It's use to copy the text inside -->
                    <pre v-show="false" id="fullText">
<template v-for="(value, key) in transferData">{{ labels[key] }}: {{ value }}
</template></pre>

                    <template v-if="!copyAllStatus">
                        <OrqButton
                            size="full"
                            label="Copiar todos los datos"
                            :inverse="inverse"
                            @click="onCopyAll"
                        />
                    </template>
                    <template v-else>
                        <OrqButton
                            size="full"
                            label="Datos copiados"
                            icon-name="check"
                            appearance="line"
                            :inverse="inverse"
                        />
                    </template>
                </OrqColumn>
            </template>
        </template>

        <template #footer>
            <AbonarFooter
                :show-cancel="false"
                next-label="Cerrar"
                :inverse="inverse"
                @next="onClose"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import {
    OrqDrawerBody,
    OrqColumn,
    OrqButton,
    OrqNotificationFixed,
    OrqShimmer,
} from '@digital/orquestra';
import { onMounted, ref } from 'vue';

import AbonarCopyRow from './shared/AbonarCopyRow.vue';
import AbonarFooter from './shared/AbonarFooter.vue';

import bankAccountsService from '@/_services/bankAccounts.service';
const loading = ref(false);

const transferData = ref({});

onMounted(() => {
    loading.value = true;
    bankAccountsService.getDataForTransfer().then((r) => {
        transferData.value = r.data;
        loading.value = false;
    });
});

const labels = {
    name: 'Nombre',
    rut: 'RUT',
    type: 'Tipo de cuenta',
    number: 'N° de cuenta',
    bankName: 'Banco',
    email: 'E-mail',
};

const copyAllStatus = ref(false);
const onCopyAll = async () => {
    try {
        const text = document.querySelector('#fullText').innerText;
        await navigator.clipboard.writeText(text);

        copyAllStatus.value = true;
        setTimeout(() => {
            copyAllStatus.value = false;
        }, 3000);
    } catch (error) {
        console.error(error.message);
    }
};

import { EVENTS } from '@/consts';
import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();

const onClose = () => {
    rootEvents.emit(EVENTS.ABONAR_CLOSE);
};

defineProps({
    inverse: {
        type: Boolean,
        default: false,
    },
});
</script>
