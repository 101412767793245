<template>
    <OrqFlex :wrap="false" class="abonar-copy-row">
        <div>
            <div
                :class="`text--emphasis-base-high${inverse ? '-inverse' : ''}`"
            >
                {{ label }}
            </div>
            <div
                class="body-sm"
                :class="`text--emphasis-base-medium${
                    inverse ? '-inverse' : ''
                }`"
            >
                {{ text }}
            </div>
        </div>
        <a
            href="#"
            :class="getCopyClass(isCopied)"
            @click.stop.prevent="onCopy"
        >
            <template v-if="!isCopied">
                <OrqIconContainer :size="24" name="edit-copy" />
            </template>
            <template v-else>
                <OrqIconContainer :size="24" name="status-success" />
            </template>
        </a>
    </OrqFlex>
</template>

<script setup>
import { OrqIconContainer, OrqFlex } from '@digital/orquestra';
import { ref } from 'vue';

const props = defineProps({
    label: {
        type: String,
        default: '',
    },
    text: {
        type: String,
        default: '',
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

const isCopied = ref(false);
const onCopy = async () => {
    try {
        await navigator.clipboard.writeText(props.text);
        isCopied.value = true;
        setTimeout(() => {
            isCopied.value = false;
        }, 5000);
    } catch (error) {
        console.error(error.message);
    }
};

const getCopyClass = (isCopied) => {
    const copiedClass = isCopied ? 'text--success' : 'text--emphasis-base-high';
    return props.inverse ? `${copiedClass}-inverse` : copiedClass;
};
</script>

<style lang="scss" scoped>
.abonar-copy-row {
    min-height: 60px;
    align-items: center;
    justify-content: space-between;
}
</style>
