<template>
    <OrqDrawerBody>
        <template #body>
            <div class="drawer_content">
                <div class="drawer_content__header bg--primary-extended-80">
                    <OrqColumn class="drawer_content__container" :gap="16">
                        <span
                            class="text--emphasis-base-high-inverse heading-xl"
                        >
                            {{ title }}
                        </span>
                        <span
                            class="text--emphasis-base-medium-inverse body-baseline"
                        >
                            {{ subtitle }}
                        </span>
                    </OrqColumn>
                </div>
                <div class="drawer_content__body">
                    <OrqColumn :gap="16">
                        <OrqRadioButtonGroup
                            v-model="selectedRegime"
                            :items="regimes"
                            :inverse="inverse"
                        />
                        <OrqLink
                            label="¿Qué significa cada régimen?"
                            type="primary"
                            @click="openExplanation"
                        />
                    </OrqColumn>
                </div>
            </div>
        </template>

        <template #footer>
            <div class="footer_content">
                <OrqDivider />
                <div class="footer_content__body">
                    <TransactionalFooter
                        :next-enabled="selectedRegime != -1"
                        :inverse="inverse"
                        back
                        @next="nextStep()"
                        @cancel="emit('cancel')"
                        @back="apvStore.goToStep('addMore')"
                    />
                </div>
            </div>
        </template>
    </OrqDrawerBody>

    <Teleport v-if="showRegimeExplanation" to="#modals">
        <OrqModal
            :visible="showRegimeExplanation"
            style="z-index: 1010"
            title="¿Qué significa cada régimen?"
            :show-actions="false"
            :show-one-action="false"
            size="large"
            @on-close="showRegimeExplanation = false"
        >
            <template #wrapper>
                <OrqColumn :gap="32">
                    <OrqColumn :gap="8">
                        <div
                            style="display: flex; align-items: center; gap: 8px"
                        >
                            <img
                                src="https://static.btgpactual.cl/apps/apv/regimen-a.svg"
                                height="40"
                                width="40"
                            />
                            <span
                                class="text--emphasis-base-high body-baseline"
                            >
                                Régimen A
                            </span>
                        </div>
                        <span class="text--emphasis-base-low">
                            El Estado te entrega anualmente una bonificación del
                            15% de tus abonos APV-A del año, con un límite de 6
                            UTM.
                        </span>
                        <OrqDivider />
                    </OrqColumn>
                    <OrqColumn :gap="8">
                        <div
                            style="display: flex; align-items: center; gap: 8px"
                        >
                            <img
                                src="https://static.btgpactual.cl/apps/apv/regimen-b.svg"
                                height="40"
                                width="40"
                            />
                            <span
                                class="text--emphasis-base-high body-baseline"
                            >
                                Régimen B
                            </span>
                        </div>
                        <span class="text--emphasis-base-low">
                            Los abonos APV-B reducen tu base imponible del
                            Impuesto Único de Segunda Categoría, con un tope de
                            600 UF al año ({{
                                loadingUF
                                    ? '-'
                                    : formatCurrency(600 * UF, 'CLP')
                            }}
                            a {{ formatDate(date, 'month') }}). El tope
                            considera abonos APV-A y APV-B.
                            <br />
                            <br />
                            Según tu tramo impositivo, recibirás un porcentaje
                            de lo ahorrado como una mayor devolución en tu
                            declaración de renta anual (para abonos directos).
                        </span>
                        <OrqDivider />
                    </OrqColumn>
                    <OrqColumn :gap="8">
                        <div
                            style="display: flex; align-items: center; gap: 8px"
                        >
                            <img
                                src="https://static.btgpactual.cl/apps/apv/deposito-convenido.svg"
                                height="40"
                                width="40"
                            />
                            <span
                                class="text--emphasis-base-high body-baseline"
                            >
                                Depósito Convenido
                            </span>
                        </div>
                        <span class="text--emphasis-base-low">
                            Similar al APV-B, los Depósitos Convenidos reducen
                            tu base imponible del Impuesto Único de Segunda
                            Categoría, con un tope de 900 UF al año ({{
                                loadingUF
                                    ? '-'
                                    : formatCurrency(900 * UF, 'CLP')
                            }}
                            a {{ formatDate(date, 'month') }}). Sin embargo, no
                            puedes retirarlos antes de tu jubilación.
                        </span>
                        <OrqDivider />
                    </OrqColumn>
                    <OrqLink
                        label="¿Quieres saber más?"
                        orientation="right"
                        icon="external"
                        type="primary"
                        href="https://ayuda.btgpactual.cl/es/articles/9977123-cuales-son-los-beneficios-tributarios-del-apv"
                    />
                </OrqColumn>
            </template>
        </OrqModal>
        <OrqLockscreen v-if="showRegimeExplanation" z-index="1009" />
    </Teleport>
</template>

<script setup>
import {
    OrqColumn,
    OrqDrawerBody,
    OrqLink,
    OrqRadioButtonGroup,
    OrqModal,
    OrqLockscreen,
    OrqDivider,
} from '@digital/orquestra';
import { formatCurrency } from '@/directives/currencyFormat';
import { formatDate } from '@/utils/text.js';
import TransactionalFooter from './TransactionalFooter.vue';

import { storeToRefs } from 'pinia';

import { useApvStore } from '@/_store/apv.store.js';

const apvStore = useApvStore();
const { selectedRegime: storeSelectedRegime, regimes } = storeToRefs(apvStore);
const { nextStep: storeNextStep } = apvStore;

import { ref, computed } from 'vue';

const showRegimeExplanation = ref(false);
const loadingUF = ref(false);
const UF = ref();
const UFdate = ref();
import dapService from '@/_services/dap.service';

const openExplanation = async () => {
    showRegimeExplanation.value = true;
    try {
        loadingUF.value = true;
        const response = await dapService.getUFRate();
        UF.value = response.data.uf;
        UFdate.value = response.data.fecha;
    } finally {
        loadingUF.value = false;
    }
};

const selectedRegime = computed({
    get() {
        return regimes.value.findIndex(
            (item) => item.value == storeSelectedRegime.value
        );
    },
    set(newValue) {
        storeSelectedRegime.value = regimes.value[newValue].value;
    },
});

const nextStep = async () => {
    storeNextStep();
};

const emit = defineEmits(['cancel']);

defineProps({
    title: {
        type: String,
        default: '',
    },
    subtitle: {
        type: String,
        default: '',
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});
</script>

<style lang="scss" scoped>
.drawer_content {
    margin-left: -48px;
    margin-right: -48px;

    &__container {
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 48px;
    }
    &__header {
        padding: 40px 0;
    }
    &__body {
        padding: 32px 48px;
    }
}

:deep(.title--title-md) {
    font-size: 20px;
}

.footer_content {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1008;

    &__body {
        padding: 24px 48px 24px 48px;
    }
}
</style>
