import { createRouter, createWebHistory } from 'vue-router';
import { routes } from './routes';
import { useAuthStore } from '@/_store/auth.store.js';
import { useUserStore } from '../_store/user.store';
import { updateMetaTitle } from './seo';
import { useAPV } from '@/composables/useAPV.js';

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const { isCurrentAccountAPV } = useAPV();

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (authStore.isLoggedIn) {
            next();
            return;
        }

        next({ name: 'login' });
    } else if (to.matched.some((record) => record.meta.requiresObol)) {
        if (authStore.obolEnabled) {
            next();
        } else {
            next({ name: 'login' });
        }
    } else if (to.matched.some((record) => record.meta.requiresAnon)) {
        if (!authStore.isLoggedIn) {
            next();
        } else {
            if (userStore.isOnlyBank) {
                next({ name: 'portfolio-bank' });
            } else {
                next({ name: 'portfolio' });
            }
        }
    } else if (isCurrentAccountAPV.value && to.meta.redirectIfAPV) {
        next({ name: to.meta.redirectIfAPV });
    } else {
        next();
    }
});

router.afterEach((to) => {
    updateMetaTitle(to.meta);
});

export default router;
