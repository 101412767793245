import axios from 'axios';
import router from '@/router';
import { useAuthStore } from '@/_store/auth.store.js';

const API_ROOT = import.meta.env.VITE_APP_API_URL;

// Todo: Config Token
const api = axios.create({
    baseURL: API_ROOT,
});

api.interceptors.request.use((config) => {
    config.headers = {
        ...config.headers,
    };

    // sending `withoutToken` param will prevent sending the token.
    if (!config.params?.withoutToken) {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = token;
        }
    } else {
        config.params.withoutToken = null;
    }

    if (config.method === 'get') {
        config.headers['Accept'] = '*/*';
        config.headers['Content-Type'] = 'application/json';
    }

    // if (config.method === "post") {
    //   config.headers["Accept"] = "*/*";
    //   config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    // }

    if (config.method === 'delete') {
        /**
         * Nota: Para hacer llamadas con method DELETE se debe pasar
         * params null
         * Ejemplo:
         * api.delete(path, { data: null });
         *
         * Si no, se cambia el content type
         */
        config.headers['Accept'] = '*/*';
        config.headers['Content-Type'] = 'application/json';
    }

    return config;
});

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        const authStore = useAuthStore();
        const currentToken = localStorage.getItem('token');

        // Preventing infinite loop while calling login with token
        const exclude = ['user/login', 'user/logout'];

        if (
            error.response?.status === 403 &&
            exclude.includes(originalRequest.url)
        ) {
            router.push({ name: 'login' });
            return Promise.reject(error);
        }

        // If we have an error we will try to refresh the logint with token.
        if (error.response?.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;
            const accessToken = await authStore.loginWithToken({
                token: currentToken,
            });
            axios.defaults.headers.common['Authorization'] =
                'Bearer ' + accessToken;
            return api(originalRequest);
        }
        return Promise.reject(error);

        // if (error.response && error.response.status === 403) {
        //     // localStorage.removeItem('isBtg');
        //     /**
        //      * By deleting the token we are preventing the 'taldo'
        //      * that was reported here https://btgpactual.atlassian.net/browse/DCH-852
        //      *
        //      * the issue was that some endpoints resolved before /login so we were trying to
        //      * connect over and over until /login failed.
        //      *
        //      */
        //     // localStorage.removeItem('token');
        //     //   router.push("/");
        //     //   router.go();
        // }
        // return Promise.reject(error);
    }
);

export default api;
